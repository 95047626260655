export const getDefaultHeadersForParameters = (element, valueProceso) => {
  let data = {
    accessorKey: element,
    header: element[0].toUpperCase() + element.substring(1),
    enableEditing: [
      "id",
      "creado_por",
      "creado",
      "modificado_por",
      "modificado",
      "fecha_creado",
      "fecha_modificado",
      "usuario_creado",
      "usuario_modificado",
      "modalidad",
    ].includes(element)
      ? false
      : true,
    size: ["clave", "active", "id"].includes(element) ? 10 : null,
    enableGlobalFilter: ![
      "id",
      "creado_por",
      "creado",
      "modificado_por",
      "modificado",
      "clave_escuela",
      "fecha_creado",
      "fecha_modificado",
      "usuario_creado",
      "modalidad",
      "valor_dias",
      "usuario_modificado",
    ].includes(element),
  };
  switch (valueProceso) {
    case 30:
      data.enableEditing = [
        "id",
        "creado_por",
        "creado",
        "modificado_por",
        "modificado",
        "fecha_creado",
        "fecha_modificado",
        "usuario_creado",
        "usuario_modificado",
        "clave_escuela",
      ].includes(element)
        ? false
        : true;
      break;
    default:
      break;
  }
  return data;
};
