export const incidenciaHeaders = [
  {
    name: "Ciclo",
    id: "Ciclo",
    col: 0,
    width: "9%",
    align: "center",
    fn: null,
  },
  {
    name: "Campus",
    id: "Campus",
    col: 1,
    width: "15%",
    align: "center",
    fn: null,
  },
  {
    name: "Escuela",
    id: "Escuela",
    col: 2,
    width: "15%",
    align: "center",
    fn: null,
  },
  {
    name: "Dirección",
    id: "Direccion",
    col: 3,
    width: "18%",
    align: "center",
    fn: null,
  },
  {
    name: "Tipo de incidencia",
    id: "Tipo_Incidencia",
    col: 4,
    width: "18%",
    align: "center",
    fn: null,
  },
  {
    name: "Estatus",
    id: "Estatus",
    col: 5,
    width: "13%",
    align: "center",
    fn: null,
  },
  {
    name: "Hora Captura",
    id: "Hora_Captura",
    col: 6,
    width: "10%",
    align: "center",
    fn: null,
  },
  {
    name: "Tipo de Clase",
    id: "Tipo_Clase",
    col: 7,
    width: "15%",
    align: "center",
    fn: null,
  },
  {
    name: "Hora Registro",
    id: "Hora_Registro",
    col: 8,
    width: "15%",
    align: "center",
    fn: null,
  },
  {
    name: "Autorizado por DA",
    id: "Autorizado_por_DA",
    col: 9,
    width: "9%",
    align: "center",
    fn: null,
  },
  {
    name: "Cancelación x Incidenc",
    id: "cancelacion_x_incidenc",
    col: 10,
    width: "9%",
    align: "center",
    fn: null,
  },

  {
    name: "Grupo",
    id: "Grupo",
    col: 11,
    width: "9%",
    align: "center",
    fn: null,
  },
  {
    name: "Clave Materia",
    id: "Clave_Materia",
    col: 12,
    width: "9%",
    align: "center",
    fn: null,
  },
  {
    name: "Nombre Materia",
    id: "Nombre_Materia",
    col: 13,
    width: "15%",
    align: "center",
    fn: null,
  },
  {
    name: "Clave Profesor",
    id: "Clave_Profesor",
    col: 14,
    width: "9%",
    align: "center",
    fn: null,
  },
  {
    name: "Nombre Profesor",
    id: "Nombre_Profesor",
    col: 15,
    width: "18%",
    align: "center",
    fn: null,
  },
  {
    name: "Comentario",
    id: "Comentario",
    col: 16,
    width: "15%",
    align: "center",
    fn: null,
  },
  {
    name: "Día Clase",
    id: "Dia_Clase",
    col: 17,
    width: "10%",
    align: "center",
    fn: null,
  },
  {
    name: "Fecha Clase",
    id: "Fecha_Clase",
    col: 18,
    width: "15%",
    align: "center",
    fn: null,
  },
  {
    name: "Horario",
    id: "Horario",
    col: 19,
    width: "15%",
    align: "center",
    fn: null,
  },
  {
    name: "Fecha Reposición",
    id: "fecha_reposicion",
    col: 20,
    width: "15%",
    align: "center",
    fn: null,
  },
  {
    name: "Fecha Original",
    id: "fecha_orignal",
    col: 21,
    width: "15%",
    align: "center",
    fn: null,
  },
  {
    name: "Profesor Original",
    id: "profesor_riginal",
    col: 22,
    width: "15%",
    align: "center",
    fn: null,
  },
  {
    name: "Creado",
    id: "Creado",
    col: 23,
    width: "15%",
    align: "center",
    fn: null,
  },
  {
    name: "Creado_por",
    id: "Creado_por",
    col: 24,
    width: "15%",
    align: "center",
    fn: null,
  },
];

export const AsistenciaHeaders = [
  {
    name: "Ciclo",
    id: "Ciclo",
    col: 0,
    width: "9%",
    align: "center",
    fn: null,
  },
  {
    name: "Campus",
    id: "Campus",
    col: 1,
    width: "15%",
    align: "center",
    fn: null,
  },
  {
    name: "Escuela",
    id: "Escuela",
    col: 2,
    width: "15%",
    align: "center",
    fn: null,
  },
  {
    name: "Dirección",
    id: "Direccion",
    col: 3,
    width: "18%",
    align: "center",
    fn: null,
  },
  {
    name: "Tipo Asistencia",
    id: "tipo_asistencia",
    col: 4,
    width: "18%",
    align: "center",
    fn: null,
  },
  {
    name: "Tipo de incidencia",
    id: "Tipo_Incidencia",
    col: 5,
    width: "18%",
    align: "center",
    fn: null,
  },
  {
    name: "Estatus",
    id: "Estatus",
    col: 6,
    width: "13%",
    align: "center",
    fn: null,
  },
  {
    name: "Hora Captura",
    id: "Hora_Captura",
    col: 7,
    width: "10%",
    align: "center",
    fn: null,
  },
  {
    name: "Tipo de Clase",
    id: "Tipo_Clase",
    col: 8,
    width: "15%",
    align: "center",
    fn: null,
  },
  {
    name: "Hora Registro",
    id: "Hora_Registro",
    col: 9,
    width: "15%",
    align: "center",
    fn: null,
  },
  {
    name: "Autorizado por DA",
    id: "Autorizado_por_DA",
    col: 10,
    width: "9%",
    align: "center",
    fn: null,
  },
  {
    name: "Cancelación x Incidenc",
    id: "cancelación_x_incidenc",
    col: 11,
    width: "9%",
    align: "center",
    fn: null,
  },

  {
    name: "Grupo",
    id: "Grupo",
    col: 12,
    width: "9%",
    align: "center",
    fn: null,
  },
  {
    name: "Clave Materia",
    id: "Clave_Materia",
    col: 13,
    width: "9%",
    align: "center",
    fn: null,
  },
  {
    name: "Nombre Materia",
    id: "Nombre_Materia",
    col: 14,
    width: "15%",
    align: "center",
    fn: null,
  },
  {
    name: "Clave Profesor",
    id: "Clave_Profesor",
    col: 15,
    width: "9%",
    align: "center",
    fn: null,
  },
  {
    name: "Nombre Profesor",
    id: "Nombre_Profesor",
    col: 16,
    width: "18%",
    align: "center",
    fn: null,
  },
  {
    name: "Comentario",
    id: "Comentario",
    col: 17,
    width: "15%",
    align: "center",
    fn: null,
  },
  {
    name: "Día Clase",
    id: "Dia_Clase",
    col: 18,
    width: "10%",
    align: "center",
    fn: null,
  },
  {
    name: "Fecha Clase",
    id: "Fecha_Clase",
    col: 19,
    width: "15%",
    align: "center",
    fn: null,
  },
  {
    name: "Horario",
    id: "Horario",
    col: 20,
    width: "15%",
    align: "center",
    fn: null,
  },
  {
    name: "Fecha Reposición",
    id: "fecha_reposicion",
    col: 21,
    width: "15%",
    align: "center",
    fn: null,
  },
  {
    name: "Fecha Original",
    id: "fecha_orignal",
    col: 22,
    width: "15%",
    align: "center",
    fn: null,
  },
  {
    name: "Profesor Original",
    id: "profesor_riginal",
    col: 23,
    width: "15%",
    align: "center",
    fn: null,
  },
  {
    name: "Creado",
    id: "Creado",
    col: 24,
    width: "15%",
    align: "center",
    fn: null,
  },
  {
    name: "Creado_por",
    id: "Creado_por",
    col: 25,
    width: "15%",
    align: "center",
    fn: null,
  },
];
