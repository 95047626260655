import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Select from "react-select";
import { useSnackbar } from "notistack";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { DataGrid } from "@mui/x-data-grid";
import { useSelector } from "react-redux";

import Contenedor from "../../Utils/Visuales/Contenedor";
import EditButton from "../../Utils/Botones/EditButton";
import DataTable from "../../Utils/DataTables";
import { getRequest, postRequest } from "../../Utils/Funciones/requester";
import NewButton from "../../Utils/Botones/NewButton";
import NewModal from "../../Utils/Visuales/NewModal";
import SearchInput from "../../Utils/Visuales/SearchInput";
import styles from "./styles.module.css";
import { isButtonDisabledCoordinador } from "../../Utils/Funciones/isButtonDisabledCoordinador";

const FDA = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [loadingModal, setLoadingModal] = useState(false);
  const [cicloValue, setCicloValue] = useState("");
  const [data, setData] = useState([]);
  const [valueCampus, setValueCampus] = useState("");
  const [optionsEscuela, setOptionsEscuela] = useState("");
  const [valueEscuela, setValueEscuela] = useState("");
  const [dataOriginal, setDataOriginal] = useState([]);
  const [consultaModalIsOpen, setConsultaModalIsOpen] = useState(false);
  const [modalConsultaValues, setModalConsultaValues] = useState("");
  const [loadingConsulta, setLoadingConsulta] = useState(false);
  const [loadingSelectConsulta, setLoadingSelectConsulta] = useState(false);
  const [dataConsulta, setDataConsulta] = useState([]);
  const [dataConsultaOriginal, setDataConsultaOriginal] = useState([]);
  const [dataConsultaRevision, setDataConsultaRevision] = useState([]);
  const [dataConsultaOriginalRevision, setDataConsultaOriginalRevision] =
    useState([]);
  const [modalComentarioIsOpen, setModalComentarioIsOpen] = useState(false);
  const [motivoModalValue, setMotivoModalValue] = useState("");
  const [hidden, setHidden] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [idModal, setIdModal] = useState("");
  const [fdaButtonStyle1, setFDAButtonStyle1] = useState(
    "black-btn reportePre"
  );
  const [fdaButtonStyle2, setFDAButtonStyle2] = useState(
    "black-btn reporteDef"
  );
  const [fdaButtonStyle3, setFDAButtonStyle3] = useState("black-btn trasladar");
  const [fdaButtonStyle4, setFDAButtonStyle4] = useState(
    "black-btn envioBanner"
  );
  const [fdaButtonStyle5, setFDAButtonStyle5] = useState(
    "black-btn eliminarFDA"
  );
  const [pageSize, setPageSize] = useState(5);
  const [modalComentario2IsOpen, setModalComentario2IsOpen] = useState(false);
  const [revisionModalIsOpen, setRevisionModalIsOpen] = useState(false);
  const [modalRevisionValues, setModalRevisionValues] = useState("");
  const [direccion, setDireccion] = useState("");
  const [direccionOptions, setDireccionOptions] = useState([]);
  const [dataIndicadores1, setDataIndicadores1] = useState([]);
  const [dataIndicadores2, setDataIndicadores2] = useState([]);
  const [dataIndicadores3, setDataIndicadores3] = useState([]);
  const [dataIndicadores4, setDataIndicadores4] = useState([]);
  const [rowSelected, setRowSelected] = useState("");
  const [solicitudCambiosModalIsOpen, setSolicitudCambiosModalIsOpen] =
    useState(false);
  const [modalSolicitudValues, setModalSolicitudValues] = useState("");
  const [participaFDA, setParticipaFDA] = useState("");
  const [participaFDAoptions, setParticipaFDAoptions] = useState("");
  const [autorizacionDivModalIsOpen, setAutorizacionDivModalIsOpen] =
    useState(false);
  const [proceso, setProceso] = useState("");
  const [procesoOptions, setProcesoOptions] = useState([]);
  const [autorizar, setAutorizar] = useState([]);
  const [showConsulta, setShowConsulta] = useState(false);
  const [dataSolicitudesDiv, setDataSolicitudesDiv] = useState([]);
  const [dataSolicitudesDivOriginal, setDataSolicitudesDivOriginal] = useState(
    []
  );
  const [dataConsultaDiv, setDataConsultaDiv] = useState([]);
  const [dataConsultaDivOriginal, setDataConsultaDivOriginal] = useState([]);
  const [autorizacionRecModalIsOpen, setAutorizacionRecModalIsOpen] =
    useState(false);
  const [modalComentario3IsOpen, setModalComentario3IsOpen] = useState(false);
  const {
    permisos,
    campusName,
    campusUser2: campusValores,
    userDirections,
    rol,
  } = useSelector((state) => state);
  const headers = [
    {
      name: "Folio",
      col: 0,
      width: "6%",
      fn: null,
    },
    {
      name: "Ciclo",
      col: 1,
      width: "6%",
    },
    {
      name: "Campus",
      col: 2,
      width: "6%",
    },
    {
      name: "Escuela",
      col: 3,
      width: "6%",
    },
    {
      name: "Fecha Creado",
      col: 4,
      width: "6%",
    },
    {
      name: "Creado Por",
      col: 5,
      width: "6%",
    },
    {
      name: "Estatus",
      col: 6,
      width: "6%",
    },
    {
      name: "Total Profesor",
      col: 7,
      width: "6%",
    },
    {
      name: "Acciones",
      col: 8,
      width: "80%",
    },
  ];

  const headersConsulta = [
    {
      name: "Dirección",
      col: 0,
      width: "20%",
      fn: null,
    },
    {
      name: "Grupo",
      col: 1,
      width: "5%",
    },
    {
      name: "Clv Mat",
      col: 2,
      width: "5%",
    },
    {
      name: "Nombre Materia",
      col: 3,
      width: "15%",
    },
    {
      name: "Clv Prof",
      col: 4,
      width: "5%",
    },
    {
      name: "Nombre Profesor",
      col: 5,
      width: "15%",
    },
    {
      name: "Prom Ev",
      col: 6,
      width: "5%",
    },
    {
      name: "Fecha de Alta",
      col: 7,
      width: "5%",
    },
    {
      name: "¿Participa?",
      col: 8,
      width: "5%",
    },
    {
      name: "% FDA",
      col: 9,
      width: "5%",
    },
    {
      name: "Solicitud",
      col: 10,
      width: "5%",
    },
    {
      name: "Estatus",
      col: 11,
      width: "10%",
    },
  ];

  const columns = [
    {
      field: "grupo",
      headerName: "Grupo",
      width: 80,
      headerAlign: "center",
      align: "center",
      disableColumnMenu: true,
    },
    {
      field: "clave_materia",
      headerName: "Clv Mat",
      width: 80,
      headerAlign: "center",
      align: "center",
      disableColumnMenu: true,
    },
    {
      field: "nombre_materia",
      headerName: "Nombre Materia",
      width: 260,
      headerAlign: "center",
      align: "left",
      disableColumnMenu: true,
    },
    {
      field: "clave_profesor",
      headerName: "Clv Prof",
      width: 80,
      headerAlign: "center",
      align: "center",
      disableColumnMenu: true,
    },
    {
      field: "nombre_profesor",
      headerName: "Nombre Profesor",
      width: 330,
      headerAlign: "center",
      align: "left",
      disableColumnMenu: true,
    },
    {
      field: "evaluacion_promedio",
      headerName: "Prom Ev",
      width: 90,
      headerAlign: "center",
      align: "center",
      disableColumnMenu: true,
    },
    {
      field: "fecha_alta",
      headerName: "Fecha de Alta",
      width: 120,
      headerAlign: "center",
      align: "center",
      disableColumnMenu: true,
    },
    {
      field: "participa",
      headerName: "¿Participa?",
      headerAlign: "center",
      align: "center",
      width: 105,
      disableColumnMenu: true,
    },
    {
      field: "fda",
      headerName: "% FDA",
      headerAlign: "center",
      align: "center",
      width: 70,
      disableColumnMenu: true,
    },
    {
      field: "estatus",
      headerName: "Estatus",
      headerAlign: "center",
      align: "center",
      width: 135,
      disableColumnMenu: true,
    },
    {
      field: "especial",
      headerName: "Especial",
      headerAlign: "center",
      align: "center",
      width: 105,
      disableColumnMenu: true,
    },
  ];

  const columnsConsulta2 = [
    {
      field: "direccion",
      headerName: "Dirección",
      width: 90,
      headerAlign: "center",
      align: "center",
      disableColumnMenu: true,
    },
    {
      field: "director",
      headerName: "Director",
      width: 250,
      headerAlign: "center",
      align: "left",
      disableColumnMenu: true,
    },
    {
      field: "grupo",
      headerName: "Grupo",
      width: 80,
      headerAlign: "center",
      align: "center",
      disableColumnMenu: true,
    },
    {
      field: "clave_materia",
      headerName: "Clv Mat",
      width: 80,
      headerAlign: "center",
      align: "center",
      disableColumnMenu: true,
    },
    {
      field: "nombre_materia",
      headerName: "Nombre Materia",
      width: 250,
      headerAlign: "center",
      align: "left",
      disableColumnMenu: true,
    },
    {
      field: "clave_profesor",
      headerName: "Clv Prof",
      width: 80,
      headerAlign: "center",
      align: "center",
      disableColumnMenu: true,
    },
    {
      field: "nombre_profesor",
      headerName: "Nombre Profesor",
      width: 250,
      headerAlign: "center",
      align: "left",
      disableColumnMenu: true,
    },
    {
      field: "promedio_evaluacion",
      headerName: "Prom Ev",
      width: 85,
      headerAlign: "center",
      align: "center",
      disableColumnMenu: true,
    },
    {
      field: "fecha_alta",
      headerName: "Fecha de Alta",
      width: 120,
      headerAlign: "center",
      align: "center",
      disableColumnMenu: true,
    },
    {
      field: "participa",
      headerName: "¿Participa?",
      headerAlign: "center",
      align: "center",
      width: 105,
      disableColumnMenu: true,
    },
    {
      field: "fda",
      headerName: "% FDA",
      headerAlign: "center",
      align: "center",
      width: 70,
      disableColumnMenu: true,
    },
    {
      field: "estatus",
      headerName: "Estatus",
      headerAlign: "center",
      align: "center",
      width: 105,
      disableColumnMenu: true,
    },
    {
      field: "especial",
      headerName: "Especial",
      headerAlign: "center",
      align: "center",
      width: 110,
      disableColumnMenu: true,
    },
  ];

  const columnsSolicitudes = [
    {
      field: "direccion",
      headerName: "Dirección",
      width: 90,
      headerAlign: "center",
      align: "center",
      disableColumnMenu: true,
    },
    {
      field: "director",
      headerName: "Director",
      width: 250,
      headerAlign: "center",
      align: "left",
      disableColumnMenu: true,
    },
    {
      field: "grupo",
      headerName: "Grupo",
      width: 80,
      headerAlign: "center",
      align: "center",
      disableColumnMenu: true,
    },
    {
      field: "clave_materia",
      headerName: "Clv Mat",
      width: 80,
      headerAlign: "center",
      align: "center",
      disableColumnMenu: true,
    },
    {
      field: "nombre_materia",
      headerName: "Nombre Materia",
      width: 250,
      headerAlign: "center",
      align: "left",
      disableColumnMenu: true,
    },
    {
      field: "clave_profesor",
      headerName: "Clv Prof",
      width: 80,
      headerAlign: "center",
      align: "center",
      disableColumnMenu: true,
    },
    {
      field: "nombre_profesor",
      headerName: "Nombre Profesor",
      width: 250,
      headerAlign: "center",
      align: "left",
      disableColumnMenu: true,
    },
    {
      field: "promedio_evaluacion",
      headerName: "Prom Ev",
      width: 85,
      headerAlign: "center",
      align: "center",
      disableColumnMenu: true,
    },
    {
      field: "fecha_alta",
      headerName: "Fecha de Alta",
      width: 120,
      headerAlign: "center",
      align: "center",
      disableColumnMenu: true,
    },
    {
      field: "participa",
      headerName: "¿Participa?",
      headerAlign: "center",
      align: "center",
      width: 105,
      disableColumnMenu: true,
    },
    {
      field: "fda",
      headerName: "% FDA",
      headerAlign: "center",
      align: "center",
      width: 70,
      disableColumnMenu: true,
    },
    {
      field: "cambio_solicitado",
      headerName: "Cambio Solicitado",
      headerAlign: "center",
      align: "center",
      width: 155,
      disableColumnMenu: true,
    },
    {
      field: "autorizar_solicitud",
      headerName: "Autorizar Solicitud",
      headerAlign: "center",
      align: "center",
      width: 160,
      renderCell: (params) => (
        <Select
          className="select-modal-container"
          styles={asyncRowSelectStyles}
          options={[
            {
              value: "Aprobado",
              label: "Si",
            },
            {
              value: "No Aprobado",
              label: "No",
            },
            {
              value: "Pendiente",
              label: "Pendiente",
            },
          ]}
          value={autorizar.find((item) => item.id === params.id)}
          onChange={(event) => handleAutorizarSolicitud(event, params.id)}
        />
      ),
    },
  ];

  const headersIndicadores1 = [
    {
      name: "Prog",
      col: 0,
      width: "20%",
    },
    {
      name: "Faltas",
      col: 1,
      width: "16%",
    },
    {
      name: "Aut",
      col: 2,
      width: "16%",
    },
    {
      name: "Sust",
      col: 3,
      width: "16%",
    },
    {
      name: "Rep",
      col: 4,
      width: "16%",
    },
    {
      name: "Adic",
      col: 5,
      width: "16%",
    },
  ];

  const headersIndicadores2 = [
    {
      name: "Clase",
      col: 0,
      width: "30%",
    },
    {
      name: "Ret",
      col: 1,
      width: "30%",
    },
    {
      name: "Sal. Ant",
      col: 2,
      width: "40%",
    },
  ];

  const headersIndicadores3 = [
    {
      name: "Ret",
      col: 0,
      width: "30%",
    },
    {
      name: "Canc",
      col: 1,
      width: "30%",
    },
    {
      name: "Sal. Ant",
      col: 2,
      width: "40%",
    },
  ];

  const headersIndicadores4 = [
    {
      name: "Fecha Baja",
      col: 0,
      width: "100%",
    },
  ];

  const asyncRowSelectStyles = {
    menuList: (base) => ({
      ...base,
      maxHeight: "80px",
    }),
  };

  useEffect(() => {
    consultarCatalogo(9).then((data) => setOptionsEscuela(data));
    setParticipaFDAoptions([
      {
        value: "autorizar",
        label: "Autorizar",
      },
      {
        value: "cancelar",
        label: "Cancelar",
      },
    ]);
    setProcesoOptions([
      {
        value: "solicitudes",
        label: "Solicitudes de Cambio",
      },
      {
        value: "consulta",
        label: "Consulta",
      },
    ]);

    setValueCampus(
      campusValores?.filter((item) => item.label.includes(campusName))[0]
    );
  }, []);

  const consultarCatalogo = async (id) => {
    try {
      const consulta = `/catalogo?id=${id}`;
      const response = await getRequest(consulta);
      let data = [];
      if (response.s === "OK") {
        let id = response.d.elementos[0].id;
        let clave = response.d.elementos[1].clave;
        let nombre = response.d.elementos[2].nombre;
        id.forEach((item, i) => {
          data.push({ value: id[i], label: `${clave[i]} - ${nombre[i]}` });
        });
        return data;
      } else {
        enqueueSnackbar(response.m, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleAutorizarSolicitud = async (ev, id) => {
    setDataSolicitudesDiv((prev) => {
      const newValue = prev.map((item) => {
        if (item.id === id) {
          return { ...item, fdas_estatus: ev.value };
        }
        return item;
      });
      return newValue;
    });
    setAutorizar((prev) => {
      const newValue = prev.map((item) => {
        if (item.id === id) {
          return { ...ev, id };
        }
        return item;
      });
      return newValue;
    });
  };

  function search(text) {
    text = text.toLowerCase();
    let result = dataOriginal.filter((row) => {
      return row.some((element) => {
        const elementString =
          typeof element === "number" ? element.toString() : element;
        return (
          typeof elementString === "string" &&
          elementString.toLowerCase().includes(text)
        );
      });
    });
    setData(result);
  }

  function search5(text) {
    text = text.toLowerCase();
    let result = dataConsultaOriginal.filter((row) => {
      return row.some((element) => {
        const elementString =
          typeof element === "number" ? element.toString() : element;
        return (
          typeof elementString === "string" &&
          elementString.toLowerCase().includes(text)
        );
      });
    });
    setDataConsulta(result);
  }

  function search2(text) {
    text = text?.toLowerCase();
    const resultados = [];
    for (let i = 0; i < dataConsultaOriginalRevision.length; i++) {
      const objeto = dataConsultaOriginalRevision[i];
      for (const key in objeto) {
        if (String(objeto[key]).toLowerCase().includes(text)) {
          resultados.push(objeto);
          break;
        }
      }
    }
    setDataConsultaRevision(resultados);
  }

  function search3(text) {
    text = text?.toLowerCase();
    const resultados = [];
    for (let i = 0; i < dataSolicitudesDivOriginal.length; i++) {
      const objeto = dataSolicitudesDivOriginal[i];
      for (const key in objeto) {
        if (String(objeto[key]).toLowerCase().includes(text)) {
          resultados.push(objeto);
          break;
        }
      }
    }
    setDataSolicitudesDiv(resultados);
  }

  function search4(text) {
    text = text?.toLowerCase();
    const resultados = [];
    for (let i = 0; i < dataConsultaDivOriginal.length; i++) {
      const objeto = dataConsultaDivOriginal[i];
      for (const key in objeto) {
        if (String(objeto[key]).toLowerCase().includes(text)) {
          resultados.push(objeto);
          break;
        }
      }
    }
    setDataConsultaDiv(resultados);
  }

  const openModalConsulta = async (id) => {
    setLoadingModal(true);
    setLoadingConsulta(true);
    setConsultaModalIsOpen(true);
    try {
      const request = `/fda?id=${id}&tipo=consulta`;
      const response = await getRequest(request);
      if (response.s === "OK") {
        setIdModal(id);
        setModalConsultaValues({
          id: id,
          folio: id,
          ciclo: response.d.ciclo,
          campus: response.d.campus,
          escuela: response.d.escuela,
          estatus: response.d.estatus,
          creado: response.d.creado,
          creado_por: response.d.creado_por,
          modificado: response.d.modificado,
          modificado_por: response.d.modificado_por,
          comentario: response.d.comentario,
        });
        if (response.d.estatus === "Eliminado") {
          setFDAButtonStyle1("gray-btn reportePre");
          setFDAButtonStyle2("gray-btn reporteDef");
          setFDAButtonStyle3("gray-btn trasladar");
          setFDAButtonStyle4("gray-btn envioBanner");
          setDisabled(true);
        }
        if (
          response.d.estatus === "Eliminado" ||
          response.d.estatus === "Trasladado" ||
          response.d.estatus === "Enviado"
        ) {
          setHidden(true);
        }
        let datos = [];
        const nro = response.d.datos["id"].length;
        for (let i = 0; i < nro; i++) {
          datos.push([
            response.d.datos["direccion"][i],
            response.d.datos["grupo"][i],
            response.d.datos["clave_materia"][i],
            response.d.datos["nombre_materia"][i],
            response.d.datos["clave_profesor"][i],
            response.d.datos["nombre_profesor"][i],
            response.d.datos["promedio_evaluacion"][i],
            response.d.datos["fecha_alta"][i],
            response.d.datos["participa"][i],
            response.d.datos["porcentaje_fda"][i],
            response.d.datos["solicitud"][i],
            response.d.datos["estatus"][i],
            response.d.datos["id"][i],
            response.d.datos["fdas_estatus"][i],
          ]);
        }
        setDataConsulta(datos);
        setDataConsultaOriginal(datos);
        enqueueSnackbar(response.m, {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      } else {
        enqueueSnackbar(response.m, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
    } catch (error) {
    } finally {
      setLoadingConsulta(false);
      setLoadingModal(false);
    }
  };

  const openModalRevision = async (
    id,
    folio,
    ciclo,
    campus,
    escuela,
    estatus
  ) => {
    if (estatus === "Eliminado") {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
    setRevisionModalIsOpen(true);
    setLoadingModal(true);
    setModalRevisionValues({
      id: id,
      folio: folio,
      ciclo: ciclo,
      campus: campus,
      escuela: escuela,
    });
    setLoadingModal(false);
    setLoadingConsulta(true);
    setLoadingSelectConsulta(true);
    try {
      const request = `/fda?id=${id}&tipo=consulta_revision_select`;
      const response = await getRequest(request);
      if (response.s === "OK") {
        setIdModal(id);
        setModalConsultaValues({
          id: id,
          folio: id,
          ciclo: response.d.ciclo,
          campus: response.d.campus,
          escuela: response.d.escuela,
          estatus: response.d.estatus,
        });
        const nro = response.d.datos["id"].length;
        const newArray = [];
        for (let i = 0; i < nro; i++) {
          const value = response.d.datos["id_direccion"][i];
          const label = response.d.datos["direccion"][i];
          const director = response.d.datos["nombre_director"][i];
          if (!newArray.some((career) => career.value === value)) {
            const career = {
              value: value,
              label: label,
              director: director,
            };
            newArray.push(career);
          }
        }
        setDireccionOptions(newArray);
        enqueueSnackbar(response.m, {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
        setLoadingConsulta(false);
        setLoadingSelectConsulta(false);
      } else {
        enqueueSnackbar(response.m, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
        setLoadingConsulta(false);
        setLoadingSelectConsulta(false);
      }
    } catch (error) {
      console.log(error);
      setLoadingConsulta(false);
      setLoadingSelectConsulta(false);
    }
  };
  const openModalAutorizarDiv = async (
    id,
    folio,
    ciclo,
    campus,
    escuela,
    estatus
  ) => {
    if (estatus === "Eliminado") {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
    setModalConsultaValues({
      id: id,
      folio: folio,
      ciclo: ciclo,
      campus: campus,
      escuela: escuela,
    });
    setAutorizacionDivModalIsOpen(true);
    setIdModal(id);
    setLoadingConsulta(true);
    try {
      const request2 = `/fda?id=${id}&tipo=consulta_Div`;
      const response2 = await getRequest(request2);
      if (response2.s === "OK") {
        const nro = response2.d.datos["id"].length;
        const newArray = [];
        const autorizarValue = [];
        for (let i = 0; i < nro; i++) {
          const fdaObject = {
            grupo: response2.d.datos["grupo"][i],
            clave_materia: response2.d.datos["clave_materia"][i],
            nombre_materia: response2.d.datos["nombre_materia"][i],
            clave_profesor: response2.d.datos["clave_profesor"][i],
            nombre_profesor: response2.d.datos["nombre_profesor"][i],
            promedio_evaluacion: response2.d.datos["promedio_evaluacion"][i],
            fecha_alta: response2.d.datos["fecha_alta"][i],
            fecha_baja: response2.d.datos["fecha_baja"][i],
            participa: response2.d.datos["participa"][i],
            fda: response2.d.datos["porcentaje_fda"][i],
            estatus: response2.d.datos["estatus"][i],
            cambio_solicitado: response2.d.datos["solicitud"][i],
            id: response2.d.datos["id"][i],
            horas_programa: response2.d.datos["horas_programa"][i],
            horas_falta: response2.d.datos["horas_falta"][i],
            horas_autorizadas: response2.d.datos["horas_autorizadas"][i],
            horas_sustitucion: response2.d.datos["horas_sustitucion"][i],
            horas_adicionales: response2.d.datos["horas_adicionales"][i],
            horas_reposicion: response2.d.datos["horas_reposicion"][i],
            sesiones_clase: response2.d.datos["sesiones_clase"][i],
            sesiones_retardos: response2.d.datos["sesiones_retardos"][i],
            sesiones_salida: response2.d.datos["sesiones_salida"][i],
            minutos_retardos: response2.d.datos["minutos_retardos"][i],
            minutos_cancelados: response2.d.datos["minutos_cancelados"][i],
            minutos_salida: response2.d.datos["minutos_salida"][i],
            director: response2.d.datos["nombre_director"][i],
            direccion: response2.d.datos["direccion"][i],
            fdas_estatus: response2.d.datos["fdas_estatus"][i],
            motivo: response2.d.datos["motivo"][i],
          };
          newArray.push(fdaObject);
          autorizarValue.push({
            id: response2.d.datos["id"][i],
            value: response2.d.datos["fdas_estatus"][i],
            label:
              response2.d.datos["fdas_estatus"][i] === "Pendiente"
                ? "Pendiente"
                : response2.d.datos["fdas_estatus"][i] === "Aprobado"
                ? "Si"
                : "No",
          });
        }
        setAutorizar(autorizarValue);
        setDataSolicitudesDiv(newArray);
        setDataSolicitudesDivOriginal(newArray);
      }
    } catch (error) {
      setLoadingConsulta(false);
    }
    try {
      setDataConsultaDiv([]);
      setDataConsultaDivOriginal([]);
      const request2 = `/fda?id=${id}&tipo=consulta_Div_aprobado`;
      const response2 = await getRequest(request2);
      if (response2.s === "OK") {
        const nro = response2.d.datos["id"].length;
        const newArray = [];
        for (let i = 0; i < nro; i++) {
          const fdaObject = {
            grupo: response2.d.datos["grupo"][i],
            clave_materia: response2.d.datos["clave_materia"][i],
            nombre_materia: response2.d.datos["nombre_materia"][i],
            clave_profesor: response2.d.datos["clave_profesor"][i],
            nombre_profesor: response2.d.datos["nombre_profesor"][i],
            promedio_evaluacion: response2.d.datos["promedio_evaluacion"][i],
            fecha_alta: response2.d.datos["fecha_alta"][i],
            fecha_baja: response2.d.datos["fecha_baja"][i],
            participa: response2.d.datos["participa"][i],
            fda: response2.d.datos["porcentaje_fda"][i],
            estatus: response2.d.datos["estatus"][i],
            cambio_solicitado: response2.d.datos["solicitud"][i],
            id: response2.d.datos["id"][i],
            horas_programa: response2.d.datos["horas_programa"][i],
            horas_falta: response2.d.datos["horas_falta"][i],
            horas_autorizadas: response2.d.datos["horas_autorizadas"][i],
            horas_sustitucion: response2.d.datos["horas_sustitucion"][i],
            horas_adicionales: response2.d.datos["horas_adicionales"][i],
            horas_reposicion: response2.d.datos["horas_reposicion"][i],
            sesiones_clase: response2.d.datos["sesiones_clase"][i],
            sesiones_retardos: response2.d.datos["sesiones_retardos"][i],
            sesiones_salida: response2.d.datos["sesiones_salida"][i],
            minutos_retardos: response2.d.datos["minutos_retardos"][i],
            minutos_cancelados: response2.d.datos["minutos_cancelados"][i],
            minutos_salida: response2.d.datos["minutos_salida"][i],
            director: response2.d.datos["nombre_director"][i],
            direccion: response2.d.datos["direccion"][i],
            fdas_estatus: response2.d.datos["fdas_estatus"][i],
            especial: response2.d.datos["solicitud"][i],
          };
          newArray.push(fdaObject);
        }
        setDataConsultaDiv(newArray);
        setDataConsultaDivOriginal(newArray);
      }
    } catch (error) {
    } finally {
      setLoadingConsulta(false);
    }
  };

  const openModalAutorizarRec = async (
    id,
    folio,
    ciclo,
    campus,
    escuela,
    estatus
  ) => {
    if (estatus === "Eliminado") {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
    setAutorizacionRecModalIsOpen(true);
    setLoadingModal(true);
    setModalConsultaValues({
      id: id,
      folio: folio,
      ciclo: ciclo,
      campus: campus,
      escuela: escuela,
    });
    try {
      const request = `/fda?id=${id}&tipo=consulta_Rec`;
      const response = await getRequest(request);
      if (response.s === "OK") {
        setIdModal(id);
        const nro = response.d.datos["id"].length;
        const newArray = [];
        for (let i = 0; i < nro; i++) {
          const fdaObject = {
            grupo: response.d.datos["grupo"][i],
            clave_materia: response.d.datos["clave_materia"][i],
            nombre_materia: response.d.datos["nombre_materia"][i],
            clave_profesor: response.d.datos["clave_profesor"][i],
            nombre_profesor: response.d.datos["nombre_profesor"][i],
            promedio_evaluacion: response.d.datos["promedio_evaluacion"][i],
            fecha_alta: response.d.datos["fecha_alta"][i],
            fecha_baja: response.d.datos["fecha_baja"][i],
            participa: response.d.datos["participa"][i],
            fda: response.d.datos["porcentaje_fda"][i],
            estatus: response.d.datos["estatus"][i],
            especial: response.d.datos["solicitud"][i],
            id: response.d.datos["id"][i],
            horas_programa: response.d.datos["horas_programa"][i],
            horas_falta: response.d.datos["horas_falta"][i],
            horas_autorizadas: response.d.datos["horas_autorizadas"][i],
            horas_sustitucion: response.d.datos["horas_sustitucion"][i],
            horas_adicionales: response.d.datos["horas_adicionales"][i],
            horas_reposicion: response.d.datos["horas_reposicion"][i],
            sesiones_clase: response.d.datos["sesiones_clase"][i],
            sesiones_retardos: response.d.datos["sesiones_retardos"][i],
            sesiones_salida: response.d.datos["sesiones_salida"][i],
            minutos_retardos: response.d.datos["minutos_retardos"][i],
            minutos_cancelados: response.d.datos["minutos_cancelados"][i],
            minutos_salida: response.d.datos["minutos_salida"][i],
            director: response.d.datos["nombre_director"][i],
            direccion: response.d.datos["direccion"][i],
            fdas_estatus: response.d.datos["fdas_estatus"][i],
          };
          newArray.push(fdaObject);
        }
        enqueueSnackbar(response.m, {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
        setDataConsultaDiv(newArray);
        setDataConsultaDivOriginal(newArray);
        setLoadingModal(false);
      } else {
        enqueueSnackbar(response.m, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
        setLoadingModal(false);
      }
    } catch (error) {
      console.log(error);
      setLoadingModal(false);
    } finally {
      setLoadingConsulta(false);
    }
  };
  async function handleClick() {
    let valido = true;
    if (cicloValue === "") {
      enqueueSnackbar("Debe Ingresar un Ciclo", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      valido = false;
    }
    if (valueEscuela === "") {
      enqueueSnackbar("Debe Seleccionar una Escuela", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      valido = false;
    }
    if (valueCampus === undefined) {
      enqueueSnackbar("Debe Seleccionar un Campus", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      valido = false;
    }
    if (valido) {
      setData([]);
      setDataOriginal([]);
      setLoading(true);
      try {
        const datos = [];
        const request = `/fda?ciclo=${cicloValue}&id_escuela=${valueEscuela.value}&id_campus=${valueCampus.value}`;
        const response = await getRequest(request);
        if (response.s === "OK") {
          const nro = response.d["id"].length;
          if (nro !== 0) {
            for (let i = 0; i < nro; i++) {
              datos.push([
                response.d["id"][i],
                response.d["ciclo"][i],
                response.d["campus"][i],
                response.d["escuela"][i],
                response.d["fecha_creado"][i],
                response.d["creado_por"][i],
                response.d["estatus"][i],
                response.d["total_profesor"][i],
                <div className={styles.buttonsContainer}>
                  <EditButton
                    onClick={() => openModalConsulta(response.d["id"][i])}
                  >
                    Consulta
                  </EditButton>
                  <EditButton
                    disabled={isButtonDisabledCoordinador(
                      permisos["Profesores->FDA_Revisión DA"].leer,
                      rol,
                      userDirections
                    )}
                    onClick={() =>
                      openModalRevision(
                        response.d["id"][i],
                        response.d["id"][i],
                        response.d["ciclo"][i],
                        response.d["campus"][i],
                        response.d["escuela"][i],
                        response.d["estatus"][i]
                      )
                    }
                    style={{
                      display:
                        permisos["Profesores->FDA"].leer === "1"
                          ? "block"
                          : "none",
                    }}
                  >
                    Revisión DA
                  </EditButton>
                  <EditButton
                    disabled={
                      permisos["Profesores->FDA_Autorizar Div"].leer !== "1"
                    }
                    onClick={() =>
                      openModalAutorizarDiv(
                        response.d["id"][i],
                        response.d["id"][i],
                        response.d["ciclo"][i],
                        response.d["campus"][i],
                        response.d["escuela"][i],
                        response.d["estatus"][i]
                      )
                    }
                    style={{
                      display:
                        permisos["Profesores->FDA"].leer === "1"
                          ? "block"
                          : "none",
                    }}
                  >
                    Autorizar Div
                  </EditButton>
                  <EditButton
                    disabled={
                      permisos["Profesores->FDA_Autorizar Rec"].leer !== "1"
                    }
                    onClick={() =>
                      openModalAutorizarRec(
                        response.d["id"][i],
                        response.d["id"][i],
                        response.d["ciclo"][i],
                        response.d["campus"][i],
                        response.d["escuela"][i],
                        response.d["estatus"][i]
                      )
                    }
                    style={{
                      display:
                        permisos["Profesores->FDA"].leer === "1"
                          ? "block"
                          : "none",
                    }}
                  >
                    Autorizar Rec
                  </EditButton>
                </div>,
              ]);
            }

            setData(datos);
            setLoading(false);
            setDataOriginal(datos);
            enqueueSnackbar(response.m, {
              variant: "success",
              anchorOrigin: {
                vertical: "top",
                horizontal: "left",
              },
            });
          } else {
            setData([]);
            setLoading(false);
            enqueueSnackbar(
              "No se encontraron datos que coincidan con la búsqueda",
              {
                variant: "info",
                anchorOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
              }
            );
          }
        } else {
          setLoading(false);
          enqueueSnackbar(response.m, {
            variant: "error",
            autoHideDuration: null,
            anchorOrigin: {
              vertical: "top",
              horizontal: "left",
            },
          });
          setData([]);
          setDataOriginal([]);
        }
      } catch (error) {
        setLoading(false);
        console.error(error);
        enqueueSnackbar(error, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
    }
  }

  const handleClickGenerar = async () => {
    let valido = true;
    if (cicloValue === "") {
      enqueueSnackbar("Debe Ingresar un Ciclo", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      valido = false;
    }
    if (valueEscuela === "") {
      enqueueSnackbar("Debe Seleccionar una Escuela", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      valido = false;
    }
    if (valueCampus === "") {
      enqueueSnackbar("Debe Seleccionar un Campus", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      valido = false;
    }
    if (valido) {
      try {
        setLoading(true);
        const body = {
          ciclo: cicloValue,
          id_escuela: valueEscuela.value,
          id_campus: valueCampus.value,
        };
        const response = await postRequest("/fda/nuevo", body);
        if (response.s === "OK") {
          enqueueSnackbar(response.m, {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "left",
            },
          });
          handleClick();
        } else {
          setLoading(false);
          enqueueSnackbar(response.m, {
            variant: "error",
            autoHideDuration: null,
            anchorOrigin: {
              vertical: "top",
              horizontal: "left",
            },
          });
          setData([]);
          setDataOriginal([]);
        }
      } catch (error) {
        setLoading(false);
        console.error(error);
        enqueueSnackbar(error, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
    }
  };

  async function handleClickEliminado() {
    setLoadingModal(true);
    setModalComentarioIsOpen(true);
    setLoadingModal(false);
  }

  const handleClickCalcular = async () => {
    const valid = !dataConsultaOriginal.some((item) =>
      item.includes("Pendiente")
    );
    if (valid) {
      setLoadingConsulta(true);
      try {
        const request = `/fda/calcular?id=${idModal}`;
        const response = await getRequest(request);
        if (response.s === "OK") {
          enqueueSnackbar(response.m, {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "left",
            },
          });
          const request2 = `/fda?id=${idModal}&tipo=consulta`;
          const response2 = await getRequest(request2);
          if (response2.s === "OK") {
            let datos = [];
            const nro = response2.d.datos["id"].length;
            for (let i = 0; i < nro; i++) {
              datos.push([
                response2.d.datos["direccion"][i],
                response2.d.datos["grupo"][i],
                response2.d.datos["clave_materia"][i],
                response2.d.datos["nombre_materia"][i],
                response2.d.datos["clave_profesor"][i],
                response2.d.datos["nombre_profesor"][i],
                response2.d.datos["promedio_evaluacion"][i],
                response2.d.datos["fecha_alta"][i],
                response2.d.datos["participa"][i],
                response2.d.datos["porcentaje_fda"][i],
                response2.d.datos["solicitud"][i],
                response2.d.datos["estatus"][i],
                response2.d.datos["id"][i],
                response2.d.datos["fdas_estatus"][i],
              ]);
            }
            setDataConsulta(datos);
            setDataConsultaOriginal(datos);
          }
        } else {
          enqueueSnackbar(response.m, {
            variant: "error",
            autoHideDuration: null,
            anchorOrigin: {
              vertical: "top",
              horizontal: "left",
            },
          });
        }
      } catch (error) {
      } finally {
        setLoadingConsulta(false);
      }
    } else {
      enqueueSnackbar("Hay solicitudes pendientes, no se puede calcular", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    }
  };

  async function handleSubmitEliminado() {
    if (!motivoModalValue) {
      enqueueSnackbar("Por favor antes de eliminar, completar campo Motivo", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      return;
    }
    try {
      setLoadingModal(true);
      const body = {
        id_FDA: modalConsultaValues.folio,
        comentario: motivoModalValue,
      };
      const response = await postRequest("/fda/eliminar", body);
      if (response.s === "OK") {
        enqueueSnackbar(response.m, {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
        setModalConsultaValues("");
        openModalConsulta(idModal);
        setFDAButtonStyle1("gray-btn reportePre");
        setFDAButtonStyle2("gray-btn reporteDef");
        setFDAButtonStyle3("gray-btn trasladar");
        setFDAButtonStyle4("gray-btn envioBanner");
        setDisabled(true);
        setHidden(true);
        setMotivoModalValue("");
        setModalComentarioIsOpen(false);
        handleClick();
      } else {
        setLoading(false);
        enqueueSnackbar(response.m, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingModal(false);
      setLoading(false);
    }
  }

  async function handleTrasladar() {
    try {
      setLoadingConsulta(true);
      const payload = {
        id: idModal,
        type: "trasladar",
      };
      const request = `/fda/edit`;
      const response = await postRequest(request, payload);
      if (response.s === "OK") {
        enqueueSnackbar(response.m, {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
        const request2 = `/fda?id=${idModal}&tipo=consulta`;
        const response2 = await getRequest(request2);
        if (response2.s === "OK") {
          let datos = [];
          const nro = response2.d.datos["id"].length;
          for (let i = 0; i < nro; i++) {
            datos.push([
              response2.d.datos["direccion"][i],
              response2.d.datos["grupo"][i],
              response2.d.datos["clave_materia"][i],
              response2.d.datos["nombre_materia"][i],
              response2.d.datos["clave_profesor"][i],
              response2.d.datos["nombre_profesor"][i],
              response2.d.datos["promedio_evaluacion"][i],
              response2.d.datos["fecha_alta"][i],
              response2.d.datos["participa"][i],
              response2.d.datos["porcentaje_fda"][i],
              response2.d.datos["solicitud"][i],
              response2.d.datos["estatus"][i],
              response2.d.datos["id"][i],
              response2.d.datos["fdas_estatus"][i],
            ]);
          }
          setDataConsulta(datos);
          setDataConsultaOriginal(datos);
        }
      } else {
        enqueueSnackbar(response.m, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingModal(false);
      setLoadingConsulta(false);
    }
  }

  async function handleEnvioBanner() {
    try {
      setLoadingConsulta(true);
      const payload = {
        id: idModal,
        type: "envio",
      };
      const request = `/fda/edit`;
      const response = await postRequest(request, payload);
      if (response.s === "OK") {
        enqueueSnackbar(response.m, {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
        const request2 = `/fda?id=${idModal}&tipo=consulta`;
        const response2 = await getRequest(request2);
        if (response2.s === "OK") {
          let datos = [];
          const nro = response2.d.datos["id"].length;
          for (let i = 0; i < nro; i++) {
            datos.push([
              response2.d.datos["direccion"][i],
              response2.d.datos["grupo"][i],
              response2.d.datos["clave_materia"][i],
              response2.d.datos["nombre_materia"][i],
              response2.d.datos["clave_profesor"][i],
              response2.d.datos["nombre_profesor"][i],
              response2.d.datos["promedio_evaluacion"][i],
              response2.d.datos["fecha_alta"][i],
              response2.d.datos["participa"][i],
              response2.d.datos["porcentaje_fda"][i],
              response2.d.datos["solicitud"][i],
              response2.d.datos["estatus"][i],
              response2.d.datos["id"][i],
              response2.d.datos["fdas_estatus"][i],
            ]);
          }
          setDataConsulta(datos);
          setDataConsultaOriginal(datos);
        }
      } else {
        enqueueSnackbar(response.m, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingModal(false);
      setLoadingConsulta(false);
    }
  }

  const handleRowSelection = async (params) => {
    setRowSelected(params.id);
    try {
      setDataIndicadores1([
        [
          params.row.horas_programa,
          params.row.horas_falta,
          params.row.horas_autorizadas,
          params.row.horas_sustitucion,
          params.row.horas_reposicion,
          params.row.horas_adicionales,
        ],
      ]);
      setDataIndicadores2([
        [
          params.row.sesiones_clase,
          params.row.sesiones_retardos,
          params.row.sesiones_salida,
        ],
      ]);
      setDataIndicadores3([
        [
          params.row.minutos_retardos,
          params.row.minutos_cancelados,
          params.row.minutos_salida,
        ],
      ]);
      setDataIndicadores4([[params.row.fecha_baja]]);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSolicitarCambio = () => {
    let valido = true;
    if (rowSelected === "") {
      enqueueSnackbar("No se ha seleccionado ninguna fila/grupo de la tabla", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      valido = false;
    }
    if (valido) {
      try {
        setLoadingModal(true);
        let valido2 = true;
        const row = dataConsultaRevision.find(
          (item) => item.id === rowSelected
        );
        if (row.estatus === "Aprobado DA") {
          enqueueSnackbar(
            "No es posible solicitar un cambio si el estatus ya está aprobado por el DA",
            {
              variant: "error",
              anchorOrigin: {
                vertical: "top",
                horizontal: "left",
              },
            }
          );
          valido2 = false;
        } else if (row.especial !== "-") {
          enqueueSnackbar(
            "No es posible solicitar un cambio si ya existe un valor en Especial",
            {
              variant: "error",
              anchorOrigin: {
                vertical: "top",
                horizontal: "left",
              },
            }
          );
          valido2 = false;
        }
        if (valido2) {
          setSolicitudCambiosModalIsOpen(true);
          setModalSolicitudValues({
            id: rowSelected,
            clave_profesor: row.clave_profesor,
            nombre_profesor: row.nombre_profesor,
            grupo: row.grupo,
            clave_materia: row.clave_materia,
            nombre_materia: row.nombre_materia,
            fda: row.fda,
          });
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoadingModal(false);
      }
    }
  };

  const handleGuardarSolicitud = async () => {
    let valido = true;
    if (participaFDA === "") {
      enqueueSnackbar("Falta seleccionar Participa FDA", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      valido = false;
    }
    if (motivoModalValue === "") {
      enqueueSnackbar("Falta llenar campo Motivo", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      valido = false;
    }
    if (valido) {
      try {
        setLoadingModal(true);
        if (participaFDA.value === "autorizar") {
          if (!modalSolicitudValues.fda) {
            const body = {
              id: modalSolicitudValues.id,
              status: true,
              comment: motivoModalValue,
            };
            const response = await postRequest("/fda/solicitar", body);
            if (response.s === "OK") {
              enqueueSnackbar(response.m, {
                variant: "success",
                anchorOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
              });
              setLoadingConsulta(true);
              const request = `/fda?id=${idModal}&tipo=consulta_revision&id_direccion=${direccion.value}`;
              const response2 = await getRequest(request);
              if (response2.s === "OK") {
                const nro = response2.d.datos["id"].length;
                const newArray = [];
                for (let i = 0; i < nro; i++) {
                  const fdaObject = {
                    grupo: response2.d.datos["grupo"][i],
                    clave_materia: response2.d.datos["clave_materia"][i],
                    nombre_materia: response2.d.datos["nombre_materia"][i],
                    clave_profesor: response2.d.datos["clave_profesor"][i],
                    nombre_profesor: response2.d.datos["nombre_profesor"][i],
                    evaluacion_promedio:
                      response2.d.datos["promedio_evaluacion"][i],
                    fecha_alta: response2.d.datos["fecha_alta"][i],
                    fecha_baja: response2.d.datos["fecha_baja"][i],
                    participa: response2.d.datos["participa"][i],
                    fda: response2.d.datos["porcentaje_fda"][i],
                    estatus: response2.d.datos["estatus"][i],
                    especial: response2.d.datos["solicitud"][i],
                    id: response2.d.datos["id"][i],
                    horas_programa: response2.d.datos["horas_programa"][i],
                    horas_falta: response2.d.datos["horas_falta"][i],
                    horas_autorizadas:
                      response2.d.datos["horas_autorizadas"][i],
                    horas_sustitucion:
                      response2.d.datos["horas_sustitucion"][i],
                    horas_adicionales:
                      response2.d.datos["horas_adicionales"][i],
                    horas_reposicion: response2.d.datos["horas_reposicion"][i],
                    sesiones_clase: response2.d.datos["sesiones_clase"][i],
                    sesiones_retardos:
                      response2.d.datos["sesiones_retardos"][i],
                    sesiones_salida: response2.d.datos["sesiones_salida"][i],
                    minutos_retardos: response2.d.datos["minutos_retardos"][i],
                    minutos_cancelados:
                      response2.d.datos["minutos_cancelados"][i],
                    minutos_salida: response2.d.datos["minutos_salida"][i],
                  };
                  newArray.push(fdaObject);
                }
                setDataConsultaRevision(newArray);
                setDataConsultaOriginalRevision(newArray);
                setLoadingConsulta(false);
                setMotivoModalValue("");
                setParticipaFDA("");
                setSolicitudCambiosModalIsOpen(false);
              }
            } else {
              setLoading(false);
              enqueueSnackbar(response.m, {
                variant: "error",
                autoHideDuration: null,
                anchorOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
              });
            }
          } else {
            enqueueSnackbar(
              "No es posible autorizar por que el grupo ya tiene calculado el % FDA",
              {
                variant: "error",
                autoHideDuration: null,
                anchorOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
              }
            );
          }
        } else if (modalSolicitudValues.fda) {
          const body = {
            id: modalSolicitudValues.id,
            status: false,
            comment: motivoModalValue,
          };
          const response = await postRequest("/fda/solicitar", body);
          if (response.s === "OK") {
            enqueueSnackbar(response.m, {
              variant: "success",
              anchorOrigin: {
                vertical: "top",
                horizontal: "left",
              },
            });
            setLoadingConsulta(true);
            const request = `/fda?id=${idModal}&tipo=consulta_revision&id_direccion=${direccion.value}`;
            const response2 = await getRequest(request);
            if (response2.s === "OK") {
              const nro = response2.d.datos["id"].length;
              const newArray = [];
              for (let i = 0; i < nro; i++) {
                const fdaObject = {
                  grupo: response2.d.datos["grupo"][i],
                  clave_materia: response2.d.datos["clave_materia"][i],
                  nombre_materia: response2.d.datos["nombre_materia"][i],
                  clave_profesor: response2.d.datos["clave_profesor"][i],
                  nombre_profesor: response2.d.datos["nombre_profesor"][i],
                  evaluacion_promedio:
                    response2.d.datos["promedio_evaluacion"][i],
                  fecha_alta: response2.d.datos["fecha_alta"][i],
                  fecha_baja: response2.d.datos["fecha_baja"][i],
                  participa: response2.d.datos["participa"][i],
                  fda: response2.d.datos["porcentaje_fda"][i],
                  estatus: response2.d.datos["estatus"][i],
                  especial: response2.d.datos["solicitud"][i],
                  id: response2.d.datos["id"][i],
                  horas_programa: response2.d.datos["horas_programa"][i],
                  horas_falta: response2.d.datos["horas_falta"][i],
                  horas_autorizadas: response2.d.datos["horas_autorizadas"][i],
                  horas_sustitucion: response2.d.datos["horas_sustitucion"][i],
                  horas_adicionales: response2.d.datos["horas_adicionales"][i],
                  horas_reposicion: response2.d.datos["horas_reposicion"][i],
                  sesiones_clase: response2.d.datos["sesiones_clase"][i],
                  sesiones_retardos: response2.d.datos["sesiones_retardos"][i],
                  sesiones_salida: response2.d.datos["sesiones_salida"][i],
                  minutos_retardos: response2.d.datos["minutos_retardos"][i],
                  minutos_cancelados:
                    response2.d.datos["minutos_cancelados"][i],
                  minutos_salida: response2.d.datos["minutos_salida"][i],
                };
                newArray.push(fdaObject);
              }
              setDataConsultaRevision(newArray);
              setDataConsultaOriginalRevision(newArray);
              setLoadingConsulta(false);
              setMotivoModalValue("");
              setParticipaFDA("");
              setSolicitudCambiosModalIsOpen(false);
            }
          } else {
            setLoading(false);
            enqueueSnackbar(response.m, {
              variant: "error",
              autoHideDuration: null,
              anchorOrigin: {
                vertical: "top",
                horizontal: "left",
              },
            });
            setLoadingConsulta(false);
          }
        } else {
          enqueueSnackbar(
            "No es posible cancelar cuando el grupo no tiene % FDA",
            {
              variant: "error",
              autoHideDuration: null,
              anchorOrigin: {
                vertical: "top",
                horizontal: "left",
              },
            }
          );
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoadingModal(false);
        setLoadingConsulta(false);
      }
    }
  };

  const handleProceso = async (e) => {
    setProceso(e);
    if (e.value === "consulta") {
      setShowConsulta(true);
      openModalAutorizarDiv(idModal);
    } else {
      setShowConsulta(false);
    }
  };

  const handleCancelar = async () => {
    try {
      setDataSolicitudesDiv(dataSolicitudesDivOriginal);
      setAutorizar([]);
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingConsulta(false);
    }
  };

  const handleAutorizacionDivisional = async () => {
    setLoadingConsulta(true);
    try {
      const body = {
        id: idModal,
        type: "autorizar-consulta",
      };
      const response = await postRequest("/fda/aprobar-div", body);
      if (response.s === "OK") {
        enqueueSnackbar(response.m, {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
        const request2 = `/fda?id=${idModal}&tipo=consulta_Div_aprobado`;
        const response2 = await getRequest(request2);
        if (response2.s === "OK") {
          const nro = response2.d.datos["id"].length;
          const newArray = [];
          for (let i = 0; i < nro; i++) {
            const fdaObject = {
              grupo: response2.d.datos["grupo"][i],
              clave_materia: response2.d.datos["clave_materia"][i],
              nombre_materia: response2.d.datos["nombre_materia"][i],
              clave_profesor: response2.d.datos["clave_profesor"][i],
              nombre_profesor: response2.d.datos["nombre_profesor"][i],
              promedio_evaluacion: response2.d.datos["promedio_evaluacion"][i],
              fecha_alta: response2.d.datos["fecha_alta"][i],
              fecha_baja: response2.d.datos["fecha_baja"][i],
              participa: response2.d.datos["participa"][i],
              fda: response2.d.datos["porcentaje_fda"][i],
              estatus: response2.d.datos["estatus"][i],
              cambio_solicitado: response2.d.datos["solicitud"][i],
              id: response2.d.datos["id"][i],
              horas_programa: response2.d.datos["horas_programa"][i],
              horas_falta: response2.d.datos["horas_falta"][i],
              horas_autorizadas: response2.d.datos["horas_autorizadas"][i],
              horas_sustitucion: response2.d.datos["horas_sustitucion"][i],
              horas_adicionales: response2.d.datos["horas_adicionales"][i],
              horas_reposicion: response2.d.datos["horas_reposicion"][i],
              sesiones_clase: response2.d.datos["sesiones_clase"][i],
              sesiones_retardos: response2.d.datos["sesiones_retardos"][i],
              sesiones_salida: response2.d.datos["sesiones_salida"][i],
              minutos_retardos: response2.d.datos["minutos_retardos"][i],
              minutos_cancelados: response2.d.datos["minutos_cancelados"][i],
              minutos_salida: response2.d.datos["minutos_salida"][i],
              director: response2.d.datos["nombre_director"][i],
              direccion: response2.d.datos["direccion"][i],
              fdas_estatus: response2.d.datos["fdas_estatus"][i],
            };
            newArray.push(fdaObject);
          }
          setDataConsultaDiv(newArray);
          setDataConsultaDivOriginal(newArray);
        }
      } else {
        enqueueSnackbar(response.m, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingConsulta(false);
    }
  };

  const handleAutorizacionRec = async () => {
    let valido = !dataConsultaDiv.some((item) => {
      return item.fdas_estatus === "Pendiente";
    });
    if (valido) {
      try {
        setLoadingConsulta(true);
        const body = {
          id: idModal,
        };
        const response = await postRequest("/fda/aprobar-rec", body);
        if (response.s === "OK") {
          enqueueSnackbar(response.m, {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "left",
            },
          });
          const request2 = `/fda?id=${idModal}&tipo=consulta_Rec`;
          const response2 = await getRequest(request2);
          if (response2.s === "OK") {
            const nro = response2.d.datos["id"].length;
            const newArray = [];
            for (let i = 0; i < nro; i++) {
              const fdaObject = {
                grupo: response2.d.datos["grupo"][i],
                clave_materia: response2.d.datos["clave_materia"][i],
                nombre_materia: response2.d.datos["nombre_materia"][i],
                clave_profesor: response2.d.datos["clave_profesor"][i],
                nombre_profesor: response2.d.datos["nombre_profesor"][i],
                promedio_evaluacion:
                  response2.d.datos["promedio_evaluacion"][i],
                fecha_alta: response2.d.datos["fecha_alta"][i],
                fecha_baja: response2.d.datos["fecha_baja"][i],
                participa: response2.d.datos["participa"][i],
                fda: response2.d.datos["porcentaje_fda"][i],
                estatus: response2.d.datos["estatus"][i],
                cambio_solicitado: response2.d.datos["solicitud"][i],
                id: response2.d.datos["id"][i],
                horas_programa: response2.d.datos["horas_programa"][i],
                horas_falta: response2.d.datos["horas_falta"][i],
                horas_autorizadas: response2.d.datos["horas_autorizadas"][i],
                horas_sustitucion: response2.d.datos["horas_sustitucion"][i],
                horas_adicionales: response2.d.datos["horas_adicionales"][i],
                horas_reposicion: response2.d.datos["horas_reposicion"][i],
                sesiones_clase: response2.d.datos["sesiones_clase"][i],
                sesiones_retardos: response2.d.datos["sesiones_retardos"][i],
                sesiones_salida: response2.d.datos["sesiones_salida"][i],
                minutos_retardos: response2.d.datos["minutos_retardos"][i],
                minutos_cancelados: response2.d.datos["minutos_cancelados"][i],
                minutos_salida: response2.d.datos["minutos_salida"][i],
                director: response2.d.datos["nombre_director"][i],
                direccion: response2.d.datos["direccion"][i],
                fdas_estatus: response2.d.datos["fdas_estatus"][i],
              };
              newArray.push(fdaObject);
            }
            setDataConsultaDiv(newArray);
            setDataConsultaDivOriginal(newArray);
            setLoadingConsulta(false);
          }
        } else {
          enqueueSnackbar(response.m, {
            variant: "error",
            autoHideDuration: null,
            anchorOrigin: {
              vertical: "top",
              horizontal: "left",
            },
          });
          setLoadingConsulta(false);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoadingConsulta(false);
      }
    } else {
      enqueueSnackbar("Hay solicitudes pendientes. No se puede autorizar", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    }
  };

  const handleRowSelection2 = async (params) => {
    setRowSelected(params.id);
    try {
      setDataIndicadores1([
        [
          params.row.horas_programa,
          params.row.horas_falta,
          params.row.horas_autorizadas,
          params.row.horas_sustitucion,
          params.row.horas_reposicion,
          params.row.horas_adicionales,
        ],
      ]);
      setDataIndicadores2([
        [
          params.row.sesiones_clase,
          params.row.sesiones_retardos,
          params.row.sesiones_salida,
        ],
      ]);
      setDataIndicadores3([
        [
          params.row.minutos_retardos,
          params.row.minutos_cancelados,
          params.row.minutos_salida,
        ],
      ]);
      setMotivoModalValue(params.row.motivo);
      setDataIndicadores4([[params.row.fecha_baja]]);
    } catch (error) {
      console.log(error);
    }
  };

  const handleChangeRevisionSelect = async (e) => {
    setDireccion(e);
    setLoadingConsulta(true);
    setLoadingSelectConsulta(true);
    try {
      const request = `/fda?id=${idModal}&tipo=consulta_revision&id_direccion=${e.value}`;
      const response = await getRequest(request);
      if (response.s === "OK") {
        const nro = response.d.datos["id"].length;
        const newArray = [];
        for (let i = 0; i < nro; i++) {
          const fdaObject = {
            grupo: response.d.datos["grupo"][i],
            clave_materia: response.d.datos["clave_materia"][i],
            nombre_materia: response.d.datos["nombre_materia"][i],
            clave_profesor: response.d.datos["clave_profesor"][i],
            nombre_profesor: response.d.datos["nombre_profesor"][i],
            evaluacion_promedio: response.d.datos["promedio_evaluacion"][i],
            fecha_alta: response.d.datos["fecha_alta"][i],
            fecha_baja: response.d.datos["fecha_baja"][i],
            participa: response.d.datos["participa"][i],
            fda: response.d.datos["porcentaje_fda"][i],
            estatus: response.d.datos["estatus"][i],
            especial: response.d.datos["solicitud"][i],
            id: response.d.datos["id"][i],
            horas_programa: response.d.datos["horas_programa"][i],
            horas_falta: response.d.datos["horas_falta"][i],
            horas_autorizadas: response.d.datos["horas_autorizadas"][i],
            horas_sustitucion: response.d.datos["horas_sustitucion"][i],
            horas_adicionales: response.d.datos["horas_adicionales"][i],
            horas_reposicion: response.d.datos["horas_reposicion"][i],
            sesiones_clase: response.d.datos["sesiones_clase"][i],
            sesiones_retardos: response.d.datos["sesiones_retardos"][i],
            sesiones_salida: response.d.datos["sesiones_salida"][i],
            minutos_retardos: response.d.datos["minutos_retardos"][i],
            minutos_cancelados: response.d.datos["minutos_cancelados"][i],
            minutos_salida: response.d.datos["minutos_salida"][i],
          };
          newArray.push(fdaObject);
        }
        setDataConsultaRevision(newArray);
        setDataConsultaOriginalRevision(newArray);
        enqueueSnackbar(response.m, {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
        setLoadingConsulta(false);
        setLoadingSelectConsulta(false);
      } else {
        enqueueSnackbar(response.m, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
        setLoadingConsulta(false);
        setLoadingSelectConsulta(false);
      }
    } catch (error) {
      console.log(error);
      setLoadingConsulta(false);
      setLoadingSelectConsulta(false);
    }
  };

  const handleAprobarRevision = async () => {
    let pending = dataConsultaOriginalRevision.some((item) =>
      item.especial.includes("Pendiente")
    );
    let valido = !dataConsultaOriginalRevision.some((item) =>
      item.especial.includes("Aprobado Div")
    );
    if (pending) {
      enqueueSnackbar(
        "Existen registros pendientes por aprobar por el divisional",
        {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        }
      );
    }
    if (!valido) {
      enqueueSnackbar("Se encontraron registro aprobados por el divisonal", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    }
    if (valido && !pending) {
      try {
        setLoadingConsulta(true);
        const body = {
          id: idModal,
          id_direccion: direccion.value,
        };
        const response = await postRequest("/fda/aprobar-revision", body);
        if (response.s === "OK") {
          enqueueSnackbar(response.m, {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "left",
            },
          });
          const request2 = `/fda?id=${idModal}&tipo=consulta_revision&id_direccion=${direccion.value}`;
          const response2 = await getRequest(request2);
          if (response2.s === "OK") {
            const nro = response2.d.datos["id"].length;
            const newArray = [];
            for (let i = 0; i < nro; i++) {
              const fdaObject = {
                grupo: response2.d.datos["grupo"][i],
                clave_materia: response2.d.datos["clave_materia"][i],
                nombre_materia: response2.d.datos["nombre_materia"][i],
                clave_profesor: response2.d.datos["clave_profesor"][i],
                nombre_profesor: response2.d.datos["nombre_profesor"][i],
                evaluacion_promedio:
                  response2.d.datos["promedio_evaluacion"][i],
                fecha_alta: response2.d.datos["fecha_alta"][i],
                fecha_baja: response2.d.datos["fecha_baja"][i],
                participa: response2.d.datos["participa"][i],
                fda: response2.d.datos["porcentaje_fda"][i],
                estatus: response2.d.datos["estatus"][i],
                especial: response2.d.datos["solicitud"][i],
                id: response2.d.datos["id"][i],
                horas_programa: response2.d.datos["horas_programa"][i],
                horas_falta: response2.d.datos["horas_falta"][i],
                horas_autorizadas: response2.d.datos["horas_autorizadas"][i],
                horas_sustitucion: response2.d.datos["horas_sustitucion"][i],
                horas_adicionales: response2.d.datos["horas_adicionales"][i],
                horas_reposicion: response2.d.datos["horas_reposicion"][i],
                sesiones_clase: response2.d.datos["sesiones_clase"][i],
                sesiones_retardos: response2.d.datos["sesiones_retardos"][i],
                sesiones_salida: response2.d.datos["sesiones_salida"][i],
                minutos_retardos: response2.d.datos["minutos_retardos"][i],
                minutos_cancelados: response2.d.datos["minutos_cancelados"][i],
                minutos_salida: response2.d.datos["minutos_salida"][i],
              };
              newArray.push(fdaObject);
            }
            setDataConsultaRevision(newArray);
            setDataConsultaOriginalRevision(newArray);
            setLoadingConsulta(false);
          }
        } else {
          enqueueSnackbar(response.m, {
            variant: "error",
            autoHideDuration: null,
            anchorOrigin: {
              vertical: "top",
              horizontal: "left",
            },
          });
          setLoadingConsulta(false);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoadingModal(false);
        setLoadingConsulta(false);
      }
    }
  };

  const handleSave = async () => {
    try {
      setLoadingConsulta(true);
      const body = {
        data: autorizar,
        type: "autorizar",
      };
      const response = await postRequest("/fda/aprobar-div", body);
      if (response.s === "OK") {
        enqueueSnackbar(response.m, {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
        setLoadingConsulta(true);
        const request2 = `/fda?id=${idModal}&tipo=consulta_Div`;
        const response2 = await getRequest(request2);
        if (response2.s === "OK") {
          const nro = response2.d.datos["id"].length;
          const newArray = [];
          const autorizarValue = [];
          for (let i = 0; i < nro; i++) {
            const fdaObject = {
              grupo: response2.d.datos["grupo"][i],
              clave_materia: response2.d.datos["clave_materia"][i],
              nombre_materia: response2.d.datos["nombre_materia"][i],
              clave_profesor: response2.d.datos["clave_profesor"][i],
              nombre_profesor: response2.d.datos["nombre_profesor"][i],
              promedio_evaluacion: response2.d.datos["promedio_evaluacion"][i],
              fecha_alta: response2.d.datos["fecha_alta"][i],
              fecha_baja: response2.d.datos["fecha_baja"][i],
              participa: response2.d.datos["participa"][i],
              fda: response2.d.datos["porcentaje_fda"][i],
              estatus: response2.d.datos["estatus"][i],
              cambio_solicitado: response2.d.datos["solicitud"][i],
              id: response2.d.datos["id"][i],
              horas_programa: response2.d.datos["horas_programa"][i],
              horas_falta: response2.d.datos["horas_falta"][i],
              horas_autorizadas: response2.d.datos["horas_autorizadas"][i],
              horas_sustitucion: response2.d.datos["horas_sustitucion"][i],
              horas_adicionales: response2.d.datos["horas_adicionales"][i],
              horas_reposicion: response2.d.datos["horas_reposicion"][i],
              sesiones_clase: response2.d.datos["sesiones_clase"][i],
              sesiones_retardos: response2.d.datos["sesiones_retardos"][i],
              sesiones_salida: response2.d.datos["sesiones_salida"][i],
              minutos_retardos: response2.d.datos["minutos_retardos"][i],
              minutos_cancelados: response2.d.datos["minutos_cancelados"][i],
              minutos_salida: response2.d.datos["minutos_salida"][i],
              director: response2.d.datos["nombre_director"][i],
              direccion: response2.d.datos["direccion"][i],
              fdas_estatus: response2.d.datos["fdas_estatus"][i],
            };
            newArray.push(fdaObject);
            autorizarValue.push({
              id: response2.d.datos["id"][i],
              value: response2.d.datos["fdas_estatus"][i],
              label:
                response2.d.datos["fdas_estatus"][i] === "Pendiente"
                  ? "Pendiente"
                  : response2.d.datos["fdas_estatus"][i] === "Aprobado"
                  ? "Si"
                  : "No",
            });
          }
          setAutorizar(autorizarValue);
          setDataSolicitudesDiv(newArray);
          setDataSolicitudesDivOriginal(newArray);
          setLoadingConsulta(false);
        }
      } else {
        enqueueSnackbar(response.m, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
        setLoadingConsulta(false);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingModal(false);
      setLoadingConsulta(false);
    }
  };

  const checkStatus = (status) => {
    const onlyStatus = status?.map((item) => item[11]);
    const searchTerms = [
      "Aprobado Div",
      "Aprobado REC",
      "Trasladado",
      "Envio Banner",
    ];
    const found = searchTerms?.some((term) => onlyStatus?.includes(term));
    return found;
  };

  const solicitudesModal = [
    <div
      style={{
        height: "calc(100% - 350px)",
        width: "96%",
        marginTop: "2%",
        marginLeft: "2%",
      }}
    >
      <DataGrid
        rows={proceso === "" ? [] : dataSolicitudesDiv}
        columns={columnsSolicitudes}
        // autoHeight
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        experimentalFeatures={{ newEditingApi: true }}
        loading={loadingConsulta}
        rowsPerPageOptions={[5, 10, 15]}
        pagination
        getRowHeight={() => "auto"}
        sx={{
          fontSize: "16px",
        }}
        onRowClick={handleRowSelection2}
      />
    </div>,
    <Grid container item md={2} style={{ float: "right", marginTop: "1%" }}>
      <div style={{ display: "flex", marginLeft: "-6%" }}>
        <NewButton
          noIcon
          customText="Cancelar"
          style={{ backgroundColor: "#989696" }}
          disabled={disabled}
          onClick={handleCancelar}
        />
        <NewButton
          noIcon
          customText="Guardar"
          style={{ marginLeft: "5%" }}
          disabled={disabled}
          onClick={handleSave}
        />
      </div>
    </Grid>,
    <div
      style={{
        display: "flex",
        marginTop: "1%",
        marginLeft: "2%",
        alignItems: "center",
      }}
    >
      <Grid item xs={1} md={1}>
        <label>Motivo:</label>
      </Grid>
      <Grid xs={1} md={3}>
        <input disabled className="filter-input" value={motivoModalValue} />
      </Grid>
    </div>,
  ];
  return (
    <Contenedor title="Profesores / Cálculo y Generación FDA">
      <Grid
        container
        className="nuevoUsuarioForm"
        style={{ position: "relative", zIndex: 9 }}
      >
        {/* Grid para los inputs */}
        <Grid container item xs={12} sm={11}>
          {/* Primera Fila */}
          <Grid
            container
            alignItems="center"
            spacing={2} /* style={{marginTop: "-60px"}} */
          >
            <Grid item xs={10} sm={1} style={{ textAlign: "end" }}>
              <label>Ciclo(*):</label>
            </Grid>
            <Grid item xs={14} sm={1}>
              <input
                className="filter-input input-ciclo"
                placeholder="Indique el ciclo..."
                value={cicloValue}
                onChange={(e) => setCicloValue(e.target.value)}
              />
            </Grid>
            <Grid item xs={10} sm={1} style={{ textAlign: "end" }}>
              <label>Escuela(*)</label>
            </Grid>
            <Grid item xs={10} sm={2}>
              <Select
                className="select-modal-container"
                classNamePrefix="select-modal"
                value={valueEscuela}
                options={optionsEscuela}
                onChange={setValueEscuela}
              />
            </Grid>
            <Grid item xs={10} sm={1} style={{ textAlign: "end" }}>
              <label>Campus(*)</label>
            </Grid>
            <Grid item xs={10} sm={2}>
              <Select
                className="select-modal-container"
                classNamePrefix="select-modal"
                value={valueCampus}
                options={campusValores}
                onChange={setValueCampus}
              />
            </Grid>
            <Grid item xs={10} sm={2} style={{ textAlign: "end" }}>
              <NewButton
                onClick={handleClick}
                noIcon
                style={{ padding: "10px 25px" }}
                customText="Buscar"
              />
            </Grid>
            <Grid item xs={10} sm={2} style={{ textAlign: "end" }}>
              <NewButton
                onClick={handleClickGenerar}
                noIcon
                style={{
                  padding: "9px 24px",
                  marginLeft: "10px",
                  display:
                    permisos["Profesores->FDA"].escribir === "1"
                      ? "block"
                      : "none",
                }}
                disabled={isButtonDisabledCoordinador(
                  permisos["Profesores->FDA_Generar"]?.leer,
                  rol,
                  userDirections
                )}
                customText="Generar"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {/* Tabla */}
      <div className={styles.opcionesBar} style={{ paddingTop: "35px" }}>
        <div style={{ float: "right", width: "15%" }}>
          <SearchInput hideIcon search={search} />
        </div>
      </div>

      <div style={{ height: "calc(80%)" }}>
        <DataTable
          headers={headers}
          data={data}
          loading={loading}
          centrar={[1, 2, 3, 4, 5, 6, 7, 8, 9]}
          paginate
          bordes
        />
      </div>

      <NewModal
        title="Consulta FDA"
        open={consultaModalIsOpen}
        handleClose={() => {
          setModalConsultaValues("");
          setFDAButtonStyle1("black-btn reportePre");
          setFDAButtonStyle2("black-btn reporteDef");
          setFDAButtonStyle3("black-btn trasladar");
          setFDAButtonStyle4("black-btn envioBanner");
          setDisabled(false);
          setHidden(false);
          setConsultaModalIsOpen(false);
        }}
        loading={loadingModal}
        height={800}
        maxWidth={1550}
        noButtons
        fdaTrasladar={
          isButtonDisabledCoordinador(
            permisos["Profesores->FDA_Trasladar"]?.escribir,
            rol,
            userDirections
          )
            ? false
            : handleTrasladar
        }
        fdaEnvioBanner={
          isButtonDisabledCoordinador(
            permisos["Profesores->FDA_Envio Banner"]?.escribir,
            rol,
            userDirections
          )
            ? false
            : handleEnvioBanner
        }
        fdaDisabled={disabled}
        overflowY={"clip"}
        handleClickEliminado={
          !disabled
            ? isButtonDisabledCoordinador(
                permisos["Profesores->FDA_Calcular"]?.escribir,
                rol,
                userDirections
              )
              ? false
              : handleClickEliminado
            : false
        }
        fdaButtonStyle1={fdaButtonStyle1}
        fdaButtonStyle2={fdaButtonStyle2}
        fdaButtonStyle3={fdaButtonStyle3}
        fdaButtonStyle4={fdaButtonStyle4}
        fdaButtonStyle5={fdaButtonStyle5}
      >
        <div className="inputs-asignaciones" style={{ marginTop: "20px" }}>
          <Grid
            container
            style={{
              marginTop: "1%",
              paddingInline: "15px",
              alignItems: "center",
              textAlign: "end",
            }}
            spacing={0.5}
          >
            <Grid item xs={1}>
              <label>Folio:</label>
            </Grid>
            <Grid item xs={1}>
              <input
                disabled
                className="filter-input"
                value={modalConsultaValues.folio}
              />
            </Grid>
            <Grid item xs={1}>
              <label>Ciclo:</label>
            </Grid>
            <Grid item xs={1}>
              <input
                disabled
                className="filter-input"
                value={modalConsultaValues.ciclo}
              />
            </Grid>
            <Grid item xs={1}>
              <label>Campus:</label>
            </Grid>
            <Grid item xs={2}>
              <input
                disabled
                className="filter-input"
                value={modalConsultaValues.campus}
              />
            </Grid>
            <Grid item xs={1}>
              <label>Escuela:</label>
            </Grid>
            <Grid item xs={!disabled ? 2 : 4}>
              <input
                disabled
                className="filter-input"
                value={modalConsultaValues.escuela}
              />
            </Grid>
            {!disabled && (
              <Grid item xs={2} style={{ textAlign: "center " }}>
                <NewButton
                  onClick={handleClickCalcular}
                  noIcon
                  style={{
                    padding: "7px 25px",
                    zIndex: 300,
                  }}
                  customText="Calcular"
                  disabled={
                    disabled ||
                    isButtonDisabledCoordinador(
                      permisos["Profesores->FDA_Calcular"]?.escribir,
                      rol,
                      userDirections
                    ) ||
                    checkStatus(dataConsulta)
                  }
                />
              </Grid>
            )}
            {disabled && (
              <>
                <Grid item xs={1}>
                  <label>Estatus:</label>
                </Grid>
                <Grid item xs={1}>
                  <input
                    disabled
                    className="filter-input"
                    value={modalConsultaValues.estatus}
                  />
                </Grid>
                <Grid item xs={1}>
                  <label>Creado:</label>
                </Grid>
                <Grid item xs={4}>
                  <input
                    disabled
                    className="filter-input"
                    value={`${modalConsultaValues.creado} - ${modalConsultaValues.creado_por}`}
                  />
                </Grid>
                <Grid item xs={1}>
                  <label>Modificado:</label>
                </Grid>
                <Grid item xs={4}>
                  <input
                    disabled
                    className="filter-input"
                    value={`${modalConsultaValues.modificado} - ${modalConsultaValues.modificado_por}`}
                  />
                </Grid>
                <Grid item xs={1}>
                  <label>Motivo:</label>
                </Grid>
                <Grid item xs={6}>
                  <input
                    disabled
                    className="filter-input"
                    value={modalConsultaValues.comentario}
                  />
                </Grid>
                <Grid item xs={5} style={{ textAlign: "end" }}>
                  <NewButton
                    onClick={handleClickCalcular}
                    noIcon
                    style={{
                      padding: "10px 25px",
                      zIndex: 300,
                    }}
                    customText="Calcular"
                    disabled={disabled}
                  />
                </Grid>
              </>
            )}
          </Grid>
        </div>
        <Grid
          container
          style={{
            alignItems: "center",
            marginTop: "0.5%",
            width: "98%",
            justifyContent: "end",
          }}
        >
          <Grid item>
            <div style={{ zIndex: 301 }}>
              <SearchInput hideIcon disabled={disabled} search={search5} />
            </div>
          </Grid>
        </Grid>

        <div
          style={{
            height: disabled ? "calc(100% - 160px)" : "calc(100% - 90px)",
            width: "96%",
            marginTop: "0.5%",
            marginLeft: "2%",
          }}
        >
          <DataTable
            headers={headersConsulta}
            data={dataConsulta}
            loading={loadingConsulta}
            centrar={[1, 2, 3, 4, 5, 6, 7, 8, 9]}
            paginate
            bordes
          />
        </div>
      </NewModal>

      <NewModal
        title={"Confirmación"}
        open={modalComentarioIsOpen}
        handleClose={() => {
          setMotivoModalValue("");
          setModalComentarioIsOpen(false);
        }}
        loading={loadingModal}
        style
        height={200}
        maxWidth={660}
        marginTop={"12%"}
        handleSubmit={handleSubmitEliminado}
      >
        <Grid container style={{ marginTop: "3.5%", marginLeft: "-1%" }}>
          <Grid
            container
            item
            spacing={1}
            alignItems="center"
            justifyContent="center"
            style={{ marginTop: "0.5%" }}
          >
            <Grid
              item
              xs={12}
              md={7}
              style={{ textAlign: "center", marginLeft: "4%" }}
            >
              <label>¿Desea cambiar el estatus del folio a "Eliminado"?</label>
            </Grid>
            <Grid
              item
              xs={12}
              md={10}
              style={{ marginTop: "2%", display: "flex" }}
            >
              <label style={{ marginTop: "3%" }}>Motivo(*):</label>
              <TextareaAutosize
                className="filter-input value-comentario"
                maxRows={5}
                style={{
                  width: "100%",
                  marginLeft: "5%",
                  height: 80,
                  resize: "none",
                  fontSize: "15px",
                }}
                value={motivoModalValue}
                onChange={(e) => setMotivoModalValue(e.target.value)}
              />
            </Grid>
          </Grid>
        </Grid>
      </NewModal>

      <NewModal
        title={"Comentario"}
        open={modalComentario2IsOpen}
        handleClose={() => {
          setModalComentario2IsOpen(false);
          setDataConsulta([]);
          setDataConsultaOriginal([]);
        }}
        style
        height={100}
        maxWidth={500}
        marginTop={"12%"}
        noButtons
      >
        <Grid container style={{ marginTop: "3.5%", marginLeft: "-1%" }}>
          <Grid
            container
            item
            spacing={1}
            alignItems="center"
            justifyContent="center"
            style={{ marginTop: "0.5%" }}
          >
            <Grid
              item
              xs={12}
              md={7}
              style={{ textAlign: "center", marginLeft: "4%" }}
            >
              <label>
                {modalConsultaValues.total_profesor} Registros Enviados
              </label>
            </Grid>
          </Grid>
        </Grid>
      </NewModal>

      <NewModal
        title="Revisión FDA - Directores Académicos"
        open={revisionModalIsOpen}
        handleClose={() => {
          setRowSelected("");
          setModalRevisionValues("");
          setDireccion("");
          setDataIndicadores1([]);
          setDataIndicadores2([]);
          setDataIndicadores3([]);
          setDataIndicadores4([]);
          setRevisionModalIsOpen(false);
          setDataConsultaRevision([]);
          setDataConsultaOriginalRevision([]);
        }}
        loading={loadingModal}
        height={800}
        maxWidth={1550}
        noButtons
        marginBottom={"10px"}
        maxHeight={"80vh"}
      >
        <div className="inputs-asignaciones" style={{ marginTop: "25px" }}>
          <Grid container style={{ position: "relative", zIndex: 9 }}>
            <Grid container item xs={12} sm={12} style={{ marginTop: "-1%" }}>
              <Grid
                container
                style={{ alignItems: "center", marginLeft: "1%" }}
              >
                <Grid item xs={1} md={1}>
                  <label>Folio:</label>
                </Grid>
                <Grid xs={1} md={1}>
                  <input
                    disabled
                    className="filter-input"
                    style={{ width: "70%" }}
                    value={modalRevisionValues.folio}
                  />
                </Grid>
                <Grid xs={1} md={1} style={{ marginLeft: "-3%" }}>
                  <label>Ciclo:</label>
                </Grid>
                <Grid xs={1} md={1}>
                  <input
                    disabled
                    className="filter-input"
                    style={{ width: "100%" }}
                    value={modalRevisionValues.ciclo}
                  />
                </Grid>
                <Grid xs={1} md={1}>
                  <label>Campus:</label>
                </Grid>
                <Grid xs={1} md={2}>
                  <input
                    disabled
                    className="filter-input"
                    style={{ width: "100%" }}
                    value={modalRevisionValues.campus}
                  />
                </Grid>
                <Grid xs={1} md={1}>
                  <label>Escuela:</label>
                </Grid>
                <Grid xs={1} md={2}>
                  <input
                    disabled
                    className="filter-input"
                    style={{ width: "120%" }}
                    value={modalRevisionValues.escuela}
                  />
                </Grid>
              </Grid>
              <Grid container style={{ alignItems: "center", marginTop: "1%" }}>
                <Grid xs={1} md={1} style={{ marginLeft: "1%" }}>
                  <label>Dirección(*):</label>
                </Grid>
                <Grid xs={1} md={4}>
                  <Select
                    className="select-modal-container"
                    classNamePrefix="select-modal"
                    value={direccion}
                    options={direccionOptions}
                    onChange={(e) => handleChangeRevisionSelect(e)}
                    isLoading={loadingSelectConsulta}
                  />
                </Grid>
                <Grid xs={1} md={2} style={{ marginLeft: "2%" }}>
                  <input
                    disabled
                    className="filter-input"
                    style={{ width: "105%" }}
                    value={direccion.director}
                  />
                </Grid>
                <Grid xs={1} md={1} style={{ marginLeft: "14%" }}>
                  <NewButton
                    noIcon
                    style={{ padding: "5px 15px", width: "158%" }}
                    customText="Solicitar Cambio"
                    disabled={
                      disabled
                        ? true
                        : isButtonDisabledCoordinador(
                            permisos[
                              "Profesores->FDA->Revision DA->Solicitar Cambio"
                            ]?.leer,
                            rol,
                            userDirections
                          )
                    }
                    onClick={handleSolicitarCambio}
                  />
                </Grid>
                <Grid xs={1} md={1} style={{ marginLeft: "7%" }}>
                  <NewButton
                    noIcon
                    style={{ padding: "5px 15px", width: "165%" }}
                    customText="Aprobar Revisión"
                    disabled={
                      disabled
                        ? true
                        : isButtonDisabledCoordinador(
                            permisos[
                              "Profesores->FDA->Revision DA->Aprobar Revision"
                            ]?.leer,
                            rol,
                            userDirections
                          )
                    }
                    onClick={handleAprobarRevision}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
        <Grid
          container
          item
          xs={12}
          sm={3}
          style={{ marginTop: "0.5%", float: "right" }}
        >
          <Grid
            container
            item
            style={{ display: "flex", gap: 16, marginLeft: "-8%" }}
          >
            <div style={{ width: "200%" }}>
              <SearchInput hideIcon search={search2} />
            </div>
          </Grid>
        </Grid>
        {/* <div
          style={{
            height: "calc(100% - 370px)",
            width: "96%",
            marginTop: "4.5%",
            marginLeft: "2%",
          }}
        > */}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "96%",
            marginTop: "4%",
            marginLeft: "2%",
          }}
        >
          <div style={{ flex: 1 }}>
            <DataGrid
              rows={dataConsultaRevision}
              columns={columns}
              autoHeight
              pageSize={pageSize}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              rowsPerPageOptions={[5, 10, 15]}
              pagination
              {...data}
              loading={loadingConsulta}
              getRowHeight={() => "auto"}
              sx={{
                fontSize: "16px",
              }}
              onRowClick={handleRowSelection}
            />
          </div>
          <div className={styles.indicadores} style={{ flex: 1 }}>
            <Grid container item xs={12} sm={3} style={{ marginLeft: "1%" }}>
              <label className={styles.indicadoresTitle}>
                Indicadores Académicos
              </label>
            </Grid>
            <div style={{ display: "flex" }}>
              <div className={styles.divSubtitle1}>
                <label className={styles.subtitles}>Horas</label>
              </div>
              <div className={styles.divSubtitle2y3}>
                <label className={styles.subtitles}>Sesiones</label>
              </div>
              <div className={styles.divSubtitle2y3}>
                <label className={styles.subtitles}>Minutos</label>
              </div>
            </div>
            <div style={{ display: "flex" }}>
              <div style={{ height: "48%", width: "41.3%" }}>
                <DataTable
                  headers={headersIndicadores1}
                  data={dataIndicadores1}
                  loading={loadingConsulta}
                  centrar={[1, 2, 3, 4, 5, 6]}
                  bordes
                />
              </div>
              <div style={{ height: "48%", width: "22.5%", marginLeft: "1%" }}>
                <DataTable
                  headers={headersIndicadores2}
                  data={dataIndicadores2}
                  loading={loadingConsulta}
                  centrar={[1, 2, 3]}
                  bordes
                />
              </div>
              <div style={{ height: "48%", width: "22.5%", marginLeft: "1%" }}>
                <DataTable
                  headers={headersIndicadores3}
                  data={dataIndicadores3}
                  loading={loadingConsulta}
                  centrar={[1, 2, 3]}
                  bordes
                />
              </div>
              <div style={{ height: "48%", width: "10.7%", marginLeft: "1%" }}>
                <DataTable
                  headers={headersIndicadores4}
                  data={dataIndicadores4}
                  loading={loadingConsulta}
                  centrar={[1, 2, 3]}
                  bordes
                />
              </div>
            </div>
          </div>
        </div>
      </NewModal>

      <NewModal
        title={"Solicitud de Cambios"}
        open={solicitudCambiosModalIsOpen}
        handleClose={() => {
          setParticipaFDA("");
          setMotivoModalValue("");
          setSolicitudCambiosModalIsOpen(false);
        }}
        style
        height={400}
        maxWidth={610}
        loading={loadingModal}
        handleSubmit={handleGuardarSolicitud}
        guardar
      >
        <div className="inputs-asignaciones" style={{ marginTop: "25px" }}>
          <Grid container style={{ position: "relative", zIndex: 9 }}>
            <Grid
              container
              item
              xs={12}
              sm={12}
              style={{ marginTop: "-1%", marginLeft: "2%" }}
            >
              <Grid container style={{ alignItems: "center" }}>
                <Grid item xs={1} md={3}>
                  <label>Clave Profesor:</label>
                </Grid>
                <Grid xs={1} md={2}>
                  <input
                    disabled
                    className="filter-input"
                    value={modalSolicitudValues.clave_profesor}
                  />
                </Grid>
                <Grid xs={1} md={6} style={{ marginLeft: "2%" }}>
                  <input
                    disabled
                    className="filter-input"
                    value={modalSolicitudValues.nombre_profesor}
                  />
                </Grid>
              </Grid>
              <Grid container style={{ alignItems: "center", marginTop: "1%" }}>
                <Grid item xs={1} md={3}>
                  <label>Grupo:</label>
                </Grid>
                <Grid xs={1} md={2}>
                  <input
                    disabled
                    className="filter-input"
                    value={modalSolicitudValues.grupo}
                  />
                </Grid>
              </Grid>
              <Grid container style={{ alignItems: "center", marginTop: "1%" }}>
                <Grid item xs={1} md={3}>
                  <label>Materia:</label>
                </Grid>
                <Grid xs={1} md={2}>
                  <input
                    disabled
                    className="filter-input"
                    value={modalSolicitudValues.clave_materia}
                  />
                </Grid>
                <Grid xs={1} md={6} style={{ marginLeft: "2%" }}>
                  <input
                    disabled
                    className="filter-input"
                    value={modalSolicitudValues.nombre_materia}
                  />
                </Grid>
              </Grid>
              <Grid container style={{ alignItems: "center", marginTop: "7%" }}>
                <Grid item xs={1} md={3}>
                  <label>¿Participa FDA?:</label>
                </Grid>
                <Grid xs={1} md={3}>
                  <Select
                    className="select-modal-container"
                    classNamePrefix="select-modal"
                    value={participaFDA}
                    options={participaFDAoptions}
                    onChange={setParticipaFDA}
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={1} md={3} style={{ marginTop: "4%" }}>
                  <label>Motivo:</label>
                </Grid>
                <Grid xs={1} md={8}>
                  <TextareaAutosize
                    className="filter-input value-comentario"
                    maxRows={5}
                    style={{
                      marginTop: "4%",
                      width: "100%",
                      height: 130,
                      resize: "none",
                      fontSize: "14px",
                    }}
                    value={motivoModalValue}
                    onChange={(e) => setMotivoModalValue(e.target.value)}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </NewModal>

      <NewModal
        title="Autorización FDA - Director Divisional"
        open={autorizacionDivModalIsOpen}
        handleClose={() => {
          setRowSelected("");
          setMotivoModalValue("");
          setModalConsultaValues("");
          setProceso("");
          setDataIndicadores1([]);
          setDataIndicadores2([]);
          setDataIndicadores3([]);
          setDataIndicadores4([]);
          setShowConsulta(false);
          setAutorizacionDivModalIsOpen(false);
        }}
        loading={loadingModal}
        height={800}
        maxWidth={1550}
        noButtons
        marginBottom={"10px"}
        maxHeight={"80vh"}
      >
        <div className="inputs-asignaciones" style={{ marginTop: "25px" }}>
          <Grid container style={{ position: "relative", zIndex: 9 }}>
            <Grid container item xs={12} sm={12} style={{ marginTop: "-1%" }}>
              <Grid
                container
                style={{ alignItems: "center", marginLeft: "1%" }}
              >
                <Grid item xs={1} md={1}>
                  <label>Folio:</label>
                </Grid>
                <Grid xs={1} md={1}>
                  <input
                    disabled
                    className="filter-input"
                    style={{ width: "70%" }}
                    value={modalConsultaValues.folio}
                  />
                </Grid>
                <Grid xs={1} md={1} style={{ marginLeft: "-4%" }}>
                  <label>Ciclo:</label>
                </Grid>
                <Grid xs={1} md={1}>
                  <input
                    disabled
                    className="filter-input"
                    style={{ width: "100%" }}
                    value={modalConsultaValues.ciclo}
                  />
                </Grid>
                <Grid xs={1} md={1}>
                  <label>Campus:</label>
                </Grid>
                <Grid xs={1} md={2}>
                  <input
                    disabled
                    className="filter-input"
                    style={{ width: "100%" }}
                    value={modalConsultaValues.campus}
                  />
                </Grid>
                <Grid xs={1} md={1}>
                  <label>Escuela:</label>
                </Grid>
                <Grid xs={1} md={2}>
                  <input
                    disabled
                    className="filter-input"
                    style={{ width: "120%" }}
                    value={modalConsultaValues.escuela}
                  />
                </Grid>
              </Grid>
              <Grid container style={{ alignItems: "center", marginTop: "1%" }}>
                <Grid xs={1} md={1}>
                  <label>Proceso:</label>
                </Grid>
                <Grid xs={1} md={3}>
                  <Select
                    className="select-modal-container"
                    classNamePrefix="select-modal"
                    value={proceso}
                    options={procesoOptions}
                    onChange={(e) => handleProceso(e)}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
        <Grid
          container
          item
          xs={12}
          sm={2}
          style={{ marginTop: "-1%", float: "right", marginRight: "1%" }}
        >
          {showConsulta && (
            <Grid containet item style={{ margin: "-14% 5% 5% ", zIndex: 99 }}>
              <NewButton
                noIcon
                customText="Autorización Divisional"
                style={{ padding: "8px 12.5px", width: "110%" }}
                disabled={disabled}
                onClick={handleAutorizacionDivisional}
              />
            </Grid>
          )}
          {showConsulta ? (
            <Grid container item>
              <div style={{ width: "140%" }}>
                <SearchInput hideIcon search={search4} />
              </div>
            </Grid>
          ) : (
            <Grid container item>
              <div style={{ width: "140%" }}>
                <SearchInput hideIcon search={search3} />
              </div>
            </Grid>
          )}
        </Grid>
        {showConsulta ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "96%",
              marginTop: "4%",
              marginLeft: "2%",
            }}
          >
            <div style={{ flex: 1 }}>
              <DataGrid
                rows={dataConsultaDiv}
                columns={columnsConsulta2}
                autoHeight
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                experimentalFeatures={{ newEditingApi: true }}
                loading={loadingConsulta}
                rowsPerPageOptions={[5, 10, 15]}
                pagination
                getRowHeight={() => "auto"}
                sx={{
                  fontSize: "16px",
                }}
                onRowClick={handleRowSelection2}
              />
            </div>
          </div>
        ) : (
          solicitudesModal
        )}
        <div className={styles.indicadores} style={{ flex: 1 }}>
          <Grid container item xs={12} sm={3} style={{ marginLeft: "1%" }}>
            <label className={styles.indicadoresTitle}>
              Indicadores Académicos
            </label>
          </Grid>
          <div style={{ display: "flex" }}>
            <div className={styles.divSubtitle1}>
              <label className={styles.subtitles}>Horas</label>
            </div>
            <div className={styles.divSubtitle2y3}>
              <label className={styles.subtitles}>Sesiones</label>
            </div>
            <div className={styles.divSubtitle2y3}>
              <label className={styles.subtitles}>Minutos</label>
            </div>
          </div>
          <div style={{ display: "flex" }}>
            <div style={{ height: "48%", width: "41.3%" }}>
              <DataTable
                headers={headersIndicadores1}
                data={dataIndicadores1}
                loading={loadingConsulta}
                centrar={[1, 2, 3, 4, 5, 6]}
                bordes
              />
            </div>
            <div style={{ height: "48%", width: "22.5%", marginLeft: "1%" }}>
              <DataTable
                headers={headersIndicadores2}
                data={dataIndicadores2}
                loading={loadingConsulta}
                centrar={[1, 2, 3]}
                bordes
              />
            </div>
            <div style={{ height: "48%", width: "22.5%", marginLeft: "1%" }}>
              <DataTable
                headers={headersIndicadores3}
                data={dataIndicadores3}
                loading={loadingConsulta}
                centrar={[1, 2, 3]}
                bordes
              />
            </div>
            <div style={{ height: "48%", width: "10.7%", marginLeft: "1%" }}>
              <DataTable
                headers={headersIndicadores4}
                data={dataIndicadores4}
                loading={loadingConsulta}
                centrar={[1, 2, 3]}
                bordes
              />
            </div>
          </div>
        </div>
      </NewModal>

      <NewModal
        title="Autorización FDA - Rector"
        open={autorizacionRecModalIsOpen}
        handleClose={() => {
          setRowSelected("");
          setModalConsultaValues("");
          setDataIndicadores1([]);
          setDataIndicadores2([]);
          setDataIndicadores3([]);
          setDataIndicadores4([]);
          setAutorizacionRecModalIsOpen(false);
        }}
        loading={loadingModal}
        height={800}
        maxWidth={1550}
        noButtons
        marginBottom={"10px"}
        maxHeight={"80vh"}
      >
        <div className="inputs-asignaciones" style={{ marginTop: "25px" }}>
          <Grid container style={{ position: "relative", zIndex: 9 }}>
            <Grid container item xs={12} sm={12} style={{ marginTop: "-1%" }}>
              <Grid
                container
                style={{ alignItems: "center", marginLeft: "1%" }}
              >
                <Grid item xs={1} md={1}>
                  <label>Folio:</label>
                </Grid>
                <Grid xs={1} md={1}>
                  <input
                    disabled
                    className="filter-input"
                    style={{ width: "70%" }}
                    value={modalConsultaValues.folio}
                  />
                </Grid>
                <Grid xs={1} md={1} style={{ marginLeft: "-4%" }}>
                  <label>Ciclo:</label>
                </Grid>
                <Grid xs={1} md={1}>
                  <input
                    disabled
                    className="filter-input"
                    style={{ width: "100%" }}
                    value={modalConsultaValues.ciclo}
                  />
                </Grid>
                <Grid xs={1} md={1}>
                  <label>Campus:</label>
                </Grid>
                <Grid xs={1} md={2}>
                  <input
                    disabled
                    className="filter-input"
                    style={{ width: "100%" }}
                    value={modalConsultaValues.campus}
                  />
                </Grid>
                <Grid xs={1} md={1}>
                  <label>Escuela:</label>
                </Grid>
                <Grid xs={1} md={2}>
                  <input
                    disabled
                    className="filter-input"
                    style={{ width: "120%" }}
                    value={modalConsultaValues.escuela}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
        <Grid container item xs={12} sm={2} style={{ float: "right" }}>
          <Grid containet item style={{ margin: "-16% 7% 5%", zIndex: 99 }}>
            <NewButton
              noIcon
              customText="Autorización Rector"
              style={{ padding: "8px 12.5px", width: "100%" }}
              disabled={disabled}
              onClick={handleAutorizacionRec}
            />
          </Grid>
          <Grid
            container
            item
            style={{ display: "flex", gap: 16, marginLeft: "-8%" }}
          >
            <div style={{ width: "140%" }}>
              <SearchInput hideIcon search={search4} />
            </div>
          </Grid>
        </Grid>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "96%",
            marginTop: "4%",
            marginLeft: "2%",
          }}
        >
          <div style={{ flex: 1 }}>
            <DataGrid
              rows={dataConsultaDiv}
              columns={columnsConsulta2}
              autoHeight
              pageSize={pageSize}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              rowsPerPageOptions={[5, 10, 15]}
              loading={loadingConsulta}
              pagination
              {...data}
              getRowHeight={() => "auto"}
              sx={{
                fontSize: "16px",
              }}
              onRowClick={handleRowSelection2}
            />
          </div>
        </div>

        <div className={styles.indicadores} style={{ flex: 1 }}>
          <Grid container item xs={12} sm={3} style={{ marginLeft: "1%" }}>
            <label className={styles.indicadoresTitle}>
              Indicadores Académicos
            </label>
          </Grid>
          <div style={{ display: "flex" }}>
            <div className={styles.divSubtitle1}>
              <label className={styles.subtitles}>Horas</label>
            </div>
            <div className={styles.divSubtitle2y3}>
              <label className={styles.subtitles}>Sesiones</label>
            </div>
            <div className={styles.divSubtitle2y3}>
              <label className={styles.subtitles}>Minutos</label>
            </div>
          </div>
          <div style={{ display: "flex" }}>
            <div style={{ height: "50%", width: "41.3%" }}>
              <DataTable
                headers={headersIndicadores1}
                data={dataIndicadores1}
                loading={loadingConsulta}
                centrar={[1, 2, 3, 4, 5, 6]}
                bordes
              />
            </div>
            <div style={{ height: "50%", width: "22.5%", marginLeft: "1%" }}>
              <DataTable
                headers={headersIndicadores2}
                data={dataIndicadores2}
                loading={loadingConsulta}
                centrar={[1, 2, 3]}
                bordes
              />
            </div>
            <div style={{ height: "50%", width: "22.5%", marginLeft: "1%" }}>
              <DataTable
                headers={headersIndicadores3}
                data={dataIndicadores3}
                loading={loadingConsulta}
                centrar={[1, 2, 3]}
                bordes
              />
            </div>
            <div style={{ height: "50%", width: "10.7%", marginLeft: "1%" }}>
              <DataTable
                headers={headersIndicadores4}
                data={dataIndicadores4}
                loading={loadingConsulta}
                centrar={[1, 2, 3]}
                bordes
              />
            </div>
          </div>
        </div>
      </NewModal>

      <NewModal
        title={"Comentario"}
        open={modalComentario3IsOpen}
        handleClose={() => {
          setModalComentario3IsOpen(false);
        }}
        style
        height={100}
        maxWidth={480}
        marginTop={"12%"}
        noButtons
      >
        <Grid container style={{ marginTop: "3.5%", marginLeft: "-1%" }}>
          <Grid
            container
            item
            spacing={1}
            alignItems="center"
            justifyContent="center"
            style={{ marginTop: "0.5%" }}
          >
            <Grid
              item
              xs={12}
              md={8}
              style={{ textAlign: "center", marginLeft: "4%" }}
            >
              <label>
                Existen registros pendientes por Aprobar. El proceso de
                "Autorización Rector" no puede continuar
              </label>
            </Grid>
          </Grid>
        </Grid>
      </NewModal>
    </Contenedor>
  );
};

export default FDA;
