import Grid from "@material-ui/core/Grid";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import { useSelector } from "react-redux";

import EditButton from "../../Utils/Botones/EditButton";
import NewButton from "../../Utils/Botones/NewButton";
import DataTable from "../../Utils/DataTables";
import Contenedor from "../../Utils/Visuales/Contenedor";
import NewModal from "../../Utils/Visuales/NewModal";
import { ExportCSV } from "../../Utils/Excel";
import { getRequest, postRequest } from "../../Utils/Funciones/requester";
import SearchInput from "../../Utils/Visuales/SearchInput";
import styles from "./styles.module.css";
import { isButtonDisabledCoordinador } from "../../Utils/Funciones/isButtonDisabledCoordinador";

function AsignacionesAutorizacionFaltasCancelaciones() {
  const [dataFinal, setDataFinal] = useState([]);
  const [dataFinalOriginal, setDataFinalOriginal] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modalConsultarIsOpen, setModalConsultarIsOpen] = useState(false);
  const [modalAutorizarIsOpen, setModalAutorizarIsOpen] = useState(false);
  const [tipoClase, setTipoClase] = useState("");
  const [optionsDireccion, setOptionsDireccion] = useState("");
  const [direccionValue, setDireccionValue] = useState("");
  const [direccionModalValue, setDireccionModalValue] = useState({
    label: "",
    value: "",
  });
  const [valueCiclo, setValueCiclo] = useState("");
  const [valueComentarioAutorizacion, setValueComentarioAutorizacion] =
    useState("");
  const [valueGrupo, setValueGrupo] = useState("");
  const [profesorValue, setProfesorValue] = useState("");
  const [tipoValue, setTipoValue] = useState({
    label: "",
    value: "",
  });
  const [autorizacionValue, setAutorizacionValue] = useState({
    label: "",
    value: "",
  });
  const [fechaInicioValue, setFechaInicioValue] = useState("");
  const [fechaFinValue, setFechaFinValue] = useState("");
  const [ciclo, setCiclo] = useState("");
  const [valueMateria, setValueMateria] = useState("");
  const [dataModal, setDataModal] = useState("");
  const [idMateria, setIdMateria] = useState(null);
  const [loadingModal, setLoadingModal] = useState(false);
  const { permisos, campusId, userDirections, rol } = useSelector(
    (state) => state
  );
  const { enqueueSnackbar } = useSnackbar();

  const headers = [
    {
      name: "Clave Profesor",
      col: 0,
    },
    {
      name: "Nombre Profesor",
      col: 1,
    },
    {
      name: "Grupo",
      col: 2,
    },
    {
      name: "Clave Materia",
      col: 3,
    },
    {
      name: "Fecha Clase",
      col: 4,
    },
    {
      name: "Tipo Clase",
      col: 5,
    },
    {
      name: "Horario",
      col: 6,
    },
    {
      name: "Salón",
      col: 7,
    },
    {
      name: "Estado Autorización",
      col: 8,
    },
    {
      name: "Acciones",
      col: 9,
    },
  ];

  const heading = [
    "clave_profesor",
    "nombre_profesor",
    "grupo",
    "clave_materia",
    "nombre_materia",
    "fecha_clase",
    "tipo_clase",
    "horario",
    "salon",
    "estado",
  ];

  const consultarCatalogo = async (id) => {
    try {
      const consulta = `/catalogo?id=${id}`;
      const response = await getRequest(consulta);
      let data = [];
      if (response.s === "OK") {
        let id = response.d.elementos[0].id;
        let clave = response.d.elementos[1].clave;
        let nombre = response.d.elementos[2].nombre;
        id.forEach((item, i) => {
          data.push({ value: id[i], label: `${clave[i]} - ${nombre[i]}` });
        });
        return data;
      } else {
        enqueueSnackbar(response.m, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
      // setLoadingModal(false);
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error, {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    }
  };

  useEffect(() => {
    consultarCatalogo(7).then((data) => setOptionsDireccion(data));
  }, []);

  async function openModalNuevo() {
    setCiclo("");
    setDireccionModalValue({
      label: "",
      value: "",
    });
    setTipoClase("");
    // setModalNuevoIsOpen(true);

    try {
      const endpoint = `/catalogo?id=22`;
      const response = await getRequest(endpoint);
      const data = [];
      if (response.s === "OK") {
        const nTipos = response.d.elementos[0]["id"].length;
        for (let i = 0; i < nTipos; i++) {
          if (response.d.elementos[1]["clave"][i] !== "001") {
            data.push({
              label:
                response.d.elementos[1]["clave"][i] +
                " - " +
                response.d.elementos[2]["name"][i],
              value: response.d.elementos[0]["id"][i],
            });
          }
        }
      } else {
        enqueueSnackbar(response.m, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
      // setLoadingModal(false);
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error, {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    }
  }

  function handleSubmit() {
    if (ciclo === "") {
      enqueueSnackbar("Debe seleccionar un ciclo", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      return;
    } else if (direccionModalValue.value === "") {
      enqueueSnackbar("Debe seleccionar una dirección", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      return;
    } else if (tipoClase === "") {
      enqueueSnackbar("Debe seleccionar un tipo de clase", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      return;
    }

    // setModalNuevoIsOpen(false);
  }

  async function handleSubmitAutorizar() {
    const payload = {
      id: idMateria,
      autorizacion: autorizacionValue.value,
      comentario: valueComentarioAutorizacion,
    };
    let isOk = true;
    if (valueComentarioAutorizacion === "") {
      enqueueSnackbar("Debes de insertar un comentario", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      isOk = false;
    } else if (
      autorizacionValue.value === undefined ||
      autorizacionValue.value === ""
    ) {
      enqueueSnackbar("Debes de selecionar una autorizacion", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      isOk = false;
    }
    if (isOk) {
      try {
        setLoadingModal(true);
        const response = await postRequest(
          "/asignaciones/autorizacion_faltas_cancelaciones",
          payload
        );
        if (response.s === "OK") {
          enqueueSnackbar(response.m, {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "left",
            },
          });
          handleClickBuscar();
        } else {
          enqueueSnackbar(response.m, {
            variant: "error",
            autoHideDuration: null,
            anchorOrigin: {
              vertical: "top",
              horizontal: "left",
            },
          });
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoadingModal(false);
        setModalAutorizarIsOpen(false);
      }
    }
  }

  async function searchProfesor(valor) {
    try {
      const options = [];

      const consulta = `/buscar?tipo=profesor&valor=${valor}`;
      const response = await getRequest(consulta);

      if (response.s === "OK") {
        response.d.forEach((item) => {
          options.push({
            label: item.nss + " - " + item.nombre,
            value: item.id,
          });
        });

        return options;
      } else {
        enqueueSnackbar(response.m, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error, {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    }
  }

  const asyncSelectStyles = {
    container: (styles) => ({ ...styles, zIndex: 9 }),
  };

  const minChars = 5;
  const waitTime = 2000;

  let timer;
  const promiseOptions = (inputValue) =>
    new Promise((resolve) => {
      clearTimeout(timer);
      if (inputValue.length >= minChars) {
        timer = setTimeout(() => {
          resolve(searchProfesor(inputValue));
        }, waitTime);
      } else {
        resolve([]);
      }
    });

  const tipoOptions = [
    { label: "Faltas", value: "faltas" },
    { label: "Canceladas", value: "canceladas" },
    { label: "Ambas", value: "ambas" },
  ];

  const autorizacionOptions = [
    { label: "Si", value: "si" },
    { label: "No", value: "no" },
  ];
  function search(text) {
    text = text.toLowerCase();
    let result = dataFinal.filter((row) => {
      if (row[0].toString().toLowerCase().includes(text)) return true;
      else if (row[1].toString().toLowerCase().includes(text)) return true;
      else if (row[2].toString().toLowerCase().includes(text)) return true;
      else if (row[3].toString().toLowerCase().includes(text)) return true;
      else if (row[4].toString().toLowerCase().includes(text)) return true;
      else if (row[5].toString().toLowerCase().includes(text)) return true;
      return false;
    });
    if (text.length === 0) {
      setDataFinal(dataFinalOriginal);
    } else {
      setDataFinal(result);
    }
  }
  async function handleClickBuscar() {
    let nrofechaInicial = parseInt(
      fechaInicioValue.substring(0, 4) +
        fechaInicioValue.substring(5, 7) +
        fechaInicioValue.substring(8, 10)
    );
    let nrofechaFinal = parseInt(
      fechaFinValue.substring(0, 4) +
        fechaFinValue.substring(5, 7) +
        fechaFinValue.substring(8, 10)
    );
    if (valueCiclo === "") {
      enqueueSnackbar("Debe ingresar un ciclo", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      return;
    } else if (fechaInicioValue === "") {
      enqueueSnackbar("Debe seleccionar una fecha inicio", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      return;
    } else if (fechaFinValue === "") {
      enqueueSnackbar("Debe seleccionar una fecha fin", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      return;
    } else if (direccionValue === "") {
      enqueueSnackbar("Debe seleccionar una dirección", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      return;
    } else if (tipoValue.value === "") {
      enqueueSnackbar("Debe seleccionar un tipo", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      return;
    } else if (nrofechaFinal < nrofechaInicial) {
      enqueueSnackbar("Fecha Final es inferior a la Fecha Inicial", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      return;
    }
    setLoading(true);
    try {
      let consulta = `/asignaciones/autorizacion_faltas_cancelaciones?ciclo=${valueCiclo}&id_direccion=${direccionValue.value}&fecha_inicio=${fechaInicioValue}&fecha_fin=${fechaFinValue}&tipo=${tipoValue.value}&id_campus=${campusId}`;
      if (profesorValue) consulta += `&id_profesor=${profesorValue.value}`;
      if (valueGrupo) consulta += `&grupo=${valueGrupo}`;
      //if (valueMateria !== "") consulta += `&id_materia=${valueMateria.value}`;
      if (valueMateria) consulta += `&materia=${valueMateria}`;
      const response = await getRequest(consulta);

      let rowsP = "";
      if (response.s === "OK") {
        enqueueSnackbar(response.m, {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });

        //data
        const array1 = [];
        const array2 = [];
        const arrayRows = [];

        response.d.forEach((e, i) => {
          const objp = Object.values(response.d[i]);
          array1[i] = objp;
        });

        const numRows_ = array1[0][0].length;
        for (let step = 0; step < numRows_; step++) {
          response.d.forEach((e, i) => {
            Object.values(response.d[i]).forEach((value) => {
              let numj = i - i + step;
              array2[i] = value[numj];
            });
          });
          array2[9] = (
            <div style={{ display: "flex", justifyContent: "space-evenly" }}>
              <EditButton
                onClick={() => abrirModalAutorizar(step, 0, response.d)}
                disabled={
                  response.d[8]?.estado_autorizacion[step] !== ""
                    ? true
                    : isButtonDisabledCoordinador(
                        permisos[
                          "Asignaciones->Autorizacion de Faltas y Cancelaciones->Autorizar"
                        ].leer,
                        rol,
                        userDirections
                      )
                }
                style={{
                  display:
                    permisos[
                      "Asignaciones->Autorización de Faltas y Cancelaciones"
                    ].modificar === "1"
                      ? "block"
                      : "none",
                }}
              >
                Autorizar
              </EditButton>
              <EditButton
                onClick={() => abrirModalAutorizar(step, 1, response.d)}
              >
                Consultar
              </EditButton>
            </div>
          );
          array2[10] = response.d?.[10]?.ids_assig_materia;
          //console.log(array2);
          arrayRows[step] = [...array2];
        }
        rowsP = arrayRows;
        //console.log(rowsP);
        setDataFinal(rowsP);
        setDataFinalOriginal(rowsP);
        setLoading(false);
      } else {
        enqueueSnackbar(response.m, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
        setDataFinalOriginal(rowsP);
        setDataFinal([]);
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error, {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    } finally {
      setLoading(false);
    }
  }

  const abrirModalAutorizar = async (index, modal, data) => {
    setLoadingModal(true);
    modal === 1 ? setModalConsultarIsOpen(true) : setModalAutorizarIsOpen(true);
    try {
      const consulta = `/asignaciones/autorizacion_faltas_cancelaciones?id=${data[10]?.ids_assig_materia?.[index]}`;
      setIdMateria(`${data[10]?.ids_assig_materia?.[index]}`);
      const response = await getRequest(consulta);
      if (response.s === "OK") {
        setDataModal(response.d);
        enqueueSnackbar(response.m, {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      } else {
        enqueueSnackbar(response.m, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingModal(false);
    }
  };

  // useEffect(() => {
  //   abrirModalAutorizar(577);
  // }, []);

  const DownloadExcel = () => {
    const data_ = dataFinal.map((row) => ({
      clave_profesor: row[0],
      nombre_profesor: row[1],
      grupo: row[2],
      clave_materia: row[3],
      nombre_materia: row[4],
      fecha_clase: row[5],
      tipo_clase: row[6],
      horario: row[7],
      salon: row[8],
      estado: row[9],
    }));
    var today = new Date();
    var fecha = `${today.getFullYear()}${
      today.getMonth() + 1
    }${today.getDate()}`;
    var hora = `${today.getHours()}${today.getMinutes()}`;
    ExportCSV(data_, `Clases_especiales_${fecha}_${hora}`, heading);
  };

  return (
    <Contenedor title="Asignaciones / Autorización de Faltas y Cancelaciones">
      <div className={styles.inputsClasesEspeciales}>
        <Grid container style={{ alignItems: "center" }}>
          {/* <Grid item xs={2}>
            <NewButton onClick={openModalNuevo} noIcon />
          </Grid>
          <Grid item xs={10} /> */}
          <Grid item xs={2} style={{ marginLeft: "-5%" }}>
            <label>Ciclo(*):</label>
          </Grid>
          <Grid item xs={2} style={{ padding: 2, zIndex: 80, marginTop: 5 }}>
            <input
              className="filter-input"
              value={valueCiclo}
              onChange={(e) => setValueCiclo(e.target.value)}
            />
          </Grid>
          <Grid item xs={2}>
            <label>Fecha Inicio(*):</label>
          </Grid>
          <Grid item xs={2} style={{ zIndex: 78 }}>
            <input
              // ref={startDateInputRef}
              // onClick={handleIStartDateDateClick}
              className="filter-input"
              label="Seleccionar fecha"
              type="date"
              value={fechaInicioValue}
              onChange={(ev) => setFechaInicioValue(ev.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
              style={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={2} style={{ marginLeft: "-5%" }}>
            <label>Fecha Fin(*):</label>
          </Grid>
          <Grid item xs={2}>
            <input
              // ref={endDateInputRef}
              className="filter-input"
              label="Seleccionar fecha"
              type="date"
              value={fechaFinValue}
              // onClick={handleIEndDateDateClick}
              onChange={(ev) => setFechaFinValue(ev.target.value)}
              disabled={fechaInicioValue !== "" ? false : true}
              min={fechaInicioValue}
              style={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={2} style={{ marginLeft: "-5%" }}>
            <label>Dirección(*):</label>
          </Grid>
          <Grid item xs={2} style={{ padding: 2, zIndex: 80, marginTop: 5 }}>
            <Select
              className="select-modal-container input-tipo"
              classNamePrefix="select-modal"
              value={direccionValue}
              options={optionsDireccion}
              onChange={setDireccionValue}
              isClearable={true}
            />
          </Grid>

          <Grid item xs={2} style={{ padding: 2, zIndex: 80, marginTop: 5 }}>
            <label>Clave Profesor:</label>
          </Grid>
          <Grid xs={2} item style={{ padding: 2, zIndex: 90, marginTop: 5 }}>
            <AsyncSelect
              className="select-modal-container"
              classNamePrefix="select-modal"
              styles={asyncSelectStyles}
              loadOptions={promiseOptions}
              onChange={setProfesorValue}
              isClearable={true}
            />
          </Grid>
          <Grid item xs={2} style={{ marginLeft: "-5%" }}>
            <label>Grupo:</label>
          </Grid>
          <Grid item xs={2} style={{ padding: 2, zIndex: 80, marginTop: 5 }}>
            <input
              className="filter-input"
              value={valueGrupo}
              onChange={(e) => setValueGrupo(e.target.value)}
            />
          </Grid>
          <Grid item xs={2} style={{ marginLeft: "-5%" }}>
            <label>Materia:</label>
          </Grid>
          <Grid xs={2} item style={{ padding: 2, zIndex: 79, marginTop: 5 }}>
            <input
              className="filter-input"
              value={valueMateria}
              onChange={(e) => setValueMateria(e.target.value)}
            />
            {/* <AsyncSelect
              className="select-modal-container input-claveProfesor clave-profesor-index0"
              classNamePrefix="select-modal"
              styles={asyncSelectStyles}
              loadOptions={promiseMateriaOptions}
              value={valueMateria}
              onChange={setValueMateria}
              placeholder="Buscar Materia"
            /> */}
          </Grid>
          <Grid item xs={2} style={{ padding: 2, zIndex: 80, marginTop: 5 }}>
            <label>Tipo(*):</label>
          </Grid>
          <Grid item xs={2} sm={2} style={{ padding: 2, zIndex: 80 }}>
            <Select
              className="select-modal-container"
              classNamePrefix="select-modal"
              options={tipoOptions}
              onChange={setTipoValue}
              styles={styles.diaSelect}
            />
          </Grid>

          <Grid item xs={3} />

          <Grid item xs={1} style={{ marginLeft: "5%" }}>
            <NewButton customText="Buscar" noIcon onClick={handleClickBuscar} />
          </Grid>
          <Grid item xs={12} />
        </Grid>
      </div>
      <Grid md={3} container style={{ marginLeft: "auto", marginBottom: 4 }}>
        <SearchInput hideIcon search={search} />
      </Grid>
      <div style={{ height: "calc(100% - 230px)", marginTop: "20px" }}>
        <DataTable
          headers={headers}
          data={dataFinal}
          loading={loading}
          centrar={[1, 2, 3, 4]}
          paginate
          bordes
        />
      </div>

      <NewModal
        title="Consulta Autorización de Faltas y Cancelaciones"
        open={modalConsultarIsOpen}
        handleClose={() => setModalConsultarIsOpen(false)}
        height={550}
        maxWidth={1000}
        maxHeight={"unset"}
        overflowY={"unset"}
        loading={loadingModal}
        noButtons
      >
        <div style={{ padding: "15px" }}>
          <Grid container spacing={2} style={{ alignItems: "center" }}>
            <Grid item xs={2}>
              <label>Ciclo:</label>
            </Grid>
            <Grid item xs={3}>
              <input
                disabled
                className={styles.filterInput}
                value={dataModal.ciclo}
                onChange={(ev) => setCiclo(ev.target.value)}
              />
            </Grid>
            <Grid item xs={4}>
              <label>Número Total de Autorizaciones:</label>
            </Grid>
            <Grid item xs={3}>
              <input
                disabled
                className={styles.filterInput}
                value={dataModal.numero_total_autorizaciones}
                onChange={(ev) => setCiclo(ev.target.value)}
              />
            </Grid>
            <Grid item xs={2}>
              <label>Escuela:</label>
            </Grid>
            <Grid item xs={3}>
              <input
                disabled
                className={styles.filterInput}
                value={`${dataModal.clave_escuela} - ${dataModal.nombre_escuela}`}
                onChange={(ev) => setCiclo(ev.target.value)}
              />
            </Grid>
            <Grid item xs={4}>
              <label>Dirección:</label>
            </Grid>
            <Grid item xs={3}>
              <input
                disabled
                className={styles.filterInput}
                value={`${dataModal.clave_direccion} - ${dataModal.nombre_direccion}`}
                onChange={(ev) => setCiclo(ev.target.value)}
              />
            </Grid>
            <Grid item xs={2}>
              <label>Grupo:</label>
            </Grid>
            <Grid item xs={3}>
              <input
                disabled
                className={styles.filterInput}
                value={`${dataModal.clave_grupo}`}
                onChange={(ev) => setCiclo(ev.target.value)}
              />
            </Grid>
            <Grid item xs={4}>
              <label>Materia:</label>
            </Grid>
            <Grid item xs={3}>
              <input
                disabled
                className={styles.filterInput}
                value={`${dataModal.clave_materia} - ${dataModal.nombre_materia}`}
                onChange={(ev) => setCiclo(ev.target.value)}
              />
            </Grid>

            <Grid item xs={2}>
              <label>Clave Profesor:</label>
            </Grid>
            <Grid item xs={3}>
              <input
                disabled
                className={styles.filterInput}
                value={`${dataModal.clave_profesor} - ${dataModal.nombre_profesor}`}
                onChange={(ev) => setCiclo(ev.target.value)}
              />
            </Grid>
            <Grid item xs={4}>
              <label>Pago:</label>
            </Grid>
            <Grid item xs={3}>
              <input
                disabled
                className={styles.filterInput}
                value={dataModal.pago}
                onChange={(ev) => setCiclo(ev.target.value)}
              />
            </Grid>
            <Grid item xs={2}>
              <label>Tipo Asistencia:</label>
            </Grid>
            <Grid item xs={3}>
              <input
                disabled
                className={styles.filterInput}
                value={dataModal.tipo_asistencia}
                onChange={(ev) => setCiclo(ev.target.value)}
              />
            </Grid>
            <Grid item xs={4}>
              <label>Fecha y Hora:</label>
            </Grid>
            <Grid item xs={3}>
              <input
                disabled
                className={styles.filterInput}
                value={`${dataModal.fecha} - ${dataModal.hora}`}
                onChange={(ev) => setCiclo(ev.target.value)}
              />
            </Grid>
            <Grid item xs={2}>
              <label>Salón:</label>
            </Grid>
            <Grid item xs={3}>
              <input
                disabled
                className={styles.filterInput}
                value={dataModal.salon}
                onChange={(ev) => setCiclo(ev.target.value)}
              />
            </Grid>
            <Grid item xs={4}>
              <label>Hora de Llegada:</label>
            </Grid>
            <Grid item xs={3}>
              <input
                disabled
                className={styles.filterInput}
                value={dataModal.hora_llegada}
                onChange={(ev) => setCiclo(ev.target.value)}
              />
            </Grid>
            <Grid item xs={2}>
              <label>Comentario:</label>
            </Grid>
            <Grid xs={10} item>
              <textarea
                disabled
                style={{ backgroundColor: "#dcdbdb" }}
                className="text-area-modal"
                rows={3}
                value={dataModal.comentario}
              />
            </Grid>
            <Grid item xs={2}>
              <label>Autorización:</label>
            </Grid>
            <Grid item xs={3}>
              <input
                disabled
                className={styles.filterInput}
                value={`${
                  dataModal.autorizacion === null ? "" : dataModal.autorizacion
                }`}
                onChange={(ev) => setCiclo(ev.target.value)}
              />
            </Grid>
            <Grid item xs={7} />
            <Grid item xs={2}>
              <label>Creado:</label>
            </Grid>
            <Grid item xs={3}>
              <input
                disabled
                className={styles.filterInput}
                value={`${dataModal.solicitado}`}
                onChange={(ev) => setCiclo(ev.target.value)}
              />
            </Grid>
            <Grid item xs={4}>
              <input
                disabled
                className={styles.filterInput}
                value={`${dataModal.solicitado_por}`}
                onChange={(ev) => setCiclo(ev.target.value)}
              />
            </Grid>
            <Grid item xs={3} />
            <Grid item xs={2}>
              <label>Autorizado:</label>
            </Grid>
            <Grid item xs={3}>
              <input
                disabled
                className={styles.filterInput}
                value={`${
                  dataModal.autorizado === null ? "" : dataModal.autorizado
                }`}
                onChange={(ev) => setCiclo(ev.target.value)}
              />
            </Grid>
            <Grid item xs={4}>
              <input
                disabled
                className={styles.filterInput}
                value={`${
                  dataModal.autorizado_por === null
                    ? ""
                    : dataModal.autorizado_por
                }`}
                onChange={(ev) => setCiclo(ev.target.value)}
              />
            </Grid>
          </Grid>
        </div>
      </NewModal>

      <NewModal
        title="Autorización de Faltas y Cancelaciones"
        open={modalAutorizarIsOpen}
        handleClose={() => {
          setModalAutorizarIsOpen(false);
          setDataModal("");
          setValueComentarioAutorizacion("");
        }}
        height={570}
        maxWidth={1000}
        maxHeight={"unset"}
        overflowY={"unset"}
        loading={loadingModal}
        handleSubmit={handleSubmitAutorizar}
      >
        <div style={{ padding: "15px" }}>
          <Grid container spacing={2} style={{ alignItems: "center" }}>
            <Grid item xs={2}>
              <label>Ciclo:</label>
            </Grid>
            <Grid item xs={3}>
              <input
                disabled
                className={styles.filterInput}
                value={dataModal.ciclo}
                onChange={(ev) => setCiclo(ev.target.value)}
              />
            </Grid>
            <Grid item xs={4}>
              <label>Número Total de Autorizaciones:</label>
            </Grid>
            <Grid item xs={3}>
              <input
                disabled
                className={styles.filterInput}
                value={dataModal.numero_total_autorizaciones}
                onChange={(ev) => setCiclo(ev.target.value)}
              />
            </Grid>
            <Grid item xs={2}>
              <label>Escuela:</label>
            </Grid>
            <Grid item xs={3}>
              <input
                disabled
                className={styles.filterInput}
                value={`${dataModal.clave_escuela} - ${dataModal.nombre_escuela}`}
                onChange={(ev) => setCiclo(ev.target.value)}
              />
            </Grid>
            <Grid item xs={4}>
              <label>Dirección:</label>
            </Grid>
            <Grid item xs={3}>
              <input
                disabled
                className={styles.filterInput}
                value={`${dataModal.clave_direccion} - ${dataModal.nombre_direccion}`}
                onChange={(ev) => setCiclo(ev.target.value)}
              />
            </Grid>
            <Grid item xs={2}>
              <label>Grupo:</label>
            </Grid>
            <Grid item xs={3}>
              <input
                disabled
                className={styles.filterInput}
                value={`${dataModal.clave_grupo}`}
                onChange={(ev) => setCiclo(ev.target.value)}
              />
            </Grid>
            <Grid item xs={4}>
              <label>Materia:</label>
            </Grid>
            <Grid item xs={3}>
              <input
                disabled
                className={styles.filterInput}
                value={`${dataModal.clave_materia} - ${dataModal.nombre_materia}`}
                onChange={(ev) => setCiclo(ev.target.value)}
              />
            </Grid>

            <Grid item xs={2}>
              <label>Clave Profesor:</label>
            </Grid>
            <Grid item xs={3}>
              <input
                disabled
                className={styles.filterInput}
                value={`${dataModal.clave_profesor} - ${dataModal.nombre_profesor}`}
                onChange={(ev) => setCiclo(ev.target.value)}
              />
            </Grid>
            <Grid item xs={4}>
              <label>Pago:</label>
            </Grid>
            <Grid item xs={3}>
              <input
                disabled
                className={styles.filterInput}
                value={dataModal.pago}
                onChange={(ev) => setCiclo(ev.target.value)}
              />
            </Grid>
            <Grid item xs={2}>
              <label>Tipo Asistencia:</label>
            </Grid>
            <Grid item xs={3}>
              <input
                disabled
                className={styles.filterInput}
                value={dataModal.tipo_asistencia}
                onChange={(ev) => setCiclo(ev.target.value)}
              />
            </Grid>
            <Grid item xs={4}>
              <label>Fecha y Hora:</label>
            </Grid>
            <Grid item xs={3}>
              <input
                disabled
                className={styles.filterInput}
                value={`${dataModal.fecha} - ${dataModal.hora}`}
                onChange={(ev) => setCiclo(ev.target.value)}
              />
            </Grid>
            <Grid item xs={2}>
              <label>Salón:</label>
            </Grid>
            <Grid item xs={3}>
              <input
                disabled
                className={styles.filterInput}
                value={dataModal.salon}
                onChange={(ev) => setCiclo(ev.target.value)}
              />
            </Grid>
            <Grid item xs={4}>
              <label>Hora de Llegada:</label>
            </Grid>
            <Grid item xs={3}>
              <input
                disabled
                className={styles.filterInput}
                value={dataModal.hora_llegada}
                onChange={(ev) => setCiclo(ev.target.value)}
              />
            </Grid>
            <Grid item xs={2}>
              <label>Comentario:</label>
            </Grid>
            <Grid xs={10} item>
              <textarea
                disabled
                style={{ backgroundColor: "#dcdbdb" }}
                className="text-area-modal"
                rows={3}
                value={dataModal.comentario}
              />
            </Grid>
            <Grid item xs={2}>
              <label>Solicitado por:</label>
            </Grid>
            <Grid item xs={3}>
              <input
                disabled
                className={styles.filterInput}
                value={`${
                  dataModal.solicitado === null ? "" : dataModal.solicitado
                }`}
                onChange={(ev) => setCiclo(ev.target.value)}
              />
            </Grid>
            <Grid item xs={4}>
              <input
                disabled
                className={styles.filterInput}
                value={`${
                  dataModal.solicitado_por === null
                    ? ""
                    : dataModal.solicitado_por
                }`}
                onChange={(ev) => setCiclo(ev.target.value)}
              />
            </Grid>
            <Grid item xs={3} />
            <Grid item xs={2}>
              <label>Autorización(*):</label>
            </Grid>
            <Grid item xs={3}>
              <Select
                className="select-modal-container"
                classNamePrefix="select-modal"
                options={autorizacionOptions}
                onChange={setAutorizacionValue}
                styles={styles.diaSelect}
              />
            </Grid>
            <Grid item xs={7} />
            <Grid item xs={2}>
              <label>Comentario(*):</label>
            </Grid>
            <Grid xs={10} item>
              <textarea
                value={valueComentarioAutorizacion}
                onChange={(ev) =>
                  setValueComentarioAutorizacion(ev.target.value)
                }
                className="text-area-modal"
                rows={2}
                placeholder="Escribe aquí"
              ></textarea>
            </Grid>
          </Grid>
        </div>
      </NewModal>
    </Contenedor>
  );
}

export default AsignacionesAutorizacionFaltasCancelaciones;
