const reducer = (state, { type, payload }) => {
  switch (type) {
    case "SET_ISLOGED":
      return {
        ...state,
        isLoged: payload,
      };

    case "SET_COMENTARIOSPAGOS":
      return {
        ...state,
        comentariosPagos: payload,
      };

    case "SET_CAMPUSUSER":
      return {
        ...state,
        campusUser: payload,
      };

    case "SET_CAMPUSUSER2":
      return {
        ...state,
        campusUser2: payload,
      };

    case "SET_CAMPUSID":
      return {
        ...state,
        campusId: payload,
      };

    case "SET_CAMPUSNAME":
      return {
        ...state,
        campusName: payload,
      };

    case "SET_PERSONID":
      return {
        ...state,
        personID: payload,
      };

    case "SET_PERMISOS":
      return {
        ...state,
        permisos: payload,
      };

    case "USER_NAME":
      return {
        ...state,
        userName: payload,
      };
    case "USER_ROL":
      return {
        ...state,
        rol: payload,
      };
    case "USER_DIRECCION":
      return {
        ...state,
        userDirections: payload,
      };

    default:
      return state;
  }
};

export default reducer;
