import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { pdf } from "@react-pdf/renderer";
import saveAs from "file-saver";
import Contenedor from "../../Utils/Visuales/Contenedor";
import { DocumentPDFTable } from "../../Utils/pdf_table/document";
import { ExportCSV } from "../../Utils/Excel";
import { CustomSpinner } from "../../Utils/Loader";
import NewButton from "../../Utils/Botones/NewButton";
import EditButton from "../../Utils/Botones/EditButton";
import DataTable from "../../Utils/DataTables";
import excelIcon from "../../images/excel-icon.png";
import pdfFileIcon from "../../images/pdf-file-icon.png";
import TextField from "@mui/material/TextField";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import SearchInput from "../../Utils/Visuales/SearchInput";
import Select from "react-select";
import NewModal from "../../Utils/Visuales/NewModal";
import { getRequest, postRequest } from "../../Utils/Funciones/requester";
import { useSnackbar } from "notistack";
import AsyncSelect from "react-select/async";
import styles from "./styles.module.css";
import { useSelector } from "react-redux";
import moment from "moment";
import CreateDictamentModal from "../../Utils/CreateDictamentModal";
import {
  headers,
  headersPDF,
} from "../../Utils/data/mantenimientoAsistenciaHeaders";

function MantenimientoAsistencias() {
  const campusName = useSelector((state) => state.campusName);
  const { enqueueSnackbar } = useSnackbar();
  const [data, setData] = useState([]);
  const [dataOriginal, setDataOriginal] = useState([]);
  const [req, setReq] = useState(false);
  const [modalCreate, setModalCreate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingModal, setLoadingModal] = useState(false);
  const [loadingModalComentario, setLoadingModalComentario] = useState(false);
  const [loadingModalComentarioBitacora, setLoadingModalComentarioBitacora] =
    useState(false);
  const [modalIncidenciaIsOpen, setModalIncidenciaIsOpen] = useState(false);
  const [modalAsistenciaIsOpen, setModalAsistenciaIsOpen] = useState(false);
  const [modalComentarioRegistroIsOpen, setModalComentarioRegistroIsOpen] =
    useState(false);
  const [idActual, setIdActual] = useState("");
  const [fechaInicialValue, setFechaInicialValue] = useState(
    moment().format("YYYY-MM-DD")
  );
  const [modalFechaInicialValue, setModalFechaInicialValue] = useState(
    moment().format("YYYY-MM-DD")
  );
  const [valueComentarioBitacora, setValueComentarioBitacora] = useState("");
  const [
    modalCancelarIncidenciaBitacoraIsOpen,
    setModalCancelarIncidenciaBitacoraIsOpen,
  ] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [jobId, setJobId] = useState("");
  const [jobLocationId, setJobLocationId] = useState("");
  const [modalSustitucionIsOpen, setModalSustitucionIsOpen] = useState(false);
  const [modalDictamen, setModalDictamen] = useState(false);
  const [valueIncidencia, setValueIncidencia] = useState([]);
  const [profesorValue, setProfesorValue] = useState("");
  const [valueGrupo, setValueGrupo] = useState("");
  const [valueIdSalon, setValueIdSalon] = useState("");
  const [valueIdAsignacion, setValueIdAsignacion] = useState("");
  const [valueClaveMateria, setValueClaveMateria] = useState("");
  const [valueMateria, setValueMateria] = useState("");
  const [valueClaveProfesor, setValueClaveProfesor] = useState("");
  const [valueIdProfesor, setValueIdProfesor] = useState("");
  const [valueProfesor, setValueProfesor] = useState("");
  const [valueTipoProfesor, setValueTipoProfesor] = useState("");
  const [valueAsistencia, setValueAsistencia] = useState("");
  const [valueHorario, setValueHorario] = useState("");
  const [valueRegistro, setValueRegistro] = useState("");
  const [valueEstatusRegistro, setValueEstatusRegistro] = useState("");
  const [valueEstatusClase, setValueEstatusClase] = useState("");
  const [valueHoraMinima, setValueHoraMinima] = useState("");
  const [valueTipoClase, setValueTipoClase] = useState("");
  const [valueSalon, setValueSalon] = useState("");
  const [valueDetalle, setValueDetalle] = useState("");
  const [valueTema, setValueTema] = useState("");
  const [valueTipoIncidencia, setValueTipoIncidencia] = useState("");
  const [tipoIncidenciaOptions, setTipoIncidenciaOptions] = useState([]);
  const [valueAutorizacion, setValueAutorizacion] = useState(false);
  const [modalComentarioIsOpen, setModalComentarioIsOpen] = useState(false);
  const [valueComentario, setValueComentario] = useState("");
  const [valueQuincena, setValueQuincena] = useState("");
  const [valueQuincenaActual, setValueQuincenaActual] = useState("");
  const IDcampus = useSelector((state) => state.campusId);
  const permisos = useSelector((state) => state.permisos);
  const [valueProfesorSustitucion, setValueProfesorSustitucion] = useState("");
  const [horaValue, setHoraValue] = useState("");
  const [minutosValue, setMinutosValue] = useState("");
  const [horaAsistenciaValue, setHoraAsistenciaValue] = useState("");
  const [minutosAsistenciaValue, setMinutosAsistenciaValue] = useState("");
  const [registro, setRegistro] = useState("null");
  const [registroValuePago, setRegistroValuePago] = useState("");
  const [valueMotivo, setValueMotivo] = useState("");
  const [isFechaProcesoQuincena, setIsFechaProcesoQuincena] = useState(false);
  const [deshabilitar, setDeshabilitar] = useState(false);
  const [subDeshabilitar, setSubDeshabilitar] = useState(false);
  const [idActualCancelacion, setIdActualCancelacion] = useState("");
  const [modalConfirmacionOpen, setModalConfirmacionOpen] = useState(false);
  const [createTeacher, setCreateTeacher] = useState([]);
  const [createGrupoValue, setCreateGrupoValue] = useState("");
  const [createMateria, setCreateMateria] = useState("");
  const [createCiclo, setCreateCiclo] = useState("");
  const [createDate, setCreateDate] = useState("");
  const [createComment, setCreateComment] = useState("");
  const [createHours, setCreateHours] = useState("");
  const [createMinutes, setCreateMinutes] = useState("");
  const [responseData, setResponseData] = useState({});
  const [tipoClase, setTipoClase] = useState({
    label: "1 - Normal",
    value: "1",
  });
  const GetTipoIncidencia = async (e) => {
    try {
      const consulta = `/catalogo?id=30`;
      const response = await getRequest(consulta);
      let rowsP = "";
      if (response.s === "OK") {
        //data
        const array1 = [];
        const array2 = [];
        const arrayRows = [];
        response.d.elementos.forEach((e, i) => {
          const objp = Object.values(response.d.elementos[i]);
          array1[i] = objp;
        });

        const numRows_ = array1[0][0].length;

        for (let step = 0; step < numRows_; step++) {
          response.d.elementos.forEach((e, i) => {
            Object.values(response.d.elementos[i]).forEach((value) => {
              let numj = i - i + step;
              array2[i] = value[numj];
            });
          });
          arrayRows[step] = [...array2];
        }
        rowsP = arrayRows;
        const dataSelect = rowsP.map((e) => {
          const data_ = {
            label: `${e[1]} - ${e[2]}`,
            value: e[0],
          };
          return data_;
        });
        setTipoIncidenciaOptions(dataSelect);
      } else {
        enqueueSnackbar(response.m, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  function handleSustitucionModalOpen() {
    setModalSustitucionIsOpen(true);
  }

  async function handleComentarioSustitucion() {
    try {
      const date_sustitucion = `${valueAsistencia.split("/")[2]}-${
        valueAsistencia.split("/")[1]
      }-${valueAsistencia.split("/")[0]}`;
      const payload = {
        id_asignacion: valueIdAsignacion,
        id_profesor: valueIdProfesor,
        id_profesor_nuevo: valueProfesorSustitucion.value,
        id_dictamen: "",
        fecha: date_sustitucion,
        fechaNuevo: date_sustitucion,
        hora: "",
        hora_inicio: valueHorario.split("-")[0],
        id_salon: valueIdSalon,
        tema: "Sustitución aut",
        tipo_clase_especial: 5,
        permitir_fechas_pasadas: 1,
      };
      setLoadingModalComentarioBitacora(true);
      const response = await postRequest(
        "/asignaciones/clases-especiales/nueva",
        payload
      );
      setLoadingModalComentarioBitacora(false);

      if (response.s === "OK") {
        enqueueSnackbar(response.m, {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
        setModalSustitucionIsOpen(false);
      } else {
        enqueueSnackbar(response.m, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
        setValueProfesorSustitucion("");
        setModalSustitucionIsOpen(false);
        setModalComentarioRegistroIsOpen(false);
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error, {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    }
  }
  async function searchProfesor(valor) {
    try {
      const options = [];

      const consulta = `/buscar?tipo=profesor&valor=${valor}`;
      const response = await getRequest(consulta);

      if (response.s === "OK") {
        response.d.forEach((item) => {
          options.push({
            label: item.nss + " - " + item.nombre,
            value: item.id,
          });
        });

        return options;
      } else {
        enqueueSnackbar(response.m, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error, {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    }
  }
  const minChars = 5;
  const waitTime = 2000;

  let timer;
  const asyncSelectStyles = {
    container: (styles) => ({ ...styles, zIndex: 9 }),
  };
  const promiseOptions = (inputValue) =>
    new Promise((resolve) => {
      clearTimeout(timer);
      if (inputValue.length >= minChars) {
        timer = setTimeout(() => {
          resolve(searchProfesor(inputValue));
        }, waitTime);
      } else {
        resolve([]);
      }
    });

  function search(text) {
    text = text.toLowerCase();
    let result = dataOriginal.filter((row) => {
      return row.some((element) => {
        const elementString =
          typeof element === "number" ? element.toString() : element;
        return (
          typeof elementString === "string" &&
          elementString.toLowerCase().includes(text)
        );
      });
    });
    setData(result);
  }

  useEffect(() => {
    GetTipoIncidencia();
  }, []);

  async function openIncidencia(id) {
    setModalIncidenciaIsOpen(true);
    setLoadingModal(true);
    try {
      const consulta = `/docentes/mantenimiento-asistencias/detalle?id=${id}`;
      const response = await getRequest(consulta);
      if (response.s === "OK") {
        if (response.d !== "" && response.d !== null) {
          setIdActual(id);
          setValueGrupo(response.d.grupo);
          setValueClaveMateria(response.d.clave_materia);
          setValueMateria(response.d.nombre_materia);
          setValueClaveProfesor(response.d.clave_profesor);
          setValueIdProfesor(response.d.id_profesor);
          setValueProfesor(response.d.nombre_profesor);
          setValueTipoProfesor(response.d.tipo_profesor);
          setValueAsistencia(response.d.asistencia);
          setValueHorario(response.d.horario);
          setValueRegistro(response.d.registro);
          setValueEstatusRegistro(response.d.estatus_registro);
          setValueEstatusClase(response.d.estatus_clase);
          setValueHoraMinima(response.d.hora_minima);
          setValueTipoClase(response.d.tipo_clase);
          setValueSalon(response.d.salon);
          setValueDetalle(response.d.detalle);
          setValueTema(response.d.tema);
          setValueIncidencia(response.d.incidencias);
          var today = new Date();
          var day = today.getDate();
          var month = today.getMonth() + 1;
          var year = today.getFullYear();
          if (day < 10) {
            day = `0${day}`;
          }
          if (month < 10) {
            month = `0${month}`;
          }
          if (response.d.estatus_registro === "CANCELADO") {
            setDeshabilitar(true);
          }
          if (response.d.fecha_proceso_quincena === `${day}/${month}/${year}`) {
            setIsFechaProcesoQuincena(true);
            enqueueSnackbar(
              "No se pueden realizar modificaciones. Día proceso de nómina",
              {
                variant: "info",
                autoHideDuration: null,
                anchorOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
              }
            );
          }
          enqueueSnackbar(response.m, {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "left",
            },
          });
        } else {
          enqueueSnackbar("No se han encontrado datos", {
            variant: "info",
            autoHideDuration: null,
            anchorOrigin: {
              vertical: "top",
              horizontal: "left",
            },
          });
        }
      } else {
        enqueueSnackbar(response.m, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
    } catch (error) {
      console.log(error);
    }
    setLoadingModal(false);
  }

  async function openAsistencia(id) {
    setModalAsistenciaIsOpen(true);
    setLoadingModal(true);
    try {
      const consulta = `/docentes/mantenimiento-asistencias/detalle?id=${id}`;
      const response = await getRequest(consulta);
      if (response.s === "OK") {
        if (response.d !== "" && response.d !== null) {
          setIdActual(id);
          setValueGrupo(response.d.grupo);
          setValueClaveMateria(response.d.clave_materia);
          setValueMateria(response.d.nombre_materia);
          setValueClaveProfesor(response.d.clave_profesor);
          setValueIdProfesor(response.d.id_profesor);
          setValueProfesor(response.d.nombre_profesor);
          setValueTipoProfesor(response.d.tipo_profesor);
          setValueAsistencia(response.d.asistencia);
          setValueHorario(response.d.horario);
          setResponseData(response.d);
          setHoraAsistenciaValue(
            response.d.registro !== null
              ? response.d.registro.substring(0, 2)
              : ""
          );
          setMinutosAsistenciaValue(
            response.d.registro !== null
              ? response.d.registro.substring(3, 5)
              : ""
          );
          setValueEstatusRegistro(response.d.estatus_registro);
          setValueEstatusClase(response.d.estatus_clase);
          setValueTema(response.d.tema);
          setValueTipoClase(response.d.tipo_clase);
          setValueSalon(response.d.salon);
          setValueDetalle(response.d.detalle);
          setValueQuincena(response.d.quincena);
          setValueQuincenaActual(response.d.quincena_actual);
          setValueComentario(response.d.comentario);
          setValueIdSalon(response.d.id_salon);
          setValueIdAsignacion(response.d.id_asignacion);
          setRegistro(response.d.registro === null ? "null" : "");
          setRegistroValuePago(response.d.registro);
          var today = new Date();
          var day = today.getDate();
          var month = today.getMonth() + 1;
          var year = today.getFullYear();
          if (day < 10) {
            day = `0${day}`;
          }
          if (month < 10) {
            month = `0${month}`;
          }
          if (response.d.fecha_proceso_quincena === `${day}/${month}/${year}`) {
            setIsFechaProcesoQuincena(true);
            enqueueSnackbar(
              "No se pueden realizar modificaciones. Día proceso de nómina",
              {
                variant: "info",
                autoHideDuration: null,
                anchorOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
              }
            );
          }
          if (response.d.estatus_registro === "CANCELADO") {
            setDeshabilitar(true);
          }
          if (
            response.d.situacion_grupo === "B" ||
            response.d.complementario === "S"
          ) {
            setSubDeshabilitar(true);
          }
          enqueueSnackbar(response.m, {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "left",
            },
          });
        } else {
          enqueueSnackbar("No se han encontrado datos", {
            variant: "info",
            autoHideDuration: null,
            anchorOrigin: {
              vertical: "top",
              horizontal: "left",
            },
          });
        }
      } else {
        enqueueSnackbar(response.m, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
    } catch (error) {
      console.log(error);
    }
    setLoadingModal(false);
  }

  async function handleClickBuscar() {
    if (fechaInicialValue === "") {
      enqueueSnackbar("Falta llenar campo Fecha Inicio", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      return;
    } else {
      try {
        setLoading(true);
        let consulta = `/docentes/mantenimiento-asistencias?id_campus=${IDcampus}&fecha=${fechaInicialValue}`;
        if (horaValue && minutosValue)
          consulta += `&hora=${horaValue}:${minutosValue}`;
        //if (horaInicial) consulta += `&hora=${horaInicial}`;
        if (profesorValue) consulta += `&id_profesor=${profesorValue.value}`;
        const response = await getRequest(consulta);

        if (response.s === "OK") {
          const datos = [];

          if (response.d !== "") {
            const nro = response.d["clave_profesor"].length;
            for (let i = 0; i < nro; i++) {
              datos.push([
                response.d["direccion"][i],
                response.d["clave_profesor"][i],
                response.d["nombre_profesor"][i],
                response.d["grupo"][i],
                response.d["clave_materia"][i],
                response.d["nombre_materia"][i],
                response.d["salon"][i],
                response.d["tipo"][i],
                response.d["estatus"][i],
                response.d["hora_reg"][i],
                response.d["horario"][i],
                response.d["autorizado"][i],
                response.d["cancelado"][i],
                response.d["reg"][i],
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <EditButton
                    onClick={() => openIncidencia(response.d["verdict_id"][i])}
                    disabled={
                      permisos[
                        "Profesores->Mantenimiento de Asistencias_Asistencia"
                      ].escribir === "1"
                        ? false
                        : true
                    }
                  >
                    Incidencia
                  </EditButton>
                  <EditButton
                    onClick={() => openAsistencia(response.d["verdict_id"][i])}
                    style={{ marginLeft: "3%" }}
                    disabled={
                      permisos[
                        "Profesores->Mantenimiento de Asistencias_Incidencia"
                      ].escribir === "1"
                        ? false
                        : true
                    }
                  >
                    Asistencia
                  </EditButton>
                </div>,
                i,
                response.d["verdict_id"][i],
              ]);
            }
            setDataOriginal(datos);
            setData(datos);
            enqueueSnackbar(response.m, {
              variant: "success",
              anchorOrigin: {
                vertical: "top",
                horizontal: "left",
              },
            });
          } else {
            setData([]);
            setDataOriginal([]);
            enqueueSnackbar(response.m, {
              variant: "error",
              autoHideDuration: null,
              anchorOrigin: {
                vertical: "top",
                horizontal: "left",
              },
            });
          }
        } else {
          enqueueSnackbar(response.m, {
            variant: "error",
            autoHideDuration: null,
            anchorOrigin: {
              vertical: "top",
              horizontal: "left",
            },
          });
          setDataOriginal([]);
          setData([]);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }
  }

  const handleClickCrear = () => {
    setModalCreate(true);
  };

  const handleComentarioIncidenciaBitacora = async () => {
    setLoadingModalComentarioBitacora(true);
    if (valueComentarioBitacora === "") {
      enqueueSnackbar("Falta Añadir Motivo", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    } else {
      try {
        const payload = {
          id_incidencia: idActualCancelacion,
          comentario: valueComentarioBitacora,
        };
        const response = await postRequest(
          "/docentes/mantenimiento-asistencias/cancelar-incidencia",
          payload
        );
        if (response.s === "OK") {
          setValueMotivo("");
          limpiar();
          handleClickBuscar();
          setModalCancelarIncidenciaBitacoraIsOpen(false);
          setModalIncidenciaIsOpen(false);
          enqueueSnackbar(response.m, {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "left",
            },
          });
        } else {
          enqueueSnackbar(response.m, {
            variant: "error",
            autoHideDuration: null,
            anchorOrigin: {
              vertical: "top",
              horizontal: "left",
            },
          });
        }
      } catch (error) {
        console.log(error);
      }
    }
    setLoadingModalComentarioBitacora(false);
  };
  const handleComentarioRegistro = async () => {
    setLoadingModalComentario(true);
    if (valueMotivo === "") {
      enqueueSnackbar("Falta Añadir Motivo", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    } else {
      try {
        const payload = {
          id_veredicto: idActual,
          comentario: valueMotivo,
        };
        const response = await postRequest(
          "/docentes/mantenimiento-asistencias/cancelar-registro-asistencia",
          payload
        );
        if (response.s === "OK") {
          setValueMotivo("");
          limpiar();
          handleClickBuscar();
          setModalComentarioRegistroIsOpen(false);
          setModalAsistenciaIsOpen(false);
          enqueueSnackbar(response.m, {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "left",
            },
          });
        } else {
          enqueueSnackbar(response.m, {
            variant: "error",
            autoHideDuration: null,
            anchorOrigin: {
              vertical: "top",
              horizontal: "left",
            },
          });
        }
      } catch (error) {
        console.log(error);
      }
    }
    setLoadingModalComentario(false);
  };

  const handleOpenModalcancelarIncidenciaBitacora = (e) => {
    setModalCancelarIncidenciaBitacoraIsOpen(true);
    setIdActualCancelacion(e.id_incidencia);
  };
  const handleCancelarRegistroAsistencia = () => {
    setModalComentarioRegistroIsOpen(true);
  };
  const handleCancelarAsistencia = () => {
    setModalComentarioIsOpen(true);
  };
  async function handleSubmitAsistencia() {
    setLoadingModal(true);
    let valido = true;
    if (horaAsistenciaValue === "" || minutosAsistenciaValue === "") {
      enqueueSnackbar(
        "Falta seleccionar hora y minutos de Registro de Asistencia",
        {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        }
      );
      valido = false;
    }
    if (valueTema === "" || valueTema === null) {
      enqueueSnackbar("Falta llenar campo Tema", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      valido = false;
    }
    if (valueComentario === "") {
      enqueueSnackbar("Falta llenar campo Comentario", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      valido = false;
    }
    if (valido) {
      try {
        let horaAsistencia = "";
        let minutosAsistencia = "";
        horaAsistenciaValue.length < 2
          ? (horaAsistencia = `0${horaAsistenciaValue}`)
          : (horaAsistencia = horaAsistenciaValue);
        minutosAsistenciaValue.length < 2
          ? (minutosAsistencia = `0${minutosAsistenciaValue}`)
          : (minutosAsistencia = minutosAsistenciaValue);
        const payload = {
          id_veredicto: idActual,
          hora_registro: `${horaAsistencia}:${minutosAsistencia}`,
          tema: valueTema,
          comentario: valueComentario,
        };
        const response = await postRequest(
          "/docentes/mantenimiento-asistencias/update_asistencia",
          payload
        );
        if (response.s === "OK") {
          limpiar();
          handleClickBuscar();
          setModalAsistenciaIsOpen(false);
          enqueueSnackbar(response.m, {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "left",
            },
          });
        } else {
          enqueueSnackbar(response.m, {
            variant: "error",
            autoHideDuration: null,
            anchorOrigin: {
              vertical: "top",
              horizontal: "left",
            },
          });
        }
      } catch (error) {
        console.log(error);
      }
    }
    setLoadingModal(false);
  }

  const DownloadPDF = async () => {
    setReq(true);
    const data_ = data.map((e) => {
      const data2 = {
        director_academico: e[0],
        clave_profesor: e[1],
        profesor: e[2],
        grupo: e[3],
        clave_materia: e[4],
        materia: e[5],
        salon: e[6],
        tipo: e[7],
        estatus: e[8],
        hora_reg: e[9],
        horario: e[10],
        aut: e[11],
        can: e[12],
        reg: e[13],
      };
      return data2;
    });
    var today = new Date();
    var fecha = `${today.getFullYear()}${
      today.getMonth() + 1
    }${today.getDate()}`;
    var hora = `${today.getHours()}${today.getMinutes()}`;
    const doc = (
      <DocumentPDFTable
        metadata={headersPDF ?? []}
        data={data_ ?? []}
        title={`Profesores Mantenimiento de Asistencias`}
        campus={campusName}
        startDate={fechaInicialValue}
        teacherKey={
          profesorValue.value === undefined ? "" : profesorValue.label
        }
      />
    );
    const asPdf = pdf([]);
    asPdf.updateContainer(doc);
    const blob = await asPdf.toBlob();
    saveAs(blob, `Mantenimiento_de_Asistencias_${fecha}_${hora}.pdf`);
    setReq(false);
  };

  function handleHoraValue(value, type) {
    if (value >= 0 && value <= 23 && value.length < 3) {
      if (type === "i") {
        setHoraValue(value);
      } else {
        setHoraAsistenciaValue(value);
      }
    }
  }

  function handleMinutosValue(value, type) {
    if (value >= 0 && value <= 59 && value.length < 3) {
      if (type === "i") {
        setMinutosValue(value);
      } else {
        setMinutosAsistenciaValue(value);
      }
    }
  }

  const DownloadExcel = () => {
    setReq(true);
    const data_ = data.map((e) => {
      const data2 = {
        direccion: e[0],
        clv_prof: e[1],
        profesor: e[2],
        grupo: e[3],
        clave_materia: e[4],
        materia: e[5],
        salon: e[6],
        tipo: e[7],
        estatus: e[8],
        hora_reg: e[9],
        horario: e[10],
        aut: e[11],
        can: e[12],
        reg: e[13],
      };
      return data2;
    });
    var today = new Date();
    var fecha = `${today.getFullYear()}${
      today.getMonth() + 1
    }${today.getDate()}`;
    var hora = `${today.getHours()}${today.getMinutes()}`;
    ExportCSV(data_, `Mantenimiento_de_Asistencias_${fecha}_${hora}`);
    setReq(false);
  };

  const limpiar = () => {
    setIdActual("");
    setValueGrupo("");
    setValueClaveMateria("");
    setValueMateria("");
    setValueClaveProfesor("");
    setValueIdProfesor("");
    setValueProfesor("");
    setValueTipoProfesor("");
    setValueAsistencia("");
    setValueHorario("");
    setValueRegistro("");
    setHoraAsistenciaValue("");
    setMinutosAsistenciaValue("");
    setValueEstatusRegistro("");
    setValueEstatusClase("");
    setValueHoraMinima("");
    setValueTipoClase("");
    setValueSalon("");
    setValueDetalle("");
    setValueTema("");
    setValueQuincena("");
    setValueComentario("");
    setValueComentarioBitacora("");
    setIsFechaProcesoQuincena(false);
    setValueProfesorSustitucion("");
    setValueIdSalon("");
    setValueIdAsignacion("");
    setValueComentarioBitacora("");
  };

  const cancelarAsistencia = async () => {
    setLoadingModalComentario(true);
    if (valueMotivo === "") {
      enqueueSnackbar("Falta Añadir Motivo", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    } else {
      try {
        const payload = {
          id_veredicto: idActual,
          motivo: valueMotivo,
        };
        const response = await postRequest(
          "/docentes/mantenimiento-asistencias/update-cancelar-asistencia",
          payload
        );
        if (response.s === "OK") {
          setValueMotivo("");
          limpiar();
          handleClickBuscar();
          setModalComentarioIsOpen(false);
          setModalAsistenciaIsOpen(false);
          enqueueSnackbar(response.m, {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "left",
            },
          });
        } else {
          enqueueSnackbar(response.m, {
            variant: "error",
            autoHideDuration: null,
            anchorOrigin: {
              vertical: "top",
              horizontal: "left",
            },
          });
        }
      } catch (error) {
        console.log(error);
      }
    }
    setLoadingModalComentario(false);
  };
  const handleSubmitIncidencia = async () => {
    if (valueTipoIncidencia === "") {
      enqueueSnackbar("Seleccionar un tipo de incidencia", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    } else if (horaAsistenciaValue === "") {
      enqueueSnackbar("Seleccionar una hora de incidencia", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    } else if (minutosAsistenciaValue === "") {
      enqueueSnackbar("Seleccionar un minuto de incidencia", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    } else if (valueComentario === "") {
      enqueueSnackbar("Insertar un comentario", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    }
    try {
      setLoadingModal(true);
      const payload = {
        id_veredicto: idActual,
        id_tipo_incidencia: parseInt(valueTipoIncidencia.value),
        comentario: valueComentario,
        hora: `${horaAsistenciaValue}:${
          minutosAsistenciaValue.length === 1
            ? "0" + minutosAsistenciaValue
            : minutosAsistenciaValue
        }`,
        autorizacion: valueAutorizacion ? 1 : 0,
      };
      const response = await postRequest(
        "/docentes/mantenimiento-asistencias/incidencia",
        payload
      );
      setLoadingModal(false);
      if (response.s === "OK") {
        limpiar();
        setValueTipoIncidencia("");
        setValueAutorizacion(false);
        setModalIncidenciaIsOpen(false);
        enqueueSnackbar(response.m, {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
        handleClickBuscar();
      } else {
        enqueueSnackbar(response.m, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  async function searchProfesor2(valor) {
    try {
      const options = [];
      const consulta = `/buscar?tipo=profesor_activos&valor=${valor}`;
      const response = await getRequest(consulta);

      if (response.s === "OK") {
        response.d.forEach((item) => {
          options.push({
            label: item.nss + " - " + item.nombre,
            value: item.id,
            valido: item.valido,
            valido_incapacidad: item.valido_incapacidad,
          });
        });

        return options;
      } else {
        enqueueSnackbar(response.m, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error, {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    }
  }

  function promiseOptions2(inputValue) {
    return new Promise((resolve) => {
      clearTimeout(timer);
      if (inputValue.length >= minChars) {
        timer = setTimeout(() => {
          resolve(searchProfesor2(inputValue));
        }, waitTime);
      } else {
        resolve([]);
      }
    });
  }

  const handleProfesorSustitucion = (e) => {
    if (e.valido === "OK") {
      if (e.valido_incapacidad === "ERROR") {
        setValueProfesorSustitucion("");
        enqueueSnackbar("El profesor tiene una incapacidad activa", {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      } else {
        setValueProfesorSustitucion(e);
      }
    } else {
      setValueProfesorSustitucion("");
      enqueueSnackbar("Profesor no activo", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    }
  };

  const handleSubmitConfirmacion = async () => {
    setLoadingModal(true);
    const payload = {
      id_veredicto: idActual,
    };
    const response = await postRequest(
      "/docentes/mantenimiento-asistencias/pago",
      payload
    );
    setLoadingModal(false);
    if (response.s === "OK") {
      limpiar();
      setModalAsistenciaIsOpen(false);
      setModalConfirmacionOpen(false);
      enqueueSnackbar(response.m, {
        variant: "success",
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    } else {
      setModalConfirmacionOpen(false);
      enqueueSnackbar(response.m, {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    }
  };
  const handleSubmitCreateDitamen = async () => {
    if (createCiclo === "") {
      enqueueSnackbar("Insertar un Ciclo", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    } else if (createTeacher?.length === 0) {
      enqueueSnackbar("Selecionar una Profesor", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    } else if (createGrupoValue === "") {
      enqueueSnackbar("Insertar un Grupo", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    } else if (createMateria === "") {
      enqueueSnackbar("Selecionar una Materia", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    } else if (createDate === "") {
      enqueueSnackbar("Selecionar una Fecha", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    } else if (createComment === "") {
      enqueueSnackbar("Insertar un Comentario", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    } else if (createMinutes === "" && createHours === "") {
      enqueueSnackbar("Selecionar las horas y los minutos", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    } else {
      try {
        const payload = {
          id_asignacion: createMateria.id_asignacion,
          id_profesor: createTeacher.value,
          fecha: createDate,
          hora_inicio: `${createHours}:${createMinutes}`,
          comentario: createComment,
        };
        setLoadingModal(true);
        const url = `/docentes/mantenimiento-asistencias/insert_dictamen`;
        const response = await postRequest(url, payload);

        if (response.s === "OK") {
          enqueueSnackbar(response.m, {
            variant: "success",
            autoHideDuration: null,
            anchorOrigin: {
              vertical: "top",
              horizontal: "left",
            },
          });
          setCreateTeacher([]);
          setCreateGrupoValue("");
          setCreateMateria("");
          setCreateCiclo("");
          setCreateDate("");
          setCreateComment("");
          setCreateHours("");
          setCreateMinutes("");
          setTipoClase({
            label: "1 - Normal",
            value: "1",
          });
          setLoadingModal(false);
          setModalCreate(false);
        } else {
          setLoadingModal(false);
          enqueueSnackbar(response.m, {
            variant: "error",
            autoHideDuration: null,
            anchorOrigin: {
              vertical: "top",
              horizontal: "left",
            },
          });
        }
      } catch (error) {
        setLoadingModal(false);
        console.error(error);
        enqueueSnackbar(error, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
    }
  };

  const handleClickGenerarDictamenActual = () => {
    setModalDictamen(true);
  };

  const generarDictamen = async () => {
    try {
      setLoadingModal(true);
      const response = await postRequest(
        "/interface_ws",
        {
          type: "generar_dictamenes_actuales",
          fecha: modalFechaInicialValue,
        },
        new AbortController().signal,
        false,
        true
      );
      if (response.s === "OK") {
        enqueueSnackbar(response.m, {
          variant: "success",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
        setJobLocationId(response.d.location_id);
        setIsActive(true);
        setModalFechaInicialValue(moment().format("YYYY-MM-DD"));
      } else {
        enqueueSnackbar(response.m, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
        setLoadingModal(false);
      }
    } catch (error) {
      console.log(error);
      enqueueSnackbar("Error", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    }
  };

  const checkJob = async (setIsActive, setLoadingModal, setJobId, jobId) => {
    try {
      const response = await getRequest(
        `/interface_ws?location_id=${jobLocationId}&type=generar_dictamenes_actuales${
          jobId !== "" ? `&job_id=${jobId}` : ""
        }`,
        new AbortController().signal,
        false,
        false,
        true
      );
      if (response.s === "OK") {
        if (jobId === "") {
          console.log(response?.d?.id_job);
          const id =
            response?.d?.id_job === undefined ? "" : response?.d?.id_job;
          setJobId(id);
        }
        if (response.d.result === "SUCCESS") {
          enqueueSnackbar(response.m, {
            variant: "success",
            autoHideDuration: null,
            anchorOrigin: {
              vertical: "top",
              horizontal: "left",
            },
          });
          setModalDictamen(false);
          setIsActive(false);
          setLoadingModal(false);
          setJobId("");
          setJobLocationId("");
        } else if (response.d.result === "FAILURE") {
          enqueueSnackbar("Error al generar el dictamen", {
            variant: "error",
            autoHideDuration: null,
            anchorOrigin: {
              vertical: "top",
              horizontal: "left",
            },
          });
          setModalDictamen(false);
          setIsActive(false);
          setLoadingModal(false);
          setJobId("");
          setJobLocationId("");
        }
      } else {
        enqueueSnackbar(response.m, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
        setIsActive(false);
        setLoadingModal(false);
      }
    } catch (error) {
      setIsActive(false);
      console.log(error);
      enqueueSnackbar("Error", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    }
  };

  const handleCloseModalDictamen = () => {
    if (!isActive) {
      setModalDictamen(false);
      setIsActive(false);
      setLoadingModal(false);
      setJobId("");
      setJobLocationId("");
      setModalFechaInicialValue(moment().format("YYYY-MM-DD"));
    }
  };

  useEffect(() => {
    let interval;

    if (isActive) {
      interval = setInterval(() => {
        checkJob(setIsActive, setLoadingModal, setJobId, jobId);
      }, 5000);
    } else {
      clearInterval(interval);
    }

    return () => {
      clearInterval(interval);
    };
  }, [isActive, jobId]);

  const handleIsDesablePago = () => {
    const { registro, estatus_clase, ult_inc_status_aut } = responseData;

    // Special conditions that return false
    if (
      registro === null &&
      estatus_clase === "Falta" &&
      ult_inc_status_aut === "A"
    ) {
      return false;
    }

    if (
      registro !== null &&
      estatus_clase === "Cancelada" &&
      ult_inc_status_aut === "A"
    ) {
      return false;
    }

    // General conditions that return true
    if (registro === null) {
      return true;
    } else if (valueQuincena === null) {
      return true;
    } else if (valueQuincena >= valueQuincenaActual) {
      return true;
    } else if (deshabilitar) {
      return true;
    } else if (subDeshabilitar) {
      return true;
    } else if (registro !== null && estatus_clase === "Cancelada") {
      return true;
    }

    return false;
  };

  return (
    <>
      <CustomSpinner visible={req} />
      <Contenedor title="Profesores / Mantenimiento de Asistencias">
        <Grid
          container
          // style={{ position: "relative", zIndex: 9 }}
        >
          <Grid
            xs={12}
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Grid style={{ padding: "10px 10px" }}>
              <Grid>
                <NewButton
                  noIcon
                  style={{}}
                  customText="Generar Dictamen Actual"
                  onClick={handleClickGenerarDictamenActual}
                  disabled={
                    permisos[
                      "Profesores->Mantenimiento de Asistencias>Generar Dictamen"
                    ]?.escribir !== "1"
                  }
                />
              </Grid>
            </Grid>

            <Grid style={{ padding: "10px 10px" }}>
              <Grid>
                <NewButton
                  noIcon
                  style={{}}
                  customText="Buscar"
                  onClick={handleClickBuscar}
                />
                {permisos[
                  "Profesores->Mantenimiento de Asistencias->Crear Dictamen"
                ]?.escribir === "1" && (
                  <NewButton
                    noIcon
                    style={{ marginLeft: 10 }}
                    customText="Crear"
                    onClick={handleClickCrear}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid
            xs={12}
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <Grid
              container
              alignItems="center"
              spacing={2}
              className="nuevoUsuarioForm"
              style={{ marginLeft: "-1%" }}
            >
              <Grid item xs={1} sm={2} container style={{ textAlign: "end" }}>
                <label>Fecha Inicio(*):</label>
              </Grid>
              <Grid item xs={1} sm={2} style={{ marginLeft: "-4%" }}>
                <TextField
                  className="select-modal input-ciclo"
                  type="date"
                  id="initialDate"
                  value={fechaInicialValue}
                  onChange={(e) => setFechaInicialValue(e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  sx={{
                    "& .notchedOutlinet": {
                      border: "none !important",
                    },
                  }}
                  style={{ width: "100%" }}
                />
              </Grid>
              <Grid
                item
                xs={1}
                sm={2}
                container
                style={{ justifyContent: "flex-end", marginLeft: "-10%" }}
              >
                <label>Hora:</label>
              </Grid>
              <Grid item xs={2} style={{ display: "flex", marginLeft: "-1%" }}>
                <input
                  className="filter-input"
                  type="number"
                  min="0"
                  max="23"
                  // placeholder="23"
                  value={horaValue}
                  onChange={(e) => handleHoraValue(e.target.value, "i")}
                />
                <label>:</label>
                <input
                  className="filter-input"
                  style={{ marginLeft: "1%" }}
                  type="number"
                  min="0"
                  max="59"
                  value={minutosValue}
                  onChange={(e) => handleMinutosValue(e.target.value, "i")}
                />
              </Grid>
              <Grid
                item
                xs={1}
                sm={2}
                container
                style={{ justifyContent: "flex-end", marginLeft: "-4%" }}
              >
                <label>Clave Profesor:</label>
              </Grid>
              <Grid item xs={1} sm={3}>
                <AsyncSelect
                  className="select-modal-container"
                  classNamePrefix="select-modal"
                  styles={asyncSelectStyles}
                  loadOptions={promiseOptions}
                  onChange={setProfesorValue}
                  isClearable={true}
                />
              </Grid>
            </Grid>
            <Grid justifyContent="center" style={{ width: 300 }}>
              <Grid
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "5px 10px",
                }}
                className="export-buttons"
              >
                <Typography style={{ marginTop: "auto", marginBottom: "auto" }}>
                  Descargar:
                </Typography>
                <div>
                  <button
                    className={"buttonDownload"}
                    onClick={DownloadExcel}
                    disabled={req}
                    variant="contained"
                  >
                    <img src={excelIcon} alt="excel-icon" />
                  </button>
                  <button
                    className={"buttonDownload"}
                    onClick={DownloadPDF}
                    disabled={req}
                    variant="contained"
                  >
                    <img src={pdfFileIcon} alt="pdf-file-icon" />
                  </button>
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            xs={12}
            style={{ display: "flex", justifyContent: "end", marginTop: 15 }}
          >
            <Grid style={{ width: "220px" }}>
              <SearchInput hideIcon disabled search={search} />
            </Grid>
          </Grid>
        </Grid>
        <div style={{ height: "calc(100% - 190px)", marginTop: "10px" }}>
          <DataTable
            headers={headers}
            data={data}
            loading={loading}
            centrar={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
            paginate
            bordes
          />
        </div>

        <NewModal
          title="Incidencia"
          open={modalIncidenciaIsOpen}
          handleClose={() => {
            limpiar();
            setValueTipoIncidencia("");
            setValueAutorizacion(false);
            setModalIncidenciaIsOpen(false);
            setDeshabilitar(false);
          }}
          height={700}
          maxWidth={1000}
          loading={loadingModal}
          buttonDisabled={deshabilitar || isFechaProcesoQuincena}
          handleSubmit={handleSubmitIncidencia}
        >
          <div style={{ padding: "25px" }}>
            <Grid
              container
              spacing={1}
              style={{
                backgroundColor: "#e9ecef",
                padding: "5px",
                borderRadius: "5px",
                marginBottom: "25px",
                alignItems: "center",
              }}
            >
              <Grid item xs={4}>
                <h4 className={styles.modalSectionName}>
                  Datos Generales del Grupo
                </h4>
              </Grid>
              <Grid item xs={8} />

              <Grid item xs={2} style={{ marginLeft: "-3%" }}>
                <label className={styles.inputLabel}>Grupo:</label>
              </Grid>
              <Grid item xs={1}>
                <input
                  className={styles.filterInput}
                  disabled
                  value={valueGrupo}
                  style={{ width: "140%" }}
                />
              </Grid>
              <Grid item xs={3} />
              <Grid item xs={2} style={{ marginLeft: "5%" }}>
                <label className={styles.inputLabel}>Materia:</label>
              </Grid>
              <Grid xs={1} item>
                <input
                  className={styles.filterInput}
                  disabled
                  value={valueClaveMateria}
                  style={{ width: "140%" }}
                />
              </Grid>
              <Grid xs={2} item style={{ marginLeft: "3%" }}>
                <input
                  className={styles.filterInput}
                  disabled
                  value={valueMateria}
                  style={{ width: "115.5%" }}
                />
              </Grid>
              <Grid item xs={2} style={{ marginLeft: "-3%" }}>
                <label className={styles.inputLabel}>Clave Profesor:</label>
              </Grid>
              <Grid item xs={1}>
                <input
                  className={styles.filterInput}
                  disabled
                  value={valueClaveProfesor}
                  style={{ width: "140%" }}
                />
              </Grid>
              <Grid item xs={3} style={{ marginLeft: "3%" }}>
                <input
                  className={styles.filterInput}
                  disabled
                  style={{ width: "122.5%" }}
                  value={valueProfesor}
                />
              </Grid>
              <Grid item xs={2} style={{ marginLeft: "2%" }}>
                <label className={styles.inputLabel}>Tipo Profesor:</label>
              </Grid>
              <Grid item xs={3}>
                <input
                  className={styles.filterInput}
                  disabled
                  value={valueTipoProfesor}
                  style={{ width: "122.5%" }}
                />
              </Grid>
              <Grid item xs={2} style={{ marginLeft: "-3%" }}>
                <label className={styles.inputLabel}>Asistencia:</label>
              </Grid>
              <Grid item xs={1}>
                <input
                  className={styles.filterInput}
                  disabled
                  value={valueAsistencia}
                  style={{ width: "180%" }}
                />
              </Grid>
              <Grid item xs={2} style={{ marginLeft: "1.5%" }}>
                <label className={styles.inputLabel}>Horario:</label>
              </Grid>
              <Grid item xs={2}>
                <input
                  className={styles.filterInput}
                  disabled
                  value={valueHorario}
                  style={{ width: "90%" }}
                />
              </Grid>
              <Grid item xs={1} style={{ marginLeft: "3.5%" }}>
                <label className={styles.inputLabel}>Registro:</label>
              </Grid>
              <Grid item xs={1}>
                <input
                  className={styles.filterInput}
                  disabled
                  value={valueRegistro}
                  style={{ width: "130%" }}
                />
              </Grid>
              <Grid item xs={2} />
              <Grid item xs={2} style={{ marginLeft: "-3%" }}>
                <label className={styles.inputLabel}>Estatus Registro:</label>
              </Grid>
              <Grid item xs={1}>
                <input
                  className={styles.filterInput}
                  disabled
                  value={valueEstatusRegistro}
                  style={{ width: "180%" }}
                />
              </Grid>
              <Grid item xs={2} style={{ marginLeft: "1.5%" }}>
                <label className={styles.inputLabel}>Estatus Clase:</label>
              </Grid>
              <Grid item xs={2}>
                <input
                  className={styles.filterInput}
                  disabled
                  value={valueEstatusClase}
                  style={{ width: "90%" }}
                />
              </Grid>
              <Grid item xs={2} style={{ marginLeft: "-4.75%" }}>
                <label className={styles.inputLabel}>Hora Mínima:</label>
              </Grid>
              <Grid item xs={1}>
                <input
                  className={styles.filterInput}
                  disabled
                  value={valueHoraMinima}
                  style={{ width: "130%" }}
                />
              </Grid>
              <Grid item xs={2} />
              <Grid item xs={2} style={{ marginLeft: "-3%" }}>
                <label className={styles.inputLabel}>Tipo Clase:</label>
              </Grid>
              <Grid item xs={1}>
                <input
                  className={styles.filterInput}
                  disabled
                  value={valueTipoClase}
                  style={{ width: "180%" }}
                />
              </Grid>
              <Grid item xs={2} style={{ marginLeft: "1.5%" }}>
                <label className={styles.inputLabel}>Salón:</label>
              </Grid>
              <Grid item xs={2}>
                <input
                  className={styles.filterInput}
                  disabled
                  value={valueSalon}
                  style={{ width: "90%" }}
                />
              </Grid>
              <Grid item xs={4} />
              <Grid item xs={2} style={{ marginLeft: "-3%" }}>
                <label className={styles.inputLabel}>Detalle:</label>
              </Grid>
              <Grid item xs={4}>
                <input
                  className={styles.filterInput}
                  disabled
                  value={valueDetalle}
                  style={{ width: "125%" }}
                />
              </Grid>
              <Grid item xs={5} />
              <Grid item xs={2} style={{ marginLeft: "-3%" }}>
                <label className={styles.inputLabel}>Tema:</label>
              </Grid>
              <Grid item xs={10}>
                <input
                  className={styles.filterInput}
                  disabled
                  value={valueTema}
                  style={{ width: "102%" }}
                />
              </Grid>
            </Grid>

            <Grid
              container
              spacing={1}
              style={{
                backgroundColor: "#e9ecef",
                padding: "10px",
                borderRadius: "5px",
                alignItems: "center",
                marginBottom: "25px",
              }}
            >
              <Grid item xs={12}>
                <h4>Nueva Incidencia:</h4>
              </Grid>
              <Grid item xs={2}>
                <label className={styles.inputLabel}>Tipo de Incidencia</label>
              </Grid>
              <Grid item xs={3}>
                {deshabilitar ? (
                  <input
                    className="filter-input"
                    style={{ width: "100%" }}
                    value={valueTipoIncidencia}
                    disabled={true}
                  />
                ) : (
                  <Select
                    className="select-modal-container"
                    classNamePrefix="select-modal"
                    value={valueTipoIncidencia}
                    options={tipoIncidenciaOptions}
                    onChange={setValueTipoIncidencia}
                    isDisabled={isFechaProcesoQuincena}
                  />
                )}
              </Grid>
              <Grid item xs={6} />
              <Grid item xs={2}>
                <label className={styles.inputLabel}>Hora de Incidencia:</label>
              </Grid>
              <Grid item xs={2}>
                <div style={{ display: "flex", alignItem: "center" }}>
                  <input
                    className="filter-input"
                    type="number"
                    style={{ width: "60%" }}
                    min="0"
                    max="23"
                    value={horaAsistenciaValue}
                    disabled={isFechaProcesoQuincena || deshabilitar}
                    onChange={(e) => handleHoraValue(e.target.value, "a")}
                  />
                  <label style={{ marginTop: "3%", marginLeft: "2%" }}>:</label>
                  <input
                    className="filter-input"
                    style={{ marginLeft: "2%", width: "60%" }}
                    type="number"
                    min="0"
                    max="59"
                    value={minutosAsistenciaValue}
                    disabled={isFechaProcesoQuincena || deshabilitar}
                    onChange={(e) => handleMinutosValue(e.target.value, "a")}
                  />
                </div>
              </Grid>
              <Grid item xs={1} style={{ marginLeft: "5%" }}>
                <label className={styles.inputLabel}>Autorización:</label>
              </Grid>
              <Grid item xs={2} style={{ marginTop: "0.5%" }}>
                <input
                  type={"checkbox"}
                  style={{ width: "18px", height: "18px" }}
                  value={valueAutorizacion}
                  onChange={setValueAutorizacion}
                  disabled={isFechaProcesoQuincena || deshabilitar}
                />
              </Grid>
              <Grid item xs={3} />
              <Grid item xs={2}>
                <label className={styles.inputLabel}>Comentario:</label>
              </Grid>
              <Grid item xs={10}>
                <TextareaAutosize
                  className="filter-input"
                  maxRows={7}
                  value={valueComentario}
                  disabled={isFechaProcesoQuincena || deshabilitar}
                  onChange={(e) => setValueComentario(e.target.value)}
                  style={{
                    width: "99%",
                    height: 70,
                    resize: "none",
                    overflow: "auto",
                  }}
                />
              </Grid>
            </Grid>
            <Grid
              container
              spacing={1}
              style={{
                backgroundColor: "#e9ecef",
                padding: "10px",
                borderRadius: "5px",
                alignItems: "center",
              }}
            >
              <Grid item xs={12}>
                <h4>Bitácora</h4>
              </Grid>
              <Grid item xs={12}>
                <table className="table_dias table_background">
                  <tr>
                    <th>Tipo</th>
                    <th>Hora</th>
                    <th>Comentario</th>
                    <th>Creado por</th>
                    <th>Creado</th>
                    <th>Estatus</th>
                    <th>Acciones</th>
                  </tr>

                  {valueIncidencia.map((e, i) => (
                    <>
                      <tr>
                        <td>{e["tipo_incidencia"]}</td>
                        <td>{e["hora_incidencia"]}</td>
                        <td>{e["comentario_incidencia"]}</td>
                        <td>{e["creado_por"]}</td>
                        <td>{e["creado"]}</td>
                        <td>{e["active"] ? "V" : "C"}</td>
                        <td
                          className="bg_select"
                          style={{ zIndex: 999, position: "relative" }}
                        >
                          <EditButton
                            disabled={!e["active"] || deshabilitar}
                            style={{ marginLeft: "3%" }}
                            onClick={() =>
                              handleOpenModalcancelarIncidenciaBitacora(e)
                            }
                          >
                            Cancelar
                          </EditButton>
                        </td>
                      </tr>
                    </>
                  ))}
                </table>
              </Grid>
            </Grid>
          </div>
        </NewModal>

        <NewModal
          title="Asistencia"
          open={modalAsistenciaIsOpen}
          handleClose={() => {
            limpiar();
            setModalAsistenciaIsOpen(false);
            setDeshabilitar(false);
          }}
          height={630}
          maxWidth={1000}
          loading={loadingModal}
          handleSubmit={handleSubmitAsistencia}
          buttonDisabled={deshabilitar || isFechaProcesoQuincena}
          cancelarAsistencia={handleCancelarAsistencia}
          cancelarAsistenciadaDisabled={
            horaAsistenciaValue === "" ||
            minutosAsistenciaValue === "" ||
            deshabilitar ||
            registro === "null" ||
            subDeshabilitar ||
            isFechaProcesoQuincena
              ? true
              : false
          }
          cancelarRegistroAsistencia={handleCancelarRegistroAsistencia}
          cancelarRegistroAsistenciadaDisabled={
            valueEstatusClase !== "Falta" ||
            deshabilitar ||
            subDeshabilitar ||
            isFechaProcesoQuincena
              ? true
              : false
          }
          isPago={true}
          isPagoDisable={handleIsDesablePago()}
          handlePago={() => {
            setModalConfirmacionOpen(true);
          }}
        >
          <div style={{ padding: "25px" }}>
            <Grid
              container
              spacing={1}
              style={{
                backgroundColor: "#e9ecef",
                padding: "5px",
                borderRadius: "5px",
                marginBottom: "25px",
                alignItems: "center",
              }}
            >
              <Grid item xs={4}>
                <h4 className={styles.modalSectionName}>
                  Datos Generales del Grupo
                </h4>
              </Grid>
              <Grid item xs={8} />

              <Grid item xs={2} style={{ marginLeft: "-3%" }}>
                <label className={styles.inputLabel}>Grupo:</label>
              </Grid>
              <Grid item xs={1}>
                <input
                  className={styles.filterInput}
                  disabled
                  value={valueGrupo}
                  style={{ width: "140%" }}
                />
              </Grid>
              <Grid item xs={3} />
              <Grid item xs={2} style={{ marginLeft: "5%" }}>
                <label className={styles.inputLabel}>Materia:</label>
              </Grid>
              <Grid xs={1} item>
                <input
                  className={styles.filterInput}
                  disabled
                  value={valueClaveMateria}
                  style={{ width: "140%" }}
                />
              </Grid>
              <Grid xs={2} item style={{ marginLeft: "3%" }}>
                <input
                  className={styles.filterInput}
                  disabled
                  value={valueMateria}
                  style={{ width: "115.5%" }}
                />
              </Grid>
              <Grid item xs={2} style={{ marginLeft: "-3%" }}>
                <label className={styles.inputLabel}>Clave Profesor:</label>
              </Grid>
              <Grid item xs={1}>
                <input
                  className={styles.filterInput}
                  disabled
                  value={valueClaveProfesor}
                  style={{ width: "140%" }}
                />
              </Grid>
              <Grid item xs={3} style={{ marginLeft: "3%" }}>
                <input
                  className={styles.filterInput}
                  disabled
                  style={{ width: "122.5%" }}
                  value={valueProfesor}
                />
              </Grid>
              <Grid item xs={2} style={{ marginLeft: "2%" }}>
                <label className={styles.inputLabel}>Tipo Profesor:</label>
              </Grid>
              <Grid item xs={3}>
                <input
                  className={styles.filterInput}
                  disabled
                  value={valueTipoProfesor}
                  style={{ width: "122.5%" }}
                />
              </Grid>
              <Grid item xs={2} style={{ marginLeft: "-3%" }}>
                <label className={styles.inputLabel}>Asistencia:</label>
              </Grid>
              <Grid item xs={1}>
                <input
                  className={styles.filterInput}
                  disabled
                  value={valueAsistencia}
                  style={{ width: "180%" }}
                />
              </Grid>
              <Grid item xs={2} style={{ marginLeft: "1.5%" }}>
                <label className={styles.inputLabel}>Horario:</label>
              </Grid>
              <Grid item xs={2}>
                <input
                  className={styles.filterInput}
                  disabled
                  value={valueHorario}
                  style={{ width: "90%" }}
                />
              </Grid>
              <Grid item xs={1} style={{ marginLeft: "3.5%" }}>
                <label className={styles.inputLabel}>Registro(*):</label>
              </Grid>
              <Grid item xs={2} style={{ zIndex: 99 }}>
                <div style={{ display: "flex", alignItem: "center" }}>
                  <input
                    className="filter-input"
                    type="number"
                    style={{ width: "60%" }}
                    min="0"
                    max="23"
                    disabled={deshabilitar || isFechaProcesoQuincena}
                    value={horaAsistenciaValue}
                    onChange={(e) => handleHoraValue(e.target.value, "a")}
                  />
                  <label style={{ marginTop: "3%", marginLeft: "2%" }}>:</label>
                  <input
                    className="filter-input"
                    style={{ marginLeft: "2%", width: "60%" }}
                    type="number"
                    min="0"
                    max="59"
                    disabled={deshabilitar || isFechaProcesoQuincena}
                    value={minutosAsistenciaValue}
                    onChange={(e) => handleMinutosValue(e.target.value, "a")}
                  />
                </div>
              </Grid>
              <Grid item xs={1} />
              <Grid item xs={2} style={{ marginLeft: "-3%" }}>
                <label className={styles.inputLabel}>Estatus Registro:</label>
              </Grid>
              <Grid item xs={1}>
                <input
                  className={styles.filterInput}
                  disabled
                  value={valueEstatusRegistro}
                  style={{ width: "180%" }}
                />
              </Grid>
              <Grid item xs={2} style={{ marginLeft: "1.5%" }}>
                <label className={styles.inputLabel}>Estatus Clase:</label>
              </Grid>
              <Grid item xs={2}>
                <input
                  className={styles.filterInput}
                  disabled
                  value={valueEstatusClase}
                  style={{ width: "90%" }}
                />
              </Grid>
              <Grid item xs={5}>
                <EditButton
                  onClick={() => handleSustitucionModalOpen()}
                  disabled={deshabilitar || subDeshabilitar}
                >
                  Sustitución
                </EditButton>
              </Grid>
              <Grid item xs={2} style={{ marginLeft: "-3%" }}>
                <label className={styles.inputLabel}>Tipo Clase:</label>
              </Grid>
              <Grid item xs={1}>
                <input
                  className={styles.filterInput}
                  disabled
                  value={valueTipoClase}
                  style={{ width: "180%" }}
                />
              </Grid>
              <Grid item xs={2} style={{ marginLeft: "1.5%" }}>
                <label className={styles.inputLabel}>Salón:</label>
              </Grid>
              <Grid item xs={2}>
                <input
                  className={styles.filterInput}
                  disabled
                  value={valueSalon}
                  style={{ width: "90%" }}
                />
              </Grid>
              <Grid item xs={4} />
              <Grid item xs={2} style={{ marginLeft: "-3%" }}>
                <label className={styles.inputLabel}>Detalle:</label>
              </Grid>
              <Grid item xs={4}>
                <input
                  className={styles.filterInput}
                  disabled
                  value={valueDetalle}
                  style={{ width: "125%" }}
                />
              </Grid>
              <Grid item xs={5} />
              <Grid item xs={2} style={{ marginLeft: "-3%" }}>
                <label className={styles.inputLabel}>Tema(*):</label>
              </Grid>
              <Grid item xs={10}>
                <input
                  className={styles.filterInput}
                  value={valueTema}
                  disabled={deshabilitar || isFechaProcesoQuincena}
                  onChange={(e) => setValueTema(e.target.value)}
                  style={{ width: "102%" }}
                />
              </Grid>
            </Grid>

            <Grid
              container
              spacing={1}
              style={{
                backgroundColor: "#e9ecef",
                padding: "10px",
                borderRadius: "5px",
                alignItems: "center",
              }}
            >
              <Grid item xs={7} />
              <Grid item xs={1} style={{ marginLeft: "4.5%" }}>
                <label className={styles.inputLabel}>Quincena:</label>
              </Grid>
              <Grid item xs={3}>
                <input
                  className={styles.filterInput}
                  disabled
                  value={valueQuincena}
                  style={{ width: "100%" }}
                />
              </Grid>
              <Grid item xs={2} style={{ marginLeft: "-3%" }}>
                <label className={styles.inputLabel}>Comentario(*):</label>
              </Grid>
              <Grid item xs={10}>
                <TextareaAutosize
                  className="filter-input"
                  maxRows={7}
                  value={valueComentario}
                  disabled={deshabilitar || isFechaProcesoQuincena}
                  onChange={(e) => setValueComentario(e.target.value)}
                  style={{
                    width: "99%",
                    height: 70,
                    resize: "none",
                    overflow: "auto",
                  }}
                />
              </Grid>
            </Grid>
          </div>
        </NewModal>
        <NewModal
          title={"Comentario"}
          open={modalComentarioIsOpen}
          handleClose={() => {
            setValueMotivo("");
            setModalComentarioIsOpen(false);
          }}
          handleSubmit={cancelarAsistencia}
          loading={loadingModalComentario}
          style
          height={220}
          maxWidth={600}
          marginTop={"8%"}
        >
          <Grid container style={{ marginTop: "2%", marginLeft: "-1%" }}>
            <Grid
              container
              item
              spacing={1}
              alignItems="center"
              justifyContent="center"
              style={{ marginTop: "0.5%" }}
            >
              <Grid item xs={12} md={10} style={{ textAlign: "center" }}>
                <p>
                  Se modificará el registro a su estado original. ¿Desea
                  Continuar?
                </p>
              </Grid>
              <Grid item xs={12} md={10} style={{ display: "flex" }}>
                <label>Motivo(*):</label>
                <TextareaAutosize
                  className="filter-input"
                  maxRows={7}
                  value={valueMotivo}
                  onChange={(e) => setValueMotivo(e.target.value)}
                  style={{
                    marginLeft: "2%",
                    height: 80,
                    resize: "none",
                    overflow: "auto",
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </NewModal>
        <NewModal
          title={"Confirmación"}
          open={modalComentarioRegistroIsOpen}
          handleClose={() => {
            setValueMotivo("");
            setModalComentarioRegistroIsOpen(false);
          }}
          handleSubmit={handleComentarioRegistro}
          loading={loadingModalComentario}
          style
          height={160}
          maxWidth={600}
          marginTop={"8%"}
        >
          <Grid container style={{ marginTop: "2%", marginLeft: "-1%" }}>
            <Grid
              container
              item
              spacing={1}
              alignItems="center"
              justifyContent="center"
              style={{ marginTop: "0.5%" }}
            >
              <Grid item xs={12} md={10} style={{ textAlign: "center" }}>
                <p>
                  ¿Desea cancelar este registro de asitencia? Si lo hace no
                  podrá reactivarlo.
                </p>
              </Grid>
              <Grid item xs={12} md={10} style={{ display: "flex" }}>
                <label>Motivo(*):</label>
                <TextareaAutosize
                  className="filter-input"
                  maxRows={7}
                  value={valueMotivo}
                  onChange={(e) => setValueMotivo(e.target.value)}
                  style={{
                    marginLeft: "2%",
                    height: 50,
                    resize: "none",
                    overflow: "auto",
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </NewModal>

        <NewModal
          title={"Confirmación"}
          open={modalCancelarIncidenciaBitacoraIsOpen}
          handleClose={() => {
            setValueMotivo("");
            setModalCancelarIncidenciaBitacoraIsOpen(false);
          }}
          handleSubmit={handleComentarioIncidenciaBitacora}
          loading={loadingModalComentarioBitacora}
          style
          height={160}
          maxWidth={600}
          marginTop={"8%"}
        >
          <Grid container style={{ marginTop: "2%", marginLeft: "-1%" }}>
            <Grid
              container
              item
              spacing={1}
              alignItems="center"
              justifyContent="center"
              style={{ marginTop: "0.5%" }}
            >
              <Grid item xs={12} md={10} style={{ textAlign: "center" }}>
                <p>¿Desea cancelar incidencia?</p>
              </Grid>
              <Grid item xs={12} md={10} style={{ display: "flex" }}>
                <label>Comentario(*):</label>
                <TextareaAutosize
                  className="filter-input"
                  maxRows={7}
                  value={valueComentarioBitacora}
                  onChange={(e) => setValueComentarioBitacora(e.target.value)}
                  style={{
                    marginLeft: "2%",
                    height: 50,
                    resize: "none",
                    overflow: "auto",
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </NewModal>
        <NewModal
          title={"Sustitución"}
          open={modalSustitucionIsOpen}
          handleClose={() => {
            setValueProfesorSustitucion("");
            setModalSustitucionIsOpen(false);
          }}
          handleSubmit={handleComentarioSustitucion}
          loading={loadingModalComentarioBitacora}
          style
          height={160}
          maxWidth={600}
          marginTop={"8%"}
        >
          <Grid container style={{ marginTop: "2%", marginLeft: "-1%" }}>
            <Grid
              container
              item
              spacing={1}
              alignItems="center"
              justifyContent="center"
              style={{ marginTop: "0.5%", position: "absolute", zIndex: 999 }}
            >
              <Grid item xs={12} md={10} style={{ display: "flex" }}>
                <label style={{ marginRight: 10 }}>Clave Profesor(*):</label>
                <Grid style={{ flex: 1 }}>
                  <AsyncSelect
                    className="select-modal-container"
                    classNamePrefix="select-modal"
                    loadOptions={promiseOptions2}
                    value={valueProfesorSustitucion}
                    onChange={(e) => handleProfesorSustitucion(e)}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </NewModal>
        <NewModal
          title={"Confirmación"}
          open={modalConfirmacionOpen}
          handleClose={() => {
            setModalConfirmacionOpen(false);
          }}
          loading={loadingModal}
          style
          height={200}
          maxWidth={660}
          marginTop={"12%"}
          handleSubmit={handleSubmitConfirmacion}
        >
          <Grid container style={{ marginTop: "3.5%", marginLeft: "-1%" }}>
            <Grid
              container
              item
              spacing={1}
              alignItems="center"
              justifyContent="center"
              style={{ marginTop: "0.5%" }}
            >
              <Grid
                item
                xs={12}
                md={7}
                style={{ textAlign: "center", marginLeft: "4%" }}
              >
                <label>¿Desea generar pago extraordinario?</label>
              </Grid>
            </Grid>
          </Grid>
        </NewModal>
        <NewModal
          title={"Crear Dictamen"}
          open={modalCreate}
          handleClose={() => {
            setModalCreate(false);
            setCreateTeacher([]);
            setCreateGrupoValue("");
            setCreateMateria("");
            setCreateCiclo("");
            setCreateDate("");
            setCreateComment("");
            setCreateHours("");
            setCreateMinutes("");
            setTipoClase({
              label: "1 - Normal",
              value: "1",
            });
          }}
          loading={loadingModal}
          style
          height={400}
          maxWidth={860}
          handleSubmit={handleSubmitCreateDitamen}
          overflowY={"unSet"}
        >
          <CreateDictamentModal
            setCreateTeacher={setCreateTeacher}
            createTeacher={createTeacher}
            setCreateGrupoValue={setCreateGrupoValue}
            createGrupoValue={createGrupoValue}
            createMateria={createMateria}
            setCreateMateria={setCreateMateria}
            createCiclo={createCiclo}
            setCreateCiclo={setCreateCiclo}
            createDate={createDate}
            setCreateDate={setCreateDate}
            createComment={createComment}
            setCreateComment={setCreateComment}
            createHours={createHours}
            setCreateHours={setCreateHours}
            createMinutes={createMinutes}
            setCreateMinutes={setCreateMinutes}
            tipoClase={tipoClase}
            setTipoClase={setTipoClase}
          />
        </NewModal>
        <NewModal
          title={"Confirmación"}
          open={modalDictamen}
          handleClose={() => {
            handleCloseModalDictamen();
          }}
          handleSubmit={generarDictamen}
          loading={loadingModal}
          style
          height={180}
          maxWidth={600}
          marginTop={"8%"}
        >
          <Grid container style={{ marginTop: "2%", marginLeft: "-1%" }}>
            <Grid
              container
              item
              spacing={1}
              alignItems="center"
              justifyContent="center"
              style={{ marginTop: "0.5%" }}
            >
              <Grid item xs={12} md={10} style={{ textAlign: "center" }}>
                <p>
                  ¿Desea ejecutar el proceso para generar los registros de
                  asistencia de la fecha seleccionada?
                </p>
              </Grid>
              <Grid item xs={10}>
                <Grid
                  container
                  alignItems="center"
                  justifyContent="center"
                  spacing={2}
                  className="nuevoUsuarioForm"
                >
                  <Grid
                    item
                    xs={1}
                    sm={2}
                    container
                    style={{ textAlign: "end", marginRight: 20 }}
                  >
                    <label>Fecha(*):</label>
                  </Grid>
                  <Grid item xs={1} sm={6} style={{ marginLeft: "-4%" }}>
                    <TextField
                      className="select-modal input-ciclo"
                      type="date"
                      id="initialDate"
                      value={modalFechaInicialValue}
                      InputProps={{
                        inputProps: { max: moment().format("YYYY-MM-DD") },
                      }}
                      onChange={(e) =>
                        setModalFechaInicialValue(e.target.value)
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                      sx={{
                        "& .notchedOutlinet": {
                          border: "none !important",
                        },
                      }}
                      style={{ width: "100%" }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </NewModal>
      </Contenedor>
    </>
  );
}

export default MantenimientoAsistencias;
