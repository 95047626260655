import moment from "moment";
export const getDataParameters = (
  type,
  dataOriginal,
  row,
  values,
  valueProceso,
  userName
) => {
  if (type === "array") {
    const newData = dataOriginal.map((element) => {
      if (element.id === row.original.id) {
        const newElement = { ...element, ...values };
        Object.keys(element).forEach((key) => {
          if (Array.isArray(element[key])) {
            if (typeof element[key][0] === "number" && valueProceso !== 4) {
              newElement[key] = values[key].split(",").map((data) => {
                return parseInt(data);
              });
            } else {
              newElement[key] = values[key].split(",");
            }
          } else if (["modificado", "fecha_modificado"].includes(key)) {
            newElement[key] = moment().format("YYYY-MM-DD HH:mm:ss");
          } else if (["modificado_por", "usuario_modificado"].includes(key)) {
            newElement[key] = userName;
          } else if (["creado", "fecha_creado"].includes(key)) {
            newElement[key] = element[key];
          }
        });
        if (valueProceso !== 30) {
          delete newElement.id;
        }
        return newElement;
      }
      if (valueProceso !== 30) {
        delete element.id;
      }
      return element;
    });
    return newData;
  } else if (type === "json") {
    const newObject = { ...dataOriginal };
    Object.keys(dataOriginal).forEach((key) => {
      if (values.nombre === key) {
        if (Array.isArray(dataOriginal[key])) {
          if (typeof dataOriginal[key][0] === "number" && valueProceso !== 4) {
            newObject[key] = values.valor.split(",").map((data) => {
              return parseInt(data);
            });
          } else {
            newObject[key] = values.valor.split(",");
          }
        } else if (typeof dataOriginal[key] === "number" && valueProceso !== 4) {
          newObject[key] = parseInt(values.valor);
        } else {
          newObject[key] = values.valor;
        }
      }
    });
    return newObject;
  } else {
    return false;
  }
};
