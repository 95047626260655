import React, { useEffect, useState } from "react";
import Contenedor from "../../Utils/Visuales/Contenedor";
import { Grid } from "@material-ui/core";
import { getRequest, postRequest } from "../../Utils/Funciones/requester";
import { useSnackbar } from "notistack";
import Select from "react-select";
import styles from "./styles.module.css";
import { useSelector } from "react-redux";
import { CustomSpinner } from "../../Utils/Loader";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import NewButton from "../../Utils/Botones/NewButton";
import NewModal from "../../Utils/Visuales/NewModal";
import CreateInternalParametersModal from "../../Utils/CreateInternalParametersModal";
import { validateInternalParameters } from "../../Utils/Funciones/validateInternalParameters";
import { getDataParameters } from "../../Utils/Funciones/getDataParameters";
import CustomTable from "../../Utils/CustomTable";
import { teachingAssignmentMaximumTeachingLoadHeader } from "../../Utils/data/teachingAssignmentMaximumTeachingLoadHeader";
import { specialClassesTotalHoursHeader } from "../../Utils/data/specialClassesTotalHoursHeader";
import moment from "moment";
import EditButton from "../../Utils/Botones/EditButton";
import { getCampusCatalog } from "../../Utils/Funciones/getCampusCatalog";
import { getSchools, getSchoolsClave } from "../../Utils/Funciones/getSchools";
import { formatString } from "../../Utils/Funciones/removeCharacterFromString";
import { getGroupType } from "../../Utils/Funciones/getGroupType";
import { getTabulators } from "../../Utils/Funciones/getTabulators";
import { SelectTipoClaseParametro64 } from "../../Utils/data/SelectTipoClaseParametro64";
import { convertDateForParameters } from "../../Utils/Funciones/convertDateForParameters";
import { getDefaultHeadersForParameters } from "../../Utils/Funciones/getDefaultHeadersForParameters";
import { getPersonCatalog } from "../../Utils/Funciones/getPersonCatalog";

const ParametrosInternos = () => {
  const [loading, setLoading] = useState(false);
  const [loadingMDis, setLoadingMDis] = useState(false);
  const [showTable, setShowTable] = useState(false);
  const { permisos, userName } = useSelector((state) => state);
  const [valueProceso, setValueProceso] = useState("");
  const [optionsProceso, setOptionsProceso] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const [dataFinal, setDataFinal] = useState([]);
  const [dataOriginal, setDataOriginal] = useState([]);
  const [dataTypeOf, setDataTypeOf] = useState("");
  const [tableEdit, setTableEdit] = useState(false);
  const [creadoYModificado, setCreadoYModificado] = useState({});
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showCreateModalTraduccion, setShowCreateModalTraduccion] =
    useState(false);
  const [showCreateModal63, setShowCreateModal63] = useState(false);
  const [createName, setCreateName] = useState("");
  const [createDay, setCreateDay] = useState("");
  const [createMonth, setCreateMonth] = useState("");
  const [createPayble, setCreatePayble] = useState("");
  const [createAssistanceType, setCreateAssistanceType] = useState("");
  const [createCurrent, setCreateCurrent] = useState("S");
  const [createClaveEscuela52, setCreateClaveEscuela52] = useState("");
  const [createSemanas52, setCreateSemanas52] = useState("");
  const [createCarrera52, setCreateCarrera52] = useState("");
  const [createDays63, setCreateDays63] = useState("");
  const [createSchool63, setCreateSchool63] = useState("");
  const [createModulo52, setCreateModulo52] = useState("");
  const [columns, setColumns] = useState([
    {
      accessorKey: "nombre",
      header: "Nombre",
      enableEditing: false,
    },
    {
      accessorKey: "valor",
      header: "Valor",
    },
  ]);
  const [editarParam58, setEditarParam58] = useState(false);
  const [editarParam10, setEditarParam10] = useState(false);
  const [filaEditar, setFilaEditar] = useState(null);
  const [valoresTipoClase, setValoresTipoClase] = useState([]);
  const [valoresPorcentaje, setValoresPorcentaje] = useState([]);
  const [valoresEscuelas, setValoresEscuelas] = useState([]);
  const [sumaTotalPorcentaje, setsumaTotalPorcentaje] = useState(null);
  const [valoresModalDis, setValoresModalDis] = useState("");
  const [estatus, setEstatus] = useState("");
  const estatusOptions = [
    { label: "Vigente", value: "vigente" },
    { label: "No Vigente", value: "no vigente" },
  ];
  const [nroFilas, setNroFilas] = useState([]);
  const [nuevoParam58, setNuevoParam58] = useState(false);
  const [nuevoParam60, setNuevoParam60] = useState(false);
  const [nuevoParam62, setNuevoParam62] = useState(false);
  const [editarParam62, setEditarParam62] = useState(false);
  const [editarParam64, setEditarParam64] = useState(false);
  const [editarParam67, setEditarParam67] = useState(false);
  const [editarParam66, setEditarParam66] = useState(false);
  const [nuevoParam50, setNuevoParam50] = useState(false);
  const [editarParam50, setEditarParam50] = useState(false);
  const [formDataParam50, setFormDataParam50] = useState({
    clve_camp_fnza: "",
    clve_crra: "",
    porcentaje: "",
  });
  const [dataModalParam60, setDataModalParam60] = useState([]);
  const tipoClaseCatalogo = SelectTipoClaseParametro64;
  const [campusCatalogo, setCampusCatalogo] = useState([]);
  const [escuelasCatalogo, setEscuelasCatalogo] = useState([]);
  const [tipoGrupoCatalogo, setTipoGrupoCatalogo] = useState([]);
  const [tabuladoresCatalogo, setTabuladoresCatalogo] = useState([]);
  const [campusSelectedOptions, setCampusSelectedOptions] = useState("");
  const [escuelasSelectedOptions, setEscuelasSelectedOptions] = useState("");
  const [tipoGrupoSelectedOptions, setTipoGrupoSelectedOptions] = useState("");
  const [title, setTitle] = useState({
    nombre: "",
    original: "",
  });
  const [groupTypeList, setGroupTypeList] = useState([]);
  const [personList, setPersonList] = useState([]);
  const [tabuladoresSelectedOptions, setTabuladoresSelectedOptions] =
    useState("");
  const [estatusSelectedOptions, setEstatusSelectedOptions] = useState("");

  const handleSaveRow = async ({ exitEditingMode, row, values }) => {
    const newData = getDataParameters(
      dataTypeOf,
      dataOriginal,
      row,
      values,
      valueProceso,
      userName
    );
    if (newData !== false) {
      try {
        const payload = {
          id: valueProceso,
          data: newData,
        };

        switch (valueProceso) {
          case 65:
            const str = values.valor_dias;
            const isOnlyNumbers = (str) => /^\d+$/.test(str);
            const num = parseInt(str);
            if (num < 0 || isNaN(num) || !isOnlyNumbers(str)) {
              enqueueSnackbar("Valor dia debe de ser numerico", {
                variant: "error",
                autoHideDuration: null,
                anchorOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
              });
              return;
            }
            break;
          default:
            break;
        }

        const response = await postRequest(`/parametro_reglas`, payload);

        if (response.s === "OK") {
          enqueueSnackbar(response.m, {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "left",
            },
          });
          setShowTable(false);
          setTableEdit(false);
          GetDataTable(valueProceso);
        } else {
          enqueueSnackbar(response.m, {
            variant: "error",
            autoHideDuration: null,
            anchorOrigin: {
              vertical: "top",
              horizontal: "left",
            },
          });
        }
      } catch (error) {}
      exitEditingMode(); //required to exit editing mode
    }
  };

  const GetListParametro = async () => {
    try {
      setLoading(true);
      const consulta = `/parametro_reglas`;
      const response = await getRequest(consulta);
      if (response.s === "OK") {
        let arrayResponse = response.d.map((e) => {
          const data2 = {
            label: `${e.id} - ${formatString(e.nombre)}`,
            value: e.id,
          };
          return data2;
        });
        setOptionsProceso(arrayResponse);
      }
      setShowTable(false);
      setLoading(false);
    } catch (error) {}
  };

  useEffect(() => {
    const suma = valoresPorcentaje?.reduce((total, subarreglo) => {
      return (
        total + (isNaN(parseInt(subarreglo[0])) ? 0 : parseInt(subarreglo[0]))
      );
    }, 0);
    setsumaTotalPorcentaje(suma);
  }, [valoresPorcentaje]);

  const GetDataTable = async (value, check = false) => {
    setShowTable(false);
    setColumns([]);
    try {
      setValueProceso(value);
      setLoading(true);
      setCreadoYModificado({});
      const request = `/parametro_reglas?id=${value}`;
      const response = await getRequest(request);
      if (response.s === "OK") {
        if (value === 58) {
          setValoresModalDis(response.d.data);
        }

        setShowTable(true);
        enqueueSnackbar(response.m, {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
        setCreadoYModificado(response.d);
        if (Array.isArray(response?.d?.data)) {
          setDataTypeOf("array");
          setDataOriginal(response?.d?.data);
          switch (value) {
            case 7:
            case 17:
              setColumns(teachingAssignmentMaximumTeachingLoadHeader);
              break;
            case 21:
            case 22:
            case 23:
              setColumns(specialClassesTotalHoursHeader);
              break;
            default:
              const elementos_map = Object.keys(
                response.d?.data?.[0] === undefined ? {} : response.d?.data?.[0]
              );
              const headers = elementos_map.map((element) => {
                if (element === "active" || element === "id") {
                  return null;
                }
                return getDefaultHeadersForParameters(element, valueProceso);
              });
              setColumns(headers.filter((element) => element !== null));
              break;
          }
          // set date for table
          const dataTable = response.d.data.map((element, index) => {
            const keys = Object.keys(element);
            const rowData = {};
            keys.forEach((key) => {
              if (Array.isArray(element[key])) {
                rowData[key] = element[key].join(",");
              } else {
                rowData[key] = element[key];
              }
            });
            return { ...rowData, id: index };
          });
          setDataOriginal(
            response.d.data.map((element, index) => {
              return { ...element, id: index };
            })
          );
          setDataFinal(
            dataTable.sort((a, b) =>
              a?.clave_escuela > b?.clave_escuela
                ? 1
                : b?.clave_escuela > a?.clave_escuela
                ? -1
                : 0
            )
          );
          setLoading(false);
        } else if (
          typeof response.d?.data === "object" &&
          !Array.isArray(response.d?.data) &&
          response.d?.data !== null
        ) {
          setDataTypeOf("json");
          // set Headers
          setColumns([
            {
              accessorKey: "nombre",
              header: "Nombre",
              enableEditing: false,
            },
            {
              accessorKey: "valor",
              header: "Valor",
            },
          ]);
          // set date for table
          const dataTable = Object.keys(response.d?.data).map((key) => {
            return {
              nombre: key,
              valor: Array.isArray(response.d?.data[key])
                ? response.d?.data[key].join(",")
                : response.d?.data[key],
            };
          });
          setDataOriginal(response.d.data);
          setDataFinal(dataTable);
          setLoading(false);
        }
      } else {
        enqueueSnackbar(response.m, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
        setShowTable(false);
        setLoading(false);
      }
    } catch (error) {}
  };

  const handleClickEditTable = (event) => {
    setShowTable(false);
    setCreadoYModificado(null);
    setTableEdit(event.target.checked);
    GetDataTable(valueProceso, event.target.checked);
  };

  const handleCloseCreateModal = () => {
    setShowCreateModal(false);
  };

  const handleSubmitCreateModal = async () => {
    const check = validateInternalParameters(
      createName,
      createDay,
      createMonth,
      createPayble,
      createAssistanceType,
      createCurrent
    );
    if (check === true) {
      const newData = dataOriginal;
      newData.push({
        id: newData.length,
        nombre: createName,
        dia: parseInt(createDay),
        mes: parseInt(createMonth),
        pagable: createPayble,
        tipo_asistencia: [createAssistanceType],
        Vigente: createCurrent,
      });
      try {
        const payload = {
          id: valueProceso,
          data: newData,
        };
        const response = await postRequest(`/parametro_reglas`, payload);

        if (response.s === "OK") {
          enqueueSnackbar(response.m, {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "left",
            },
          });
          setShowCreateModal(false);
          setShowTable(false);
          setTableEdit(false);
          GetDataTable(valueProceso);
        } else {
          enqueueSnackbar(response.m, {
            variant: "error",
            autoHideDuration: null,
            anchorOrigin: {
              vertical: "top",
              horizontal: "left",
            },
          });
        }
      } catch (error) {}
    }
  };

  const agregarNuevaFila = (nroFilas) => {
    if (nroFilas.length === 0) {
      setValoresTipoClase([]);
    }
    let filas = [...nroFilas];
    filas.push([0, 0]);
    valueProceso === 10
      ? valoresEscuelas.push(null)
      : valueProceso === 64
      ? valoresTipoClase.push(null)
      : valoresPorcentaje.push([0, 0]);
    setNroFilas(filas);
  };

  const deleteRow = (i) => {
    if (valueProceso === 10) {
      const copiaValoresEscuelas = [...valoresEscuelas];
      copiaValoresEscuelas.splice(i, 1);
      setValoresEscuelas(copiaValoresEscuelas);
      setNroFilas(copiaValoresEscuelas);
    } else if (
      valueProceso === 64 ||
      valueProceso === 66 ||
      valueProceso === 67
    ) {
      const copiaValores = [...valoresTipoClase];
      copiaValores.splice(i, 1);
      setValoresTipoClase(copiaValores);
      setNroFilas(copiaValores);
    } else {
      const copiaValoresPorcentaje = [...valoresPorcentaje];
      copiaValoresPorcentaje.splice(i, 1);
      setValoresPorcentaje(copiaValoresPorcentaje);
      setNroFilas(copiaValoresPorcentaje);
    }
  };

  const handleSubmitDis = async () => {
    if (sumaTotalPorcentaje > 100) {
      enqueueSnackbar("El porcentaje total no puede ser mayor a 100", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      return;
    } else if (nroFilas.length === 0) {
      enqueueSnackbar("Se debe de agregar al menos un Porcentaje", {
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      setLoadingMDis(false);
    }

    setLoadingMDis(true);
    if (sumaTotalPorcentaje !== null) {
      dataFinal[filaEditar].Porcentaje = sumaTotalPorcentaje;
    }
    if (estatus.label !== undefined) {
      dataFinal[filaEditar].Estatus = estatus.label;
    }
    const convertedData = valoresPorcentaje.map(
      (pair) => `${pair[0]}% => ${pair[1]}`
    );
    const result = convertedData.join(", ");
    dataFinal[filaEditar].Distribucion = result;

    try {
      const payload = {
        id: valueProceso,
        data: dataFinal,
      };
      const response = await postRequest(`/parametro_reglas`, payload);

      if (response.s === "OK") {
        enqueueSnackbar(response.m, {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
        setShowTable(false);
        GetDataTable(valueProceso);
      } else {
        enqueueSnackbar(response.m, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
    } finally {
      setEditarParam58(false);
      setLoadingMDis(false);
    }
  };

  const handleInputChange = (index, e) => {
    const newData = [...dataModalParam60];
    newData[index] = e.target.value;
    setDataModalParam60(newData);
  };

  const handleSubmitParam60Nuevo = async () => {
    const filtrados = dataModalParam60.filter((elemento) => {
      return elemento !== "" && elemento !== null && elemento !== undefined;
    });

    if (filtrados.length < 3) {
      enqueueSnackbar("Todos los campos son obligatorios", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      return;
    }
    setLoadingMDis(true);
    const nuevoRegistro = {
      clave_escuela: dataModalParam60[0],
      clave_grupo: dataModalParam60[1],
      dias: dataModalParam60[2],
    };

    dataFinal.push(nuevoRegistro);

    try {
      const payload = {
        id: valueProceso,
        data: dataFinal,
      };
      const response = await postRequest(`/parametro_reglas`, payload);

      if (response.s === "OK") {
        enqueueSnackbar(response.m, {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
        setShowTable(false);
        GetDataTable(valueProceso);
      } else {
        enqueueSnackbar(response.m, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
    } finally {
      setNuevoParam60(false);
      setLoadingMDis(false);
      setDataModalParam60([]);
    }
  };

  const handleSubmit52Nuevo = async () => {
    let valid = true;
    if (createClaveEscuela52 === "") {
      valid = false;
      enqueueSnackbar("Se debe de agregar una Clave Escuela", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      return;
    } else if (createSemanas52 === "") {
      valid = false;
      enqueueSnackbar("Se debe de agregar un Numero de Semana", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      return;
    } else if (createCarrera52 === "") {
      valid = false;
      enqueueSnackbar("Se debe de agregar una Clave Carrera", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      return;
    } else if (createModulo52 === "") {
      valid = false;
      enqueueSnackbar("Se debe de agregar una Modulo", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      return;
    }
    valid = !dataFinal.some(
      (item) =>
        createClaveEscuela52 === item.clave_esc &&
        createSemanas52 === item.num_semanas
    );

    if (!valid) {
      enqueueSnackbar(
        "Ya existe un registro para esa Clave Escuela y Numero de Semana",
        {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        }
      );
      return;
    }
    if (valid) {
      try {
        setLoadingMDis(true);
        const newData = [...dataFinal];
        newData.push({
          clave_esc: createClaveEscuela52,
          num_semanas: createSemanas52,
          clave_crra: createCarrera52,
          horas_modulo: createModulo52,
          id: dataFinal.length,
        });
        const payload = {
          id: valueProceso,
          data: newData,
        };
        const response = await postRequest(`/parametro_reglas`, payload);
        if (response.s === "OK") {
          enqueueSnackbar(response.m, {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "left",
            },
          });
          GetDataTable(valueProceso);
          setCreateClaveEscuela52("");
          setCreateSemanas52("");
          setCreateCarrera52("");
          setCreateModulo52("");
          setLoadingMDis(false);
          setShowCreateModalTraduccion(false);
        } else {
          setLoadingMDis(false);
          enqueueSnackbar(response.m, {
            variant: "error",
            autoHideDuration: null,
            anchorOrigin: {
              vertical: "top",
              horizontal: "left",
            },
          });
        }
      } catch (error) {}
    }
  };
  const handleSubmitDisNuevo = async () => {
    if (sumaTotalPorcentaje > 100) {
      enqueueSnackbar("El porcentaje total no puede ser mayor a 100", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      return;
    }
    try {
      setLoadingMDis(true);
      let valid = !valoresModalDis.some(
        (item) =>
          campusSelectedOptions?.value === item.id_campus &&
          escuelasSelectedOptions?.value === item.id_escuela
      );
      const newData = [...valoresModalDis];
      newData.push({
        Campus: campusSelectedOptions?.label,
        Escuela: escuelasSelectedOptions?.label,
        Porcentaje: sumaTotalPorcentaje,
        Distribucion: valoresPorcentaje
          .map((item) => {
            return `${item[0]}% => ${item[1]}`;
          })
          .join(", "),
        Estatus: "Vigente",
        Creado: moment().format("YYYY-MM-DD HH:mm"),
        Creado_por: userName,
        Modificado: "",
        Modificado_por: "",
        id: valoresModalDis.length,
        id_campus: campusSelectedOptions?.value,
        id_escuela: escuelasSelectedOptions?.value,
      });
      const payload = {
        id: valueProceso,
        data: newData,
      };
      if (nroFilas.length === 0) {
        enqueueSnackbar("Se debe de agregar al menos un Porcentaje", {
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
        setLoadingMDis(false);
      } else if (
        campusSelectedOptions?.value === undefined ||
        escuelasSelectedOptions?.value === undefined
      ) {
        enqueueSnackbar("Se debe de agregar una escuela y un campus", {
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
        setLoadingMDis(false);
      } else if (valid) {
        const response = await postRequest(`/parametro_reglas`, payload);
        if (response.s === "OK") {
          enqueueSnackbar(response.m, {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "left",
            },
          });
          GetDataTable(valueProceso);
          setNuevoParam58(false);
          setLoadingMDis(false);
          setNroFilas([]);
          setValoresPorcentaje([]);
          setsumaTotalPorcentaje(null);
          setCampusSelectedOptions("");
          setEscuelasSelectedOptions("");
        } else {
          setLoadingMDis(false);
          enqueueSnackbar(response.m, {
            variant: "error",
            autoHideDuration: null,
            anchorOrigin: {
              vertical: "top",
              horizontal: "left",
            },
          });
        }
      } else {
        enqueueSnackbar(
          "Ya existe un registro para el campus y escuela insertados",
          {
            variant: "error",
            autoHideDuration: null,
            anchorOrigin: {
              vertical: "top",
              horizontal: "left",
            },
          }
        );
        setLoadingMDis(false);
      }
    } catch (error) {}
  };
  const handleSubmitParametro62 = async () => {
    if (
      escuelasSelectedOptions?.value === undefined ||
      tipoGrupoSelectedOptions?.value === undefined ||
      tabuladoresSelectedOptions?.value === undefined
    ) {
      enqueueSnackbar(
        "Se debe de agregar una escuela, un tipo de grupo y un tabulador",
        {
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        }
      );
      return;
    }

    try {
      const newData = [];
      if (nuevoParam62) {
        const valid = !dataFinal.some(
          (item) =>
            escuelasSelectedOptions.value === item.clave_escuela &&
            tipoGrupoSelectedOptions.value === item.tipo_grupo
        );
        if (!valid) {
          enqueueSnackbar(
            "Ya existe un registro para la Escuela y Tipo de Grupo seleccionado",
            {
              variant: "error",
              autoHideDuration: null,
              anchorOrigin: {
                vertical: "top",
                horizontal: "left",
              },
            }
          );
          return;
        }

        newData.push({
          clave_escuela: escuelasSelectedOptions?.value,
          tipo_grupo: tipoGrupoSelectedOptions?.value,
          tabulador: tabuladoresSelectedOptions?.label,
          estatus: "Activo",
          creado: moment().format("YYYY-MM-DD HH:mm"),
          creado_por: userName,
          modificado: "",
          modificado_por: "",
        });
      } else {
        newData.push({
          clave_escuela: escuelasSelectedOptions?.value,
          tipo_grupo: tipoGrupoSelectedOptions?.value,
          tabulador: tabuladoresSelectedOptions?.label,
          estatus: estatusSelectedOptions?.value,
          modificado: `${moment().format("YYYY-MM-DD HH:mm:ss")}`,
          modificado_por: `${userName}`,
        });
      }
      setLoadingMDis(true);

      const payload = {
        id: valueProceso,
        data: newData,
      };
      const response = await postRequest(`/parametro_reglas`, payload);
      if (response.s === "OK") {
        enqueueSnackbar(response.m, {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
        GetDataTable(valueProceso);
        setNuevoParam62(false);
        setEditarParam62(false);
        setEscuelasSelectedOptions("");
        setTabuladoresSelectedOptions("");
        setTipoGrupoSelectedOptions("");
      } else {
        enqueueSnackbar(response.m, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
    } finally {
      setLoadingMDis(false);
    }
  };

  const handleSubmitParametro64 = async () => {
    const values = valoresTipoClase.map((item) => item?.value);
    const duplicados = new Set(values).size !== values.length;
    if (valoresTipoClase.length === 0) {
      enqueueSnackbar("Se debe ingresar al menos una clase especial", {
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      setLoadingMDis(false);
      return;
    }

    if (duplicados) {
      enqueueSnackbar("No se permiten registros duplicados", {
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      return;
    }
    const labelValues = valoresTipoClase
      .map((item) => item?.label)
      .filter((label) => label !== undefined);
    const labelString = labelValues.join(", ");
    dataFinal[0].valor = labelString;
    const json = Object.fromEntries(
      dataFinal.map((item) => [item.nombre, item.valor])
    );

    try {
      setLoadingMDis(true);
      const payload = {
        id: valueProceso,
        data: json,
      };

      const response = await postRequest("/parametro_reglas", payload);

      if (response.s === "OK") {
        enqueueSnackbar(response.m, {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
        setTableEdit(false);
        GetDataTable(valueProceso);
        setEditarParam64(false);
        setNroFilas([]);
        setValoresTipoClase([]);
      } else {
        enqueueSnackbar(response.m, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
    } finally {
      setLoadingMDis(false);
    }
  };

  const handleSubmitParametro66 = async () => {
    const stringValor = valoresTipoClase.map((valor) => valor.label);
    const json = dataFinal.map((item) => {
      if (item.nombre === title.original) {
        return { ...item, valor: stringValor.join(", ") };
      }
      return { ...item };
    });
    try {
      setLoadingMDis(true);
      const payload = {
        id: valueProceso,
        data: {
          tipo_grupo: json[0].valor,
          tipo_persona: json[1].valor,
        },
      };

      const response = await postRequest("/parametro_reglas", payload);

      if (response.s === "OK") {
        enqueueSnackbar(response.m, {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
        setTableEdit(false);
        GetDataTable(valueProceso);
        setEditarParam66(false);
        setNroFilas([]);
        setValoresTipoClase([]);
      } else {
        enqueueSnackbar(response.m, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
    } finally {
      setLoadingMDis(false);
    }
  };

  const handleSubmitParametro50 = async () => {
    let nuevoRegistro = {};

    for (let clave in formDataParam50) {
      if (formDataParam50.hasOwnProperty(clave)) {
        if (formDataParam50[clave] === "") {
          enqueueSnackbar(
            "Se debe de agregar un campo clave finanza, una carrera y un porcentaje",
            {
              variant: "error",
              anchorOrigin: {
                vertical: "top",
                horizontal: "left",
              },
            }
          );
          return;
        }
      }
    }

    if (nuevoParam50) {
      const valid = !dataFinal.some(
        (item) =>
          formDataParam50.clve_camp_fnza === item.clve_camp_fnza &&
          formDataParam50.clve_crra === item.clve_crra
      );
      if (!valid) {
        enqueueSnackbar(
          "Ya existe un registro para el Campo Clave Finanza y la Carrera seleccionada",
          {
            variant: "error",
            autoHideDuration: null,
            anchorOrigin: {
              vertical: "top",
              horizontal: "left",
            },
          }
        );
        return;
      }

      nuevoRegistro = {
        ...formDataParam50,
        estatus: nuevoParam50 ? "Activo" : estatusSelectedOptions,
        creado: moment().format("YYYY-MM-DD HH:mm"),
        creado_por: userName,
        modificado: "",
        modificado_por: "",
      };
      dataFinal.push(nuevoRegistro);
    } else {
      const nuevoRegistro = {
        ...dataFinal[filaEditar],
        ...formDataParam50,
        estatus: estatusSelectedOptions.value,
        modificado: moment().format("YYYY-MM-DD HH:mm:ss"),
        modificado_por: userName,
      };
      dataFinal[filaEditar] = nuevoRegistro;
    }
    try {
      setLoadingMDis(true);
      const payload = {
        id: valueProceso,
        data: dataFinal,
      };
      const response = await postRequest(`/parametro_reglas`, payload);
      if (response.s === "OK") {
        enqueueSnackbar(response.m, {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
        GetDataTable(valueProceso);
        setNuevoParam50(false);
        setEditarParam50(false);
      } else {
        enqueueSnackbar(response.m, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
    } finally {
      setLoadingMDis(false);
      setFormDataParam50({
        clve_camp_fnza: "",
        clve_crra: "",
        porcentaje: "",
        estatus: "Activo",
      });
    }
  };

  const handleSubmitParam10 = async () => {
    try {
      setLoadingMDis(true);

      if (valoresEscuelas.length === 0) {
        enqueueSnackbar("Se debe ingresar al menos una escuela", {
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
        setLoadingMDis(false);
        return;
      }

      const tieneSubarreglosRepetidos = (arr) => {
        const stringArreglos = arr.map(JSON.stringify);
        const setArreglos = new Set(stringArreglos);
        return stringArreglos.length !== setArreglos.size;
      };

      if (tieneSubarreglosRepetidos(valoresEscuelas)) {
        enqueueSnackbar("No se permiten registros duplicados", {
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
        setLoadingMDis(false);
        return;
      }

      const hasEmptyElements = valoresEscuelas.some(
        (element) => element === "" || element === null
      );

      if (hasEmptyElements) {
        enqueueSnackbar("Debe ingresar escuela", {
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
        setLoadingMDis(false);
        return;
      }

      const arregloValores = valoresEscuelas.map((elemento) => {
        const match = elemento.label.match(/\d+/); // Encuentra números en la cadena
        return match ? match[0] : null; // Devuelve el número encontrado o null
      });

      dataFinal[0].valor = dataFinal[0]?.valor?.split(",");
      dataFinal[2].valor = arregloValores;

      const json = Object.fromEntries(
        dataFinal.map((item) => [item.nombre, item.valor])
      );

      const payload = {
        id: valueProceso,
        data: json,
      };

      const response = await postRequest("/parametro_reglas", payload);

      if (response.s === "OK") {
        enqueueSnackbar(response.m, {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
        setTableEdit(false);
        GetDataTable(valueProceso);
        setEditarParam10(false);
        setNroFilas([]);
        setValoresEscuelas([]);
      } else {
        enqueueSnackbar(response.m, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
    } finally {
      setLoadingMDis(false);
    }
  };

  useEffect(() => {
    GetListParametro();
    getSchools().then((schools) => {
      setEscuelasCatalogo(schools);
    });
    getGroupType().then((group) => {
      setGroupTypeList(group);
    });
    getPersonCatalog().then((people) => {
      setPersonList(
        people.map((person) => {
          return {
            label: person.label,
            value: person.clave,
          };
        })
      );
    });
  }, []);

  const handleChangeEscuela52 = (e) => {
    setCreateClaveEscuela52(e.target.value);
  };

  const handleChangeSemanas52 = (e) => {
    const inputValue = e.target.value;
    if (/^\d+$/.test(inputValue)) {
      setCreateSemanas52(inputValue);
    }
  };

  const handleChangeDays63 = (e) => {
    const inputValue = e.target.value;
    if (/^\d+$/.test(inputValue)) {
      setCreateDays63(inputValue);
    } else if (e === "") {
      setCreateDays63("");
    }
  };

  const handleChangeCarrera52 = (e) => {
    setCreateCarrera52(e.target.value);
  };

  const handleChangeModulo52 = (e) => {
    const inputValue = e.target.value;
    if (/^\d+$/.test(inputValue)) {
      setCreateModulo52(inputValue);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormDataParam50((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleOpenModalCreate = (id) => {
    switch (id) {
      case 16:
        setShowCreateModal(true);
        break;
      case 52:
        setShowCreateModalTraduccion(true);
        break;
      case 63:
        setShowCreateModal63(true);
        break;
      default:
        break;
    }
  };

  const handleSubmit63Nuevo = async () => {
    const sameSchool = dataFinal.some(
      (school) =>
        parseInt(school.clave_escuela) === parseInt(createSchool63?.clave)
    );
    if (createSchool63?.value === "" || createSchool63 === "") {
      enqueueSnackbar("Se debe seleccionar una escuela", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    } else if (createDays63 === "") {
      enqueueSnackbar("Se debe insertar los dias", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    } else if (sameSchool) {
      enqueueSnackbar("Ya existe un registro con la escuela selecionada", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    } else {
      const body = [
        ...dataFinal,
        {
          clave_escuela: `${createSchool63?.clave}`,
          dias_previos: parseFloat(createDays63),
          creado: moment().format("YYYY-MM-DD HH:mm"),
          creado_por: `${userName} ${moment().format("YYYY-MM-DD HH:mm:ss")}`,
          modificado_por: `${userName}`,
          modificado: moment().format("YYYY-MM-DD HH:mm"),
          id: dataFinal[dataFinal.length - 1]?.id + 1,
        },
      ];
      const payload = {
        id: valueProceso,
        data: body,
      };
      try {
        setLoadingMDis(true);
        const response = await postRequest("/parametro_reglas", payload);
        if (response.s === "OK") {
          enqueueSnackbar(response.m, {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "left",
            },
          });
          setCreateSchool63("");
          setCreateDays63("");
          setShowCreateModal63(false);
          GetDataTable(valueProceso);
        } else {
          enqueueSnackbar(response.m, {
            variant: "error",
            autoHideDuration: null,
            anchorOrigin: {
              vertical: "top",
              horizontal: "left",
            },
          });
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoadingMDis(false);
      }
    }
  };

  const handleNroFilas = (valor) => {
    const newValor = valor.filter((element) => element?.value !== "");
    setNroFilas(newValor.length ? newValor : [0]);
    setTitle({
      nombre: valor[0].nombre,
      original: valor[0].original,
    });
  };

  const handleValorTipoClase = (valor) => {
    switch (valueProceso) {
      case 66:
        {
          const valorArry = valor.filter((item) => item.value !== "");
          setValoresTipoClase(valorArry.length ? valor : []);
        }

        break;
      case 67:
        {
          const valorArry = valor.map((item) => item.value);
          setValoresTipoClase(
            valorArry.length
              ? groupTypeList.filter((grupo) => valorArry.includes(grupo.value))
              : []
          );
        }
        break;
      default:
        setValoresTipoClase(valor);
        break;
    }
  };

  const handleSubmitParametro67 = async () => {
    const stringValor = valoresTipoClase.map((valor) => valor.value);
    const json = dataFinal.map((item) => {
      if (item.modalidad === title.original) {
        return {
          modalidad: item.modalidad,
          tipos_grupos: stringValor.join(","),
        };
      }
      return { modalidad: item.modalidad, tipos_grupos: item.tipos_grupos };
    });
    try {
      setLoadingMDis(true);
      const payload = {
        id: valueProceso,
        data: json,
      };
      const response = await postRequest("/parametro_reglas", payload);

      if (response.s === "OK") {
        enqueueSnackbar(response.m, {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
        setTableEdit(false);
        GetDataTable(valueProceso);
        setEditarParam67(false);
        setNroFilas([]);
        setValoresTipoClase([]);
      } else {
        enqueueSnackbar(response.m, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
    } finally {
      setLoadingMDis(false);
    }
  };

  return (
    <Contenedor title="Configuración / Parámetros Internos del Sistema">
      <CustomSpinner visible={loading} />
      <Grid container style={{ marginBottom: 20, marginTop: 10 }}>
        <Grid
          container
          item
          xs={12}
          spacing={2}
          style={{
            justifyContent: "space-around",
          }}
        >
          <Grid
            container
            spacing={2}
            style={{ width: "100%", display: "flex", alignItems: "center" }}
          >
            <Grid xs={6} sm={1} item>
              <label>Proceso(*):</label>
            </Grid>
            <Grid xs={6} sm={5} item style={{ padding: 2, zIndex: 99 }}>
              <Select
                className="select-modal-container input-campus"
                classNamePrefix="select-modal"
                value={valueProceso.value}
                defaultValue=""
                options={optionsProceso}
                onChange={(e) => {
                  setTableEdit(false);
                  GetDataTable(e.value);
                }}
              />
            </Grid>
            {showTable && (
              <>
                <Grid item xs={12} sm={4} style={{ paddingLeft: "24px" }}>
                  <FormGroup>
                    <FormControlLabel
                      style={{
                        width: 80,
                        display:
                          permisos["Configuración->Parámetros Internos"]
                            .modificar === "1"
                            ? "block"
                            : "none",
                      }}
                      control={
                        <Switch
                          checked={tableEdit}
                          onChange={handleClickEditTable}
                        />
                      }
                      label="Editar"
                    />
                  </FormGroup>
                </Grid>
                {valueProceso === 58 && (
                  <Grid item xs={12} sm={2} style={{ textAlign: "end" }}>
                    <NewButton
                      customText="Crear"
                      noIcon
                      style={{ padding: "5px 15px" }}
                      onClick={() => {
                        setLoadingMDis(true);
                        setNuevoParam58(true);

                        Promise.all([getCampusCatalog(), getSchools()])
                          .then(([campusData, schoolsData]) => {
                            setCampusCatalogo(campusData);
                            setEscuelasCatalogo(schoolsData);
                          })
                          .catch((error) => {
                            setNuevoParam58(false);
                          })
                          .finally(() => {
                            setLoadingMDis(false);
                          });
                      }}
                    />
                  </Grid>
                )}
                {valueProceso === 50 && (
                  <Grid item xs={12} sm={2} style={{ textAlign: "end" }}>
                    <NewButton
                      customText="Crear"
                      noIcon
                      style={{ padding: "5px 15px" }}
                      onClick={() => {
                        setNuevoParam50(true);
                      }}
                    />
                  </Grid>
                )}
                {valueProceso === 62 && (
                  <Grid item xs={12} sm={2} style={{ textAlign: "end" }}>
                    <NewButton
                      customText="Crear"
                      noIcon
                      style={{ padding: "5px 15px" }}
                      onClick={() => {
                        setLoadingMDis(true);
                        setNuevoParam62(true);

                        Promise.all([
                          getSchoolsClave(),
                          getGroupType(),
                          getTabulators(),
                        ])
                          .then(
                            ([schoolsData, groupTypeData, tabulatorsData]) => {
                              setEscuelasCatalogo(schoolsData);
                              setTipoGrupoCatalogo(groupTypeData);
                              setTabuladoresCatalogo(tabulatorsData);
                            }
                          )
                          .catch((error) => {
                            setNuevoParam62(false);
                          })
                          .finally(() => {
                            setLoadingMDis(false);
                          });
                      }}
                    />
                  </Grid>
                )}
                {valueProceso === 60 && (
                  <Grid item xs={12} sm={2} style={{ textAlign: "end" }}>
                    <NewButton
                      customText="Crear"
                      noIcon
                      style={{ padding: "5px 15px" }}
                      onClick={() => {
                        setLoadingMDis(true);
                        setNuevoParam60(true);
                        setLoadingMDis(false);
                      }}
                    />
                  </Grid>
                )}
              </>
            )}
            {showTable &&
              (valueProceso === 16 ||
                valueProceso === 52 ||
                valueProceso === 63) && (
                <Grid
                  item
                  xs={12}
                  sm={2}
                  style={{ textAlign: "-webkit-right" }}
                >
                  <NewButton
                    customText="Crear"
                    noIcon
                    style={{
                      padding: "5px 15px",
                      display:
                        permisos["Configuración->Divisionales"].escribir === "1"
                          ? "block"
                          : "none",
                    }}
                    disabled={false}
                    onClick={() => handleOpenModalCreate(valueProceso)}
                  />
                </Grid>
              )}
          </Grid>
        </Grid>
      </Grid>

      {showTable && (
        <Grid>
          <CustomTable
            data={loading ? [] : convertDateForParameters(dataFinal)}
            headers={columns}
            loading={loading}
            isEditarDisabled={tableEdit}
            onEditingRowSave={handleSaveRow}
            isDeleteButton={false}
            setEditarParam50={setEditarParam50}
            setFormDataParam50={setFormDataParam50}
            setEditarParam58={setEditarParam58}
            setEditarParam62={setEditarParam62}
            setEditarParam64={setEditarParam64}
            setEditarParam66={setEditarParam66}
            setValoresTipoClase={handleValorTipoClase}
            setLoadingMDis={setLoadingMDis}
            setEscuelasSelectedOptions={setEscuelasSelectedOptions}
            setTipoGrupoSelectedOptions={setTipoGrupoSelectedOptions}
            setTabuladoresSelectedOptions={setTabuladoresSelectedOptions}
            setEstatusSelectedOptions={setEstatusSelectedOptions}
            setEscuelasCatalogo={setEscuelasCatalogo}
            setTipoGrupoCatalogo={setTipoGrupoCatalogo}
            setTabuladoresCatalogo={setTabuladoresCatalogo}
            setFilaEditar={setFilaEditar}
            setValoresPorcentaje={setValoresPorcentaje}
            valoresModalDis={valoresModalDis}
            setNroFilas={handleNroFilas}
            valueProceso={valueProceso}
            setEditarParam67={setEditarParam67}
          />
          {valueProceso === 10 && tableEdit && (
            <NewButton
              customText="Editar"
              noIcon
              style={{
                padding: "5px 15px",
                position: "absolute",
                marginTop: "-138px",
                left: "45px",
              }}
              onClick={async () => {
                setEditarParam10(true);
                setLoadingMDis(true);
                const data = await getSchools();
                setEscuelasCatalogo(data);
                const filaEditar10 = dataFinal.find(
                  (objeto) =>
                    objeto.nombre === "excepcion_total_horas_por_escuela"
                );
                const arreglo = filaEditar10.valor.split(",");
                setNroFilas(arreglo);

                const valoresFiltrados = data.reduce((result, elemento) => {
                  if (arreglo.some((label) => elemento.label.includes(label))) {
                    result.push(elemento);
                  }
                  return result;
                }, []);

                setValoresEscuelas(valoresFiltrados);
                setLoadingMDis(false);
              }}
            />
          )}
          <Grid
            container
            style={{
              marginBottom: 40,
              alignItems: "baseline",
              justifyContent: "space-between",
            }}
          >
            <Grid item>Creado:</Grid>
            <Grid item xs={3}>
              <input
                className="filter-input"
                value={creadoYModificado.creado_por}
                disabled
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={2}>
              <input
                className="filter-input"
                value={moment(creadoYModificado.creado).format(
                  "YYYY-MM-DD HH:mm:ss"
                )}
                disabled
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item> Modificado:</Grid>
            <Grid item xs={3}>
              <input
                className="filter-input"
                value={creadoYModificado.modificado_por}
                disabled
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={2}>
              <input
                className="filter-input"
                value={
                  creadoYModificado.modificado !== null
                    ? moment(creadoYModificado.modificado).format(
                        "YYYY-MM-DD HH:mm:ss"
                      )
                    : ""
                }
                disabled
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      )}
      {showCreateModal && (
        <NewModal
          title={`Crear Día Festivo`}
          open={showCreateModal}
          handleClose={handleCloseCreateModal}
          handleSubmit={handleSubmitCreateModal}
        >
          <CreateInternalParametersModal
            setName={setCreateName}
            setDay={setCreateDay}
            setMonth={setCreateMonth}
            setPayble={setCreatePayble}
            setAssistanceType={setCreateAssistanceType}
            setCurrent={setCreateCurrent}
          />
        </NewModal>
      )}
      <NewModal
        title="Configurar Distribución"
        open={nuevoParam58}
        handleClose={() => {
          setNuevoParam58(false);
          setEstatus("");
          setLoadingMDis(false);
          setNroFilas([]);
          setValoresPorcentaje([]);
          setCampusSelectedOptions("");
          setEscuelasSelectedOptions("");
        }}
        height={600}
        maxWidth={700}
        loading={loadingMDis}
        overflowY={"auto"}
        handleSubmit={handleSubmitDisNuevo}
      >
        <div style={{ padding: "25px" }}>
          <Grid
            container
            spacing={1}
            style={{
              marginBottom: "30px",
              alignItems: "center",
              justifyContent: "space-evenly",
            }}
          >
            <Grid item xs={3}>
              <label className={styles.inputLabel}>Campus:</label>
            </Grid>
            <Grid item xs={8}>
              <Select
                id="campus"
                className="select-modal-container"
                classNamePrefix="select-modal"
                style={{ width: "200px" }}
                options={[...campusCatalogo]}
                value={campusSelectedOptions}
                onChange={setCampusSelectedOptions}
                isClearable={true}
              />
            </Grid>

            <Grid item xs={3}>
              <label className={styles.inputLabel}>Escuela:</label>
            </Grid>
            <Grid item xs={8}>
              <Select
                id="campus"
                className="select-modal-container"
                classNamePrefix="select-modal"
                style={{ width: "200px" }}
                options={[...escuelasCatalogo]}
                value={escuelasSelectedOptions}
                onChange={setEscuelasSelectedOptions}
                isClearable={true}
              />
            </Grid>

            <Grid item xs={3}>
              <label className={styles.inputLabel}>Porcentaje:</label>
            </Grid>
            <Grid item xs={8}>
              <input
                className={styles.filterInput}
                value={
                  sumaTotalPorcentaje || valoresModalDis[filaEditar]?.Porcentaje
                }
                disabled
              />
            </Grid>

            <Grid item xs={3}>
              <label className={styles.inputLabel}>Estatus:</label>
            </Grid>
            <Grid item xs={8}>
              <input
                className={styles.filterInput}
                value={"Vigente"}
                disabled
              />
            </Grid>
          </Grid>
        </div>
        <Grid
          item
          xs={12}
          className="consult-tab-option_center"
          style={{
            marginTop: "-4%",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "right",
              marginBottom: "2%",
              marginRight: "5%",
              height: "30px",
            }}
          >
            <EditButton onClick={() => agregarNuevaFila(nroFilas)}>
              Agregar
            </EditButton>
          </div>
          <div>
            <table
              className="input-table"
              style={{ width: "78%", marginTop: "1%" }}
            >
              <tr>
                <th>Porcentaje Parcial</th>
                <th>Porcentaje a Otorgar</th>
              </tr>
              {nroFilas.map((e, i) => (
                <tr key={i}>
                  <td>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <input
                        className="filter-input"
                        style={{ width: "35%" }}
                        type="number"
                        min={0}
                        max={100}
                        value={valoresPorcentaje?.[i]?.[0]}
                        onChange={(e) => {
                          const nuevosValores = [...valoresPorcentaje];
                          nuevosValores[i][0] = e.target.value;

                          setValoresPorcentaje(nuevosValores);
                        }}
                      />
                      <span>%</span>
                    </div>
                  </td>
                  <td>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <input
                        className="filter-input"
                        type="number"
                        style={{ width: "35%" }}
                        min={0}
                        max={100}
                        value={valoresPorcentaje?.[i]?.[1]}
                        onChange={(e) => {
                          const nuevosValores = [...valoresPorcentaje];
                          nuevosValores[i][1] = e.target.value;
                          setValoresPorcentaje(nuevosValores);
                        }}
                      />
                      <span>%</span>
                    </div>
                  </td>
                  <td>
                    <div
                      className="table-delete"
                      style={{ marginLeft: "38.5%", zIndex: 99 }}
                      onClick={() => deleteRow(i)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                        />
                      </svg>
                    </div>
                  </td>
                </tr>
              ))}
            </table>
          </div>
        </Grid>
      </NewModal>
      <NewModal
        title="Configurar Tipos de Grupos por Tabulador"
        open={nuevoParam62 || editarParam62}
        handleClose={() => {
          setNuevoParam62(false);
          setEditarParam62(false);
          setEscuelasSelectedOptions("");
          setTipoGrupoSelectedOptions("");
          setTabuladoresSelectedOptions("");
        }}
        height={200}
        maxWidth={700}
        loading={loadingMDis}
        overflowY={"auto"}
        handleSubmit={handleSubmitParametro62}
      >
        <div style={{ padding: "25px" }}>
          <Grid
            container
            spacing={1}
            style={{
              alignItems: "center",
              position: "absolute",
              zIndex: 998,
            }}
          >
            <Grid item xs={3}>
              <label className={styles.inputLabel}>Escuela:</label>
            </Grid>
            <Grid item xs={8} style={{ zIndex: 1000 }}>
              <Select
                id="escuela"
                className="select-modal-container"
                classNamePrefix="select-modal"
                style={{ width: "200px" }}
                options={[...escuelasCatalogo]}
                value={escuelasSelectedOptions}
                onChange={setEscuelasSelectedOptions}
                isClearable={true}
              />
            </Grid>

            <Grid item xs={3}>
              <label className={styles.inputLabel}>Tipo de Grupo:</label>
            </Grid>
            <Grid item xs={8}>
              <Select
                id="tipo-grupo"
                className="select-modal-container"
                classNamePrefix="select-modal"
                style={{ width: "200px" }}
                options={[...tipoGrupoCatalogo]}
                value={tipoGrupoSelectedOptions}
                onChange={setTipoGrupoSelectedOptions}
                isClearable={true}
              />
            </Grid>

            <Grid item xs={3}>
              <label className={styles.inputLabel}>Tabulador:</label>
            </Grid>
            <Grid item xs={8}>
              <Select
                id="tabulador"
                className="select-modal-container"
                classNamePrefix="select-modal"
                style={{ width: "200px" }}
                options={[...tabuladoresCatalogo]}
                value={tabuladoresSelectedOptions}
                onChange={setTabuladoresSelectedOptions}
                isClearable={true}
              />
            </Grid>

            <Grid item xs={3}>
              <label className={styles.inputLabel}>Estatus:</label>
            </Grid>
            <Grid item xs={8}>
              {nuevoParam62 ? (
                <input
                  className={styles.filterInput}
                  value={"Activo"}
                  disabled
                />
              ) : (
                <Select
                  id="estatus"
                  className="select-modal-container"
                  classNamePrefix="select-modal"
                  style={{ width: "200px" }}
                  options={[
                    { value: "Activo", label: "Activo" },
                    { value: "Inactivo", label: "Inactivo" },
                  ]}
                  value={estatusSelectedOptions}
                  onChange={setEstatusSelectedOptions}
                  isClearable={true}
                />
              )}
            </Grid>
          </Grid>
        </div>
      </NewModal>
      <NewModal
        title="Configuración Clases Especiales para Proyecciones"
        open={editarParam64}
        handleClose={() => {
          setEditarParam64(false);
        }}
        maxWidth={800}
        height={600}
        loading={loadingMDis}
        overflowY={"auto"}
        handleSubmit={handleSubmitParametro64}
      >
        <Grid
          item
          xs={12}
          className="consult-tab-option_center"
          style={{ margin: "2% 0" }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "right",
              marginBottom: "2%",
              marginRight: "5%",
              height: "30px",
            }}
          >
            <EditButton onClick={() => agregarNuevaFila(nroFilas)}>
              Agregar
            </EditButton>
          </div>
          <div>
            <table
              className="input-table"
              style={{ width: "78%", marginTop: "1%" }}
            >
              <tr>
                <th>Clase Especial</th>
              </tr>
              {nroFilas.map((e, i) => (
                <tr key={i}>
                  <td>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Select
                        id="proyecciones"
                        className="select-modal-container"
                        classNamePrefix="select-modal"
                        styles={{
                          menuList: () => ({
                            overflowY: "visible",
                          }),
                        }}
                        options={[...tipoClaseCatalogo]}
                        value={valoresTipoClase?.[i]}
                        onChange={(e) => {
                          const nuevosValores = [...valoresTipoClase];
                          nuevosValores[i] = e;
                          setValoresTipoClase(nuevosValores);
                        }}
                        isClearable={true}
                      />
                    </div>
                  </td>

                  <td>
                    <div
                      className="table-delete"
                      style={{ marginInline: "auto", zIndex: 99 }}
                      onClick={() => deleteRow(i)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                        />
                      </svg>
                    </div>
                  </td>
                </tr>
              ))}
            </table>
          </div>
        </Grid>
      </NewModal>
      <NewModal
        title={`Configuración ${title.nombre} No Envia a Nómina`}
        open={editarParam66}
        handleClose={() => {
          setEditarParam66(false);
        }}
        maxWidth={800}
        height={600}
        loading={loadingMDis}
        overflowY={"auto"}
        handleSubmit={handleSubmitParametro66}
      >
        <Grid
          item
          xs={12}
          className="consult-tab-option_center"
          style={{ margin: "2% 0" }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "right",
              marginBottom: "2%",
              marginRight: "5%",
              height: "30px",
            }}
          >
            <EditButton onClick={() => agregarNuevaFila(nroFilas)}>
              Agregar
            </EditButton>
          </div>
          <div>
            <table
              className="input-table"
              style={{ width: "78%", marginTop: "1%" }}
            >
              <tr>
                <th>{title.nombre}</th>
              </tr>
              {nroFilas.map((e, i) => (
                <tr key={i}>
                  <td>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Select
                        id="proyecciones"
                        className="select-modal-container"
                        classNamePrefix="select-modal"
                        styles={{
                          maxWidth: 604,
                          menu: (styles) => ({
                            ...styles,
                            zIndex: 999,
                          }),
                          indicatorsContainer: (styles) => ({
                            ...styles,
                          }),
                        }}
                        options={
                          title.nombre === "Tipo Grupo"
                            ? [
                                ...groupTypeList.filter(
                                  (group) =>
                                    !valoresTipoClase.some(
                                      (clase) => clase.value === group.value
                                    )
                                ),
                              ]
                            : [
                                ...personList.filter(
                                  (person) =>
                                    !valoresTipoClase.some(
                                      (clase) => clase.value === person.value
                                    )
                                ),
                              ]
                        }
                        value={valoresTipoClase?.[i]}
                        onChange={(e) => {
                          const nuevosValores = [...valoresTipoClase];
                          nuevosValores[i] = e;
                          setValoresTipoClase(nuevosValores);
                        }}
                      />
                    </div>
                  </td>

                  <td>
                    <div
                      className="table-delete"
                      style={{ marginInline: "auto", zIndex: 99 }}
                      onClick={() => deleteRow(i)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                        />
                      </svg>
                    </div>
                  </td>
                </tr>
              ))}
            </table>
          </div>
        </Grid>
      </NewModal>
      <NewModal
        title="Configurar Claves Campus Planes Competencia"
        open={nuevoParam50 || editarParam50}
        handleClose={() => {
          setNuevoParam50(false);
          setEditarParam50(false);
          setFormDataParam50({
            clve_camp_fnza: "",
            clve_crra: "",
            porcentaje: "",
            estatus: "Activo",
          });
        }}
        height={200}
        maxWidth={750}
        loading={loadingMDis}
        overflowY={"auto"}
        handleSubmit={handleSubmitParametro50}
      >
        <div style={{ padding: "25px" }}>
          <Grid
            container
            spacing={1}
            style={{
              alignItems: "center",
              position: "absolute",
              zIndex: 998,
            }}
          >
            <Grid item xs={3}>
              <label className={styles.inputLabel}>Clave Campo Finanza:</label>
            </Grid>
            <Grid item xs={8} style={{ zIndex: 1000 }}>
              <input
                className={styles.filterInput}
                name="clve_camp_fnza"
                value={formDataParam50.clve_camp_fnza}
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={3}>
              <label className={styles.inputLabel}>Clave Carrera:</label>
            </Grid>
            <Grid item xs={8}>
              <input
                className={styles.filterInput}
                name="clve_crra"
                value={formDataParam50.clve_crra}
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={3}>
              <label className={styles.inputLabel}>Porcentaje:</label>
            </Grid>
            <Grid item xs={8}>
              <input
                className={styles.filterInput}
                type="number"
                min={0}
                name="porcentaje"
                value={formDataParam50.porcentaje}
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={3}>
              <label className={styles.inputLabel}>Estatus:</label>
            </Grid>
            <Grid item xs={8}>
              {nuevoParam50 ? (
                <input
                  className={styles.filterInput}
                  value={"Activo"}
                  disabled
                />
              ) : (
                <Select
                  id="estatus"
                  className="select-modal-container"
                  classNamePrefix="select-modal"
                  style={{ width: "200px" }}
                  options={[
                    { value: "Activo", label: "Activo" },
                    { value: "Inactivo", label: "Inactivo" },
                  ]}
                  value={estatusSelectedOptions}
                  onChange={setEstatusSelectedOptions}
                  isClearable={true}
                />
              )}
            </Grid>
          </Grid>
        </div>
      </NewModal>

      <NewModal
        title="Configurar Escuelas a Excluir"
        open={editarParam10}
        handleClose={() => {
          setEditarParam10(false);
          setLoadingMDis(false);
          setNroFilas([]);
          setValoresEscuelas([]);
          setEscuelasCatalogo([]);
        }}
        height={600}
        maxWidth={700}
        loading={loadingMDis}
        overflowY={"auto"}
        handleSubmit={handleSubmitParam10}
      >
        <Grid
          item
          xs={12}
          className="consult-tab-option_center"
          style={{
            marginTop: "2%",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "right",
              marginBottom: "2%",
              marginRight: "5%",
              height: "30px",
            }}
          >
            <EditButton onClick={() => agregarNuevaFila(nroFilas)}>
              Agregar
            </EditButton>
          </div>
          <div>
            <table
              className="input-table"
              style={{ width: "78%", marginTop: "1%" }}
            >
              <tr>
                <th>Escuela</th>
              </tr>
              {nroFilas.map((e, i) => (
                <tr key={i}>
                  <td>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Select
                        id="campus"
                        className="select-modal-container"
                        classNamePrefix="select-modal"
                        style={{ width: "90%" }}
                        options={[...escuelasCatalogo]}
                        value={valoresEscuelas?.[i]}
                        onChange={(e) => {
                          const nuevosValores = [...valoresEscuelas];
                          nuevosValores[i] = e;
                          setValoresEscuelas(nuevosValores);
                        }}
                        isClearable={true}
                      />
                    </div>
                  </td>

                  <td>
                    <div
                      className="table-delete"
                      style={{ marginInline: "auto", zIndex: 99 }}
                      onClick={() => deleteRow(i)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                        />
                      </svg>
                    </div>
                  </td>
                </tr>
              ))}
            </table>
          </div>
        </Grid>
      </NewModal>

      <NewModal
        title="Configuración Horas Semanales OnLine"
        open={showCreateModalTraduccion}
        handleClose={() => {
          setCreateClaveEscuela52("");
          setCreateSemanas52("");
          setCreateCarrera52("");
          setCreateModulo52("");
          setShowCreateModalTraduccion(false);
          setLoadingMDis(false);
        }}
        height={250}
        maxWidth={700}
        overflowY={"auto"}
        loading={loadingMDis}
        handleSubmit={handleSubmit52Nuevo}
      >
        <div style={{ padding: "25px" }}>
          <Grid
            container
            spacing={1}
            style={{
              marginBottom: "30px",
              alignItems: "center",
              justifyContent: "space-evenly",
            }}
          >
            <Grid item xs={3}>
              <label className={styles.inputLabel}>Clave Escuela:</label>
            </Grid>
            <Grid item xs={8}>
              <input
                className={styles.filterInput}
                onChange={(e) => {
                  handleChangeEscuela52(e);
                }}
                value={createClaveEscuela52}
              />
            </Grid>

            <Grid item xs={3}>
              <label className={styles.inputLabel}>Número Semanas:</label>
            </Grid>
            <Grid item xs={8}>
              <input
                className={styles.filterInput}
                onChange={(e) => {
                  handleChangeSemanas52(e);
                }}
                value={createSemanas52}
              />
            </Grid>

            <Grid item xs={3}>
              <label className={styles.inputLabel}>Clave Carrera:</label>
            </Grid>
            <Grid item xs={8}>
              <input
                className={styles.filterInput}
                onChange={(e) => {
                  handleChangeCarrera52(e);
                }}
                value={createCarrera52}
              />
            </Grid>

            <Grid item xs={3}>
              <label className={styles.inputLabel}>Horas Módulo:</label>
            </Grid>
            <Grid item xs={8}>
              <input
                className={styles.filterInput}
                onChange={(e) => {
                  handleChangeModulo52(e);
                }}
                value={createModulo52}
              />
            </Grid>
          </Grid>
        </div>
      </NewModal>
      <NewModal
        title="Configuración Días Previos para Actualizar Salón"
        open={showCreateModal63}
        handleClose={() => {
          setCreateSchool63("");
          setCreateDays63("");
          setShowCreateModal63(false);
        }}
        height={250}
        maxWidth={800}
        loading={loadingMDis}
        handleSubmit={handleSubmit63Nuevo}
        overflowY={"unset"}
      >
        <div style={{ padding: "25px" }}>
          <Grid
            container
            spacing={1}
            style={{
              marginBottom: "30px",
              alignItems: "center",
              justifyContent: "space-evenly",
            }}
          >
            <Grid item xs={3}>
              <label className={styles.inputLabel}>Clave Escuela:</label>
            </Grid>
            <Grid item xs={8}>
              <Select
                id="campus"
                className="select-modal-container"
                classNamePrefix="select-modal"
                style={{ width: "200px" }}
                options={[...escuelasCatalogo]}
                value={createSchool63}
                onChange={setCreateSchool63}
                isClearable={true}
              />
            </Grid>

            <Grid item xs={3}>
              <label className={styles.inputLabel}>Días Previos:</label>
            </Grid>
            <Grid item xs={8}>
              <input
                className={styles.filterInput}
                onChange={(e) => {
                  handleChangeDays63(e);
                }}
                value={createDays63}
              />
            </Grid>
          </Grid>
        </div>
      </NewModal>
      <NewModal
        title="Configurar Distribución"
        open={editarParam58}
        handleClose={() => {
          setEditarParam58(false);
          setFilaEditar(null);
          setEstatus("");
          setLoadingMDis(false);
        }}
        height={600}
        maxWidth={700}
        loading={loadingMDis}
        overflowY={"auto"}
        handleSubmit={handleSubmitDis}
      >
        <div style={{ padding: "25px" }}>
          <Grid
            container
            spacing={1}
            style={{
              marginBottom: "30px",
              alignItems: "center",
              justifyContent: "space-evenly",
            }}
          >
            <Grid item xs={3}>
              <label className={styles.inputLabel}>Campus:</label>
            </Grid>
            <Grid item xs={8}>
              <input
                className={styles.filterInput}
                value={valoresModalDis[filaEditar]?.Campus}
                disabled
              />
            </Grid>

            <Grid item xs={3}>
              <label className={styles.inputLabel}>Escuela:</label>
            </Grid>
            <Grid item xs={8}>
              <input
                className={styles.filterInput}
                value={valoresModalDis[filaEditar]?.Escuela}
                disabled
              />
            </Grid>

            <Grid item xs={3}>
              <label className={styles.inputLabel}>Porcentaje:</label>
            </Grid>
            <Grid item xs={8}>
              <input
                className={styles.filterInput}
                value={
                  sumaTotalPorcentaje || valoresModalDis[filaEditar]?.Porcentaje
                }
                disabled
              />
            </Grid>

            <Grid item xs={3}>
              <label className={styles.inputLabel}>Estatus:</label>
            </Grid>
            <Grid item xs={8}>
              <Select
                classNamePrefix="select-modal"
                options={estatusOptions}
                styles={{
                  container: (styles) => ({ ...styles, zIndex: 19 }),
                  singleValue: (styles) => ({ ...styles, textAlign: "center" }),
                }}
                onChange={setEstatus}
                value={
                  estatus || {
                    label: valoresModalDis[filaEditar]?.Estatus,
                    value:
                      valoresModalDis[filaEditar]?.Estatus === "Vigente"
                        ? "vigente"
                        : "no vigente",
                  }
                }
              />
            </Grid>
          </Grid>
        </div>
        <Grid
          item
          xs={12}
          className="consult-tab-option_center"
          style={{
            marginTop: "-4%",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "right",
              marginBottom: "2%",
              marginRight: "5%",
              height: "30px",
            }}
          >
            <EditButton onClick={() => agregarNuevaFila(nroFilas)}>
              Agregar
            </EditButton>
          </div>
          <div>
            <table
              className="input-table"
              style={{ width: "78%", marginTop: "1%" }}
            >
              <tr>
                <th>Porcentaje Parcial</th>
                <th>Porcentaje a Otorgar</th>
              </tr>
              {nroFilas.map((e, i) => (
                <tr>
                  <td>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <input
                        className="filter-input"
                        style={{ width: "35%" }}
                        type="number"
                        min={0}
                        max={100}
                        value={valoresPorcentaje?.[i]?.[0]}
                        onChange={(e) => {
                          const nuevosValores = [...valoresPorcentaje];
                          nuevosValores[i][0] = e.target.value;

                          setValoresPorcentaje(nuevosValores);
                        }}
                      />
                      <span>%</span>
                    </div>
                  </td>
                  <td>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <input
                        className="filter-input"
                        type="number"
                        style={{ width: "35%" }}
                        min={0}
                        max={100}
                        value={valoresPorcentaje?.[i]?.[1]}
                        onChange={(e) => {
                          const nuevosValores = [...valoresPorcentaje];
                          nuevosValores[i][1] = e.target.value;
                          setValoresPorcentaje(nuevosValores);
                        }}
                      />
                      <span>%</span>
                    </div>
                  </td>
                  <td>
                    <div
                      className="table-delete"
                      style={{ marginLeft: "38.5%", zIndex: 99 }}
                      onClick={() => deleteRow(i)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                        />
                      </svg>
                    </div>
                  </td>
                </tr>
              ))}
            </table>
          </div>
        </Grid>
      </NewModal>

      <NewModal
        title="Configurar Excepción Día Última Fecha Clase"
        open={nuevoParam60}
        handleClose={() => {
          setNuevoParam60(false);
          setLoadingMDis(false);
          setDataModalParam60([]);
        }}
        height={200}
        maxWidth={800}
        loading={loadingMDis}
        overflowY={"auto"}
        handleSubmit={handleSubmitParam60Nuevo}
      >
        <div style={{ padding: "25px" }}>
          <Grid
            container
            spacing={1}
            style={{
              marginBottom: "30px",
              alignItems: "center",
              justifyContent: "space-evenly",
            }}
          >
            <Grid item xs={3}>
              <label className={styles.inputLabel}>Clave Escuela:</label>
            </Grid>
            <Grid item xs={8}>
              <input
                className={styles.filterInput}
                value={dataModalParam60[0] || ""}
                onChange={(e) => handleInputChange(0, e)}
              />
            </Grid>

            <Grid item xs={3}>
              <label className={styles.inputLabel}>Clave Grupo:</label>
            </Grid>
            <Grid item xs={8}>
              <input
                className={styles.filterInput}
                value={dataModalParam60[1] || ""}
                onChange={(e) => handleInputChange(1, e)}
              />
            </Grid>

            <Grid item xs={3}>
              <label className={styles.inputLabel}>Días:</label>
            </Grid>
            <Grid item xs={8}>
              <input
                className={styles.filterInput}
                value={dataModalParam60[2] || ""}
                onChange={(e) => handleInputChange(2, e)}
              />
            </Grid>
          </Grid>
        </div>
      </NewModal>
      <NewModal
        title={`Configuración Tipo Grupo No Contabilizan Horas`}
        open={editarParam67}
        handleClose={() => {
          setEditarParam67(false);
        }}
        maxWidth={800}
        height={600}
        loading={loadingMDis}
        overflowY={"auto"}
        handleSubmit={handleSubmitParametro67}
      >
        <Grid
          item
          xs={12}
          className="consult-tab-option_center"
          style={{ margin: "2% 0" }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "right",
              marginBottom: "2%",
              marginRight: "5%",
              height: "30px",
            }}
          >
            <EditButton onClick={() => agregarNuevaFila(nroFilas)}>
              Agregar
            </EditButton>
          </div>
          <div>
            <table
              className="input-table"
              style={{ width: "78%", marginTop: "1%" }}
            >
              <tr>
                <th>Tipo de Grupo</th>
              </tr>
              {nroFilas.map((e, i) => (
                <tr key={i}>
                  <td>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Select
                        id="proyecciones"
                        className="select-modal-container"
                        classNamePrefix="select-modal"
                        styles={{
                          maxWidth: 604,
                          menu: (styles) => ({
                            ...styles,
                            zIndex: 999,
                          }),
                          indicatorsContainer: (styles) => ({
                            ...styles,
                          }),
                        }}
                        options={groupTypeList.filter(
                          (group) =>
                            !valoresTipoClase.some(
                              (clase) => clase.value === group.value
                            )
                        )}
                        value={valoresTipoClase?.[i]}
                        onChange={(e) => {
                          const nuevosValores = [...valoresTipoClase];
                          nuevosValores[i] = e;
                          setValoresTipoClase(nuevosValores);
                        }}
                      />
                    </div>
                  </td>

                  <td>
                    <div
                      className="table-delete"
                      style={{ marginInline: "auto", zIndex: 99 }}
                      onClick={() => deleteRow(i)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                        />
                      </svg>
                    </div>
                  </td>
                </tr>
              ))}
            </table>
          </div>
        </Grid>
      </NewModal>
    </Contenedor>
  );
};

export default ParametrosInternos;
