export const headersPDF = [
  {
    name: "Dirección",
    col: 0,
    id: "director_academico",
    width: "20%",
    align: "center",
    fn: null,
  },
  {
    name: "Clv Prof",
    col: 1,
    id: "clave_profesor",
    width: "5%",
    align: "center",
    fn: null,
  },
  {
    name: "Nombre Profesor",
    col: 2,
    id: "profesor",
    width: "20%",
    align: "center",
    fn: null,
  },

  {
    name: "Grupo",
    col: 3,
    id: "grupo",
    width: "8%",
    align: "center",
    fn: null,
  },
  {
    name: "Clave Materia",
    col: 4,
    id: "clave_materia",
    width: "8%",
    align: "center",
    fn: null,
  },
  {
    name: "Nombre Materia",
    col: 5,
    id: "materia",
    width: "10%",
    align: "center",
    fn: null,
  },
  {
    name: "Salón",
    col: 6,
    id: "salon",
    width: "5%",
    align: "center",
    fn: null,
  },
  {
    name: "Tipo",
    col: 7,
    id: "tipo",
    width: "8%",
    align: "center",
    fn: null,
  },
  {
    name: "Estatus",
    col: 8,
    id: "estatus",
    width: "8%",
    align: "center",
    fn: null,
  },

  {
    name: "Hora Reg",
    col: 9,
    id: "hora_reg",
    width: "10%",
    align: "center",
    fn: null,
  },
  {
    name: "Horario",
    col: 10,
    id: "horario",
    width: "10%",
    align: "center",
    fn: null,
  },
  {
    name: "Aut",
    col: 11,
    id: "aut",
    width: "5%",
    align: "center",
    fn: null,
  },
  {
    name: "Can",
    col: 12,
    id: "can",
    width: "5%",
    align: "center",
    fn: null,
  },
  {
    name: "Reg",
    col: 13,
    id: "reg",
    width: "5%",
    align: "center",
    fn: null,
  },
];

export const headers = [
  {
    name: "Dirección",
    col: 0,
    id: "direccion",
    width: "20%",
    align: "center",
    fn: null,
  },
  {
    name: "Clv Prof",
    col: 1,
    id: "clave_profesor",
    width: "5%",
    align: "center",
    fn: null,
  },
  {
    name: "Nombre Profesor",
    col: 2,
    id: "profesor",
    width: "20%",
    align: "center",
    fn: null,
  },

  {
    name: "Grupo",
    col: 3,
    id: "grupo",
    width: "5%",
    align: "center",
    fn: null,
  },
  {
    name: "CLV MAT",
    col: 4,
    id: "clave_materia",
    width: "4%",
    align: "center",
    fn: null,
  },
  {
    name: "Nombre Materia",
    col: 5,
    id: "materia",
    width: "10%",
    align: "center",
    fn: null,
  },
  {
    name: "Salón",
    col: 6,
    id: "salon",
    width: "5%",
    align: "center",
    fn: null,
  },
  {
    name: "Tipo",
    col: 7,
    id: "tipo",
    width: "10%",
    align: "center",
    fn: null,
  },
  {
    name: "Estatus",
    col: 8,
    id: "estatus",
    width: "10%",
    align: "center",
    fn: null,
  },
  {
    name: "Hora Reg",
    col: 9,
    id: "hora_reg",
    width: "10%",
    align: "center",
    fn: null,
  },
  {
    name: "Horario",
    col: 10,
    id: "horario",
    width: "10%",
    align: "center",
    fn: null,
  },
  {
    name: "Aut",
    col: 11,
    id: "aut",
    width: "5%",
    align: "center",
    fn: null,
  },
  {
    name: "Can",
    col: 12,
    id: "can",
    width: "5%",
    align: "center",
    fn: null,
  },
  {
    name: "Reg",
    col: 13,
    id: "reg",
    width: "5%",
    align: "center",
    fn: null,
  },
  {
    name: "Acción",
    col: 14,
    id: "accion",
    width: "20%",
    align: "center",
    fn: null,
  },
];
